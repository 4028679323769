/**
 * @flow
 */
import React from 'react';
import {useDataGridView} from "../../components/DataGridView";
import {api, apiAccount, apiSettings, util} from "../../services/service";
import {PrimaryButton} from "../../components/buttons";
import {useSimpleEditModal} from "../../components/SimpleModal";
import EmailFormManager from "../../forms/settings/EmailFormManager";
import {useUser} from "../../redux/reducers/userReducer";
import {useTopbar} from "../../redux/reducers/topbarReducer";

const form = new EmailFormManager();

const EmailList = () => {
  const {grid, onAdd, emailModal, onBranchChange} = useEmailList();
  const areaOptions = api.useAreaOptions(true);

  return (
    <div className={'flex'}>
      <div className={'flex-1'} style={{position: 'relative'}}>
        <div style={{position: 'absolute', top: -12}}>
          <PrimaryButton label={'ADD'} onClick={onAdd} />
        </div>

        <div className={'form'} style={{position: 'absolute', top: -15, left: 80, marginBottom: 0, width: 'inherit'}}>
          <div className={'form__form-group'}>
            <select onChange={onBranchChange}>
              <option value={''}>ALL</option>
              {areaOptions.map(({value, label}) => {
                return <option key={value} value={value}>{label}</option>
              })}
            </select>
          </div>
        </div>

        {grid.render()}
        {emailModal.render()}
      </div>
    </div>
  );
};

function useEmailList() {
  const [id, setId] = React.useState();
  const branchIdRef = React.useRef();
  const user = useUser();
  const grid = useDataGridView({
    async onAction(action, data) {
      if (action === 'edit') {
        setId(data.id);
        emailModal.open(action, data);
      }
    },
    async onQuery(gridInfo) {
      return apiSettings.listEmail(branchIdRef.current);
    },
    name: 'EmailList',
    label: 'Email List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Staff'},
    menuLabel: {label: 'Email Templates'},
    sortCol: 'branch_name',
    sortDesc: true,
    pageRows: 2000,
    actions: ['edit'],
    actionWidth: 70,
    showDateSearch: false,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    initialSearchOneMonth: true,
    showRowSearch: false,
    columns: getColumns(),
  });
  const onBranchChange = ({target: {value}}) => {
    branchIdRef.current = value;
    grid.query();
  };
  const onAdd = () => {
    emailModal.open('add', onSave);
  };
  const onEdit = async () => {
    const values = form.getValues();
    const {id, branch_id = user.branch_id, pic_email, pic_name, subject, message, kind, cc_email} = values;

    if(!subject) {
      return util.showWarning('Please enter a valid subject!');
    }
    if(!message) {
      return util.showWarning('Please enter a valid message!');
    }
    const data = {
      id: id,
      branchId: branch_id,
      picEmail: pic_email,
      picName: pic_name,
      subject: subject,
      kind: kind,
      ccEmail: cc_email,
      message: message
    };

    const res = await apiSettings.setEmail(data);
    if(res.data) {
      util.showSuccess('Email Template has been saved successfully.');
      emailModal.close();
      grid.query();
    }
  };
  const onDelete = async () => {
    const values = form.getValues();
    const {id} = values;
    const data = {id: id, isDelete: true};

    util.showConfirm('Are you sure to delete?', async () => {
      const res = await apiSettings.setEmail(data);
      if(res.data) {
        util.showSuccess('Email Template has been deleted successfully.');
        emailModal.close();
        grid.query();
      }
    });
  };
  const onSave = async (mode) => {
    if(mode === 'delete') {
      await onDelete();
    } else {
      await onEdit();
    }
  };
  const emailModal = useSimpleEditModal({
    title: 'Email template',
    width: 935,
    form,
    onSave,
  });

  return {grid, onAdd, emailModal, onBranchChange};
}

function getColumns() {
  return [
    {field: 'id', headerName: '', hide: true, flex: 1},
    {field: 'branch_id', headerName: '', hide: true, flex: 1},
    {field: 'branch_name', headerName: 'Branch', flex: 1},
    {field: 'kind', headerName: 'Service', flex: 1},
    {field: 'pic_email', headerName: 'PIC Email', flex: 1},
    {field: 'pic_name', headerName: 'PIC Name', flex: 1},
    {field: 'cc_email', headerName: 'Email CC', flex: 1},
    {field: 'subject', headerName: 'Subject', flex: 2},
    {field: 'message', headerName: '', hide: true, flex: 1},
  ];
}

export default EmailList;
