/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";
import coshipLogo from '../media/coship_logo.png';

class InvoiceARPrintNew extends React.Component {
  render() {
    const {printData, user} = this.props;
    if (!printData) {
      return null;
    }
    return <ARPrint printData={printData} user={user}/>;
  }
}

const ARPrint = ({printData, user}) => {
  const {accountData, accountGridData, branchListData, bankInfoData} = printData;
  if (!printData) {
    return null;
  }
  const [size, setSize] = React.useState(15);
  const [arrARDetailData, setArrARDetailData] = React.useState([]);
  const totalAmount = accountData.totalAmount ? util.round(accountData.totalAmount, 2) : 0;
  const balance = accountData.balance ?? 0;
  const arrLength = arrARDetailData.length;
  const ref = React.useRef(null);

  React.useEffect(() => {
    setArrARDetailData(util.chunk(accountGridData, size));
  }, [accountGridData]);

  React.useLayoutEffect(() => {
    let containerHeight = 450;
    let count = 0;
    const commodityHeight = document.getElementById('commodity')?.clientHeight;
    containerHeight = containerHeight - commodityHeight + 21;
    if (arrARDetailData?.length > 0) {
      arrARDetailData[0].forEach((item, index) => {
        const rowHeight = document.getElementById(`accountType${index}`).clientHeight;
        if (containerHeight < rowHeight && count === 0) {
          setSize(index - 1);
          setArrARDetailData(util.chunk(accountGridData, index - 1));
          count += 1;
        } else {
          containerHeight -= rowHeight;
        }
      })
    }
  }, []);

  const render = () => {
    return (
      <div style={{color: 'black'}}>
        {arrARDetailData?.map((items, index) => {
          const itemsSize = items.length;
          const emptyArr = [];
          for(let i=0; i<size-itemsSize; i++) {
            emptyArr.push({empty: '\u00a0'});
          }
          return (
            <div className={'page-break'} key={index}>
              {renderHeader(accountData, user)}
              <hr />
              {renderHeaderSub(accountData)}
              <table className={'print-table mt-20'} style={{width: '100%', height: '450px'}}>
                <tbody>
                  <tr style={{height: 25}}>
                    <td className={'no-border-lr left invoice_label fb-15'} width={'25%'}>DESCRIPTION OF CHARGES</td>
                    <td className={'no-border-lr left invoice_label fb-15'} width={'25%'}>REMARK</td>
                    <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>UNIT</td>
                    <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>RATE</td>
                    <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>QTY</td>
                    <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>CUR.RATE</td>
                    <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>AMOUNT</td>
                  </tr>
                  <tr><td colSpan={7} className={'no-border-all'} height={8}>{''}</td></tr>
                  {items?.map((item, index) => {
                    return (
                      <tr id={`accountType${index}`} ref={ref} key={index}>
                        <td className={'no-border-all'} width={'30%'} height={'20'} style={{paddingTop: '1px', lineHeight: 1}}>{item['description']}</td>
                        <td className={'no-border-all'} width={'25%'} height={'20'} style={{paddingTop: '1px', lineHeight: 1}}>{item['remark']}</td>
                        <td className={'no-border-all'} width={'10%'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{item['unit']}</td>
                        <td className={'no-border-all'} width={'10%'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{util.formatCurrency(item['rate'])}</td>
                        <td className={'no-border-all'} width={'10%'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{item['volume']}</td>
                        <td className={'no-border-all'} width={'10%'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{item['cur_rate']}</td>
                        <td className={'no-border-all'} width={'15%'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{util.formatCurrency(item['amount'], accountData.currency, 2)}</td>
                      </tr>
                    );
                  })}
                  {size > itemsSize &&
                    emptyArr?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td colSpan={7} className={'no-border-all'} width={'100%'} height={'20'} style={{paddingTop: '1px', lineHeight: 1}}>{item.empty}</td>
                        </tr>
                      );
                    })
                  }
                  {arrLength-1 !== index && (<tr>
                    <td colSpan={7} className={'no-border-all center'}><b>CONTINUED</b></td>
                  </tr>)}
                </tbody>
              </table>
              <hr />
              {arrLength-1 !== index && (<table className={'print-table mt-20'} style={{width: '100%', height: '50px'}}>
                <tbody>
                <tr>
                  <td className={'no-border-all'}>{'\u00a0'}</td>
                </tr>
                </tbody>
              </table>)}
              {arrLength-1 === index && (<table className={'print-table mt-20'} style={{width: '100%', height: '30px'}}>
                <tbody>
                {renderInvoiceItemsTotal('TOTAL DUE', '', util.formatCurrency(util.toFloat(totalAmount), accountData.currency, 2))}
                {renderInvoiceItemsTotal('PAID AMOUNT', '', util.formatCurrency((totalAmount - balance), accountData.currency, 2))}
                {renderInvoiceItemsTotal('PLEASE PAY THIS AMOUNT', accountData.currency, util.formatCurrency(balance, accountData.currency, 2))}
                </tbody>
              </table>)}
              <table className={'print-table mt-20'} style={{width: '100%'}}>
                <tbody>
                {renderInvoiceMemoInfo('MEMO', accountData.memo)}
                {renderInvoiceBankInfo('BANK INFO', bankInfoData?.memo)}
                </tbody>
              </table>
              <hr className={'line'} />
              <table className={'print-table mt-20'} style={{width: '100%'}}>
                <tbody>
                <tr className={'flex'}>
                  {renderFootInfo(branchListData)}
                </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    )
  }
  const renderHeader = (accountData, user) => {
    return (
      <>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all flex mb-1'}>
                  <div className={'flex-1'}><img src={coshipLogo} /></div>
                  <div className={'flex-5 ml-12 mt-24 invoice_label'}><h3>{user.print.company}</h3></div>
                </td>
              </tr>
              <tr>
                <td className={'no-border-all'}>
                  {user.print.address}<br/>
                  TEL: {user.print.phone} {'\u00a0'} FAX: {user.print.fax}<br/>
                  EMAIL: {user.print.email}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%', height: '100%'}}>
              <tbody>
              <tr style={{height: '20px'}}>
                <td className={'no-border-all right invoice_label'}><h1>INVOICE</h1></td>
              </tr>
              <tr>
                <td className={'no-border-all bottom_right'}>
                  <h3 className={'invoice_label'}>INVOICE No. : {accountData.inv_no}</h3>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'flex'} style={{marginTop: '15px'}}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all w-100'}>BILL TO :</td>
                <td className={'no-border-all'} style={{whiteSpace: 'pre-line'}}>{accountData.bill_address ?? accountData.billToAddr}</td>
              </tr>
              <tr>
                <td className={'no-border-all w-100'}>SHIP TO :</td>
                {/*<td className={'no-border-all'}>{accountData.shipTo}</td>*/}
                <td className={'no-border-all'} style={{whiteSpace: 'pre-line'}}>{accountData.shipTo || accountData.consigneeAddr}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>INVOICE DATE</td>
                <td className={'left'}>{accountData.inv_date}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>TERMS</td>
                <td className={'left'}>14 days</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>DUE DATE</td>
                <td className={'left'}>{util.getAddDays(14, accountData.inv_date)}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>OUR FILING NO.</td>
                <td className={'left'}>{accountData.filingNo}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  const renderHeaderSub = (accountData) => {
    const grossWeight = accountData.grossWeightKg
        ? `${parseFloat(accountData.grossWeightKg).toFixed(2)} KGS / ${(parseFloat(accountData.grossWeightKg)*2.205).toFixed(2)} LBS`
        : ''
    const chargeWeight = accountData.chargeableWeightKg
        ? `${parseFloat(accountData.chargeableWeightKg).toFixed(2)} KGS / ${(parseFloat(accountData.chargeableWeightKg)*2.205).toFixed(2)} LBS`
        : ''
    const finalDest = accountData.fDestEta ? `${accountData.finalDestLabel} / ${accountData.fDestEta}` : accountData.finalDestLabel;
    const packages = accountData.package ? `${accountData.package} ${accountData.packageType}` : '';
    const weightText = accountData.portType === 'O' ? 'CBM' : 'KGS / LBS(C)';
    // const weight = accountData.portType === 'O'
    //     ? (accountData.kind === 'F'
    //       ? (accountData.cbm ? `${accountData.cbm} CBM` : '')
    //       : (accountData.offerCbm ? `${accountData.offerCbm} CBM` : ''))
    //     : chargeWeight;
    const weight = accountData.portType === 'O' ? (accountData.offerCbm ? `${accountData.offerCbm} CBM` : '') : chargeWeight;
    return (
      <table className={'print-table'} style={{width: '100%', height: '180px'}}>
        <tbody>
        <tr className={'flex'}>
          {renderInvoiceInfoLine('MASTER B/L NO.', accountData.mblNo)}
          {renderInvoiceInfoLine('NO. OF PKGS', packages)}
        </tr>
        <tr className={'flex'}>
          {renderInvoiceInfoLine('HOUSE B/L NO.', accountData.hblNo)}
          {renderInvoiceInfoLine('KGS / LBS(G)', grossWeight)}
        </tr>
        <tr className={'flex'}>
          {renderInvoiceInfoLine('VESSEL/FLT NO.', accountData.vfNo)}
          {renderInvoiceInfoLine(weightText, weight)}
        </tr>
        <tr className={'flex'}>
          {renderInvoiceInfoLine('POL / ETD', util.displayWithMark(accountData.polLabel, accountData.polEtd, '/'))}
          {renderInvoiceInfoLine('CNTR. NO.', accountData.containerStr)}
        </tr>
        <tr className={'flex'}>
          {renderInvoiceInfoLine('POD / ETA', util.displayWithMark(accountData.podLabel, accountData.podEta, '/'))}
          {renderInvoiceInfoLine('INCOTERMS', accountData.incoterms)}
        </tr>
        <tr className={'flex'}>
          {renderInvoiceInfoLine('F.DEST.', finalDest)}
          {renderInvoiceInfoLine('SHIPPER', accountData.shipper)}
        </tr>
        <tr className={'flex'}>
          {renderInvoiceInfoLine('CARRIER BKG. NO.', accountData.bkgNo)}
          {renderInvoiceInfoLine('CONSIGNEE', util.textSubstring(accountData.consignee ?? '', 23))}
        </tr>
        <tr id={'commodity'} className={'flex'}>
          {renderInvoiceInfoLine('COMMODITY', accountData.commodity)}
          {renderInvoiceInfoLine('NOTIFY', accountData.notify)}
        </tr>
        </tbody>
      </table>
    );
  }
  const renderInvoiceInfoLine = (label, value) => {
    return (
      <td className={'no-border-all flex-1'}>
        <div className={'flex'}>
          <div className={'flex-1'}>{label}</div>
          <div className={'mx-4'}>:</div>
          <div className={'flex-2'} style={{whiteSpace: 'pre-line'}}>{value}</div>
        </div>
      </td>
    );
  }
  const renderEmptyItems = (items, accountData, arrLength, index) => {
    const empty = [];
    const extra = arrLength-1 === index ? 3 : 0;
    const numberOfcomm = ((accountData.commodity?.match(/\n/g)||[]).length ?? 0) + 1;
    let length = 25 - items.length - numberOfcomm - extra;
    if(length > 0) {
      for(let i=0; i<length; i++) {
        empty.push(
          <tr key={i}>
            <td colSpan={7} className={'no-border-all'} style={{paddingTop: '10px'}}>{'\u00a0'}</td>
          </tr>
        );
      }
    }
    return empty;
  }
  const renderInvoiceItemsTotal = (label, value1, value2) => {
    return (
      <tr>
        <td className={'no-border-all right invoice_label'}>{label}</td>
        <td className={'no-border-all right invoice_label'}>{value1}</td>
        <td className={'no-border-all right invoice_label'}>{value2}</td>
      </tr>
    );
  }
  const renderInvoiceMemoInfo = (label, value) => {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-wrap', minHeight: '25px', fontSize: '0.8em', paddingTop: '3px', paddingBottom: '3px'}}>{value}</div></td>
      </tr>
    );
  }
  const renderInvoiceBankInfo = (label, value) => {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-wrap', minHeight: '230px', fontSize: '0.8em', paddingTop: '3px', paddingBottom: '3px'}}>{value}</div></td>
      </tr>
    );
  }
  const renderFootInfo = (branchLists) => {
    return branchLists?.map((item, idx) => {
      return (
        <td key={idx} className={'no-border-all flex-1 f-15'}>
          {item.pic}<br/>
          {item.address}<br/>
          TEL: {item.phone} {item.fax && `FAX: ${item.fax}`}<br/>
          EMAIL: {item.email}
        </td>
      );
    });
  }

  return (
    render()
  );
}

export function useInvoiceARPreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const accountDataRef = React.useRef();
  const accountGridDataRef = React.useRef();
  const branchListRef = React.useRef();
  const bankInfoRef = React.useRef();
  const printRef = React.useRef();
  const invoiceNoRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: invoiceNoRef.current,
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <InvoiceARPrintNew ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        accountData: accountDataRef.current,
        accountGridData: accountGridDataRef.current,
        branchListData: branchListRef.current,
        bankInfoData: bankInfoRef.current,
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 500);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (accountData, accountGridData, branchLists, bankInfo) => {
      // console.log('accountData:', accountData);
      accountDataRef.current = accountData;
      accountGridDataRef.current = accountGridData;
      branchListRef.current = branchLists;
      bankInfoRef.current = bankInfo;
      invoiceNoRef.current = accountData.inv_no;
      modal.open();
    },
    print,
  };
}

export default InvoiceARPrintNew;
