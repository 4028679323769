/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../../components/SimpleModal';
import ForwardingMasterBLContainerFormManager from "../../../forms/newCoship/forwarding/ForwardingMasterBLContainerFormManager";

const editForm = new ForwardingMasterBLContainerFormManager();

export default function useForwardingMasterBLContainerModal(onSave) {
  const modal = useSimpleEditModal({
    title: 'CONTAINER',
    centered: true,
    width: 600,
    form: editForm,
    onSave,
  });
  React.useEffect(() => {
    if (modal.isOpen) {

    }
  }, [modal.isOpen]);
  return {...modal};
};
