export const SET_CARRIER_TYPE = 'SET_CARRIER_TYPE';
export const SET_BRANCH = 'SET_BRANCH';
export const SET_KIND = 'SET_KIND';
export const SET_BOUND = 'SET_BOUND';
export const SET_FILING_NO = 'SET_FILING_NO';
export const SET_MAWB_NO = 'SET_MAWB_NO';
export const SET_BL_STATUS = 'SET_BL_STATUS';
export const SET_HOUSE_AR_TOTAL = 'SET_HOUSE_AR_TOTAL';
export const SET_HOUSE_AP_TOTAL = 'SET_HOUSE_AP_TOTAL';
export const SET_HOUSE_BALANCE_TOTAL = 'SET_HOUSE_BALANCE_TOTAL';
export const SET_HOUSE_ACCOUNT_LIST = 'SET_HOUSE_ACCOUNT_LIST';
export const SET_PROFIT_ARRAY = 'SET_PROFIT_ARRAY';
export const SET_HOUSE_ACCOUNT_AR_DATA = 'SET_HOUSE_ACCOUNT_AR_DATA';
export const SET_HOUSE_ACCOUNT_DEPOSIT = 'SET_HOUSE_ACCOUNT_DEPOSIT';
export const SET_PACK_DATE = 'SET_PACK_DATE';
export const SET_PACK_FREIGHT = 'SET_PACK_FREIGHT';
export const SET_CBM = 'SET_CBM';
export const SET_PKG = 'SET_PKG';
export const SET_FLIGHT_DATA = 'SET_FLIGHT_DATA';

export function setCarrierType(dispatch, carrierType) {
  dispatch({type: SET_CARRIER_TYPE, carrierType});
}

export function setBranch(dispatch, branch) {
  dispatch({type: SET_BRANCH, branch});
}

export function setKind(dispatch, kind) {
  dispatch({type: SET_KIND, kind});
}

export function setBound(dispatch, bound) {
  dispatch({type: SET_BOUND, bound});
}

export function setFilingNo(dispatch, filingNo) {
  dispatch({type: SET_FILING_NO, filingNo});
}

export function setMawbNo(dispatch, mawbNo) {
  dispatch({type: SET_MAWB_NO, mawbNo});
}

export function setBlStatus(dispatch, blStatus) {
  dispatch({type: SET_BL_STATUS, blStatus});
}

export function setHouseARTotal(dispatch, arTotal) {
  dispatch({type: SET_HOUSE_AR_TOTAL, arTotal});
}

export function setHouseAPTotal(dispatch, apTotal) {
  dispatch({type: SET_HOUSE_AP_TOTAL, apTotal});
}

export function setHouseBalanceTotal(dispatch, balanceTotal) {
  dispatch({type: SET_HOUSE_BALANCE_TOTAL, balanceTotal});
}

export function setHouseAccountList(dispatch, accountList) {
  dispatch({type: SET_HOUSE_ACCOUNT_LIST, accountList});
}

export function setProfitArray(dispatch, profitArray) {
  dispatch({type: SET_PROFIT_ARRAY, profitArray});
}

export function setHouseAccountARData(dispatch, arData) {
  dispatch({type: SET_HOUSE_ACCOUNT_AR_DATA, arData});
}

export function setHouseAccountDeposit(dispatch, deposit) {
  dispatch({type: SET_HOUSE_ACCOUNT_DEPOSIT, deposit});
}

export function setPackDate(dispatch, packDate) {
  dispatch({type: SET_PACK_DATE, packDate});
}

export function setPackfreight(dispatch, packfreight) {
  dispatch({type: SET_PACK_FREIGHT, packfreight});
}

export function setCBM(dispatch, cbm) {
  dispatch({type: SET_CBM, cbm});
}

export function setPKG(dispatch, pkg) {
  dispatch({type: SET_PKG, pkg});
}

export function setFlightData(dispatch, flightData) {
  dispatch({type: SET_FLIGHT_DATA, flightData});
}

const initState = {
  carrierType: 'O',
  branch: '',
  kind: '',
  bound: 'E',
  filingNo: '',
  mawbNo: '',
  blStatus: '',
  arTotal: '',
  apTotal: '',
  balanceTotal: '',
  accountList: [],
  profitArray: [],
  arData: [],
  deposit: [],
  packDate: '',
  packfreight: '',
  cbm: '',
  pkg: '',
  flightData: {},
}

export default function (state = initState, action) {
  switch (action.type) {
    case SET_CARRIER_TYPE:
      return {...state, carrierType: action.carrierType};
    case SET_BRANCH:
      return {...state, branch: action.branch};
    case SET_KIND:
      return {...state, kind: action.kind};
    case SET_BOUND:
      return {...state, bound: action.bound};
    case SET_FILING_NO:
      return {...state, filingNo: action.filingNo};
    case SET_MAWB_NO:
      return {...state, mawbNo: action.mawbNo};
    case SET_BL_STATUS:
      return {...state, blStatus: action.blStatus};
    case SET_HOUSE_AR_TOTAL:
      return {...state, arTotal: action.arTotal};
    case SET_HOUSE_AP_TOTAL:
      return {...state, apTotal: action.apTotal};
    case SET_HOUSE_BALANCE_TOTAL:
      return {...state, balanceTotal: action.balanceTotal};
    case SET_HOUSE_ACCOUNT_LIST:
      return {...state, accountList: action.accountList};
    case SET_PROFIT_ARRAY:
      return {...state, profitArray: action.profitArray};
    case SET_HOUSE_ACCOUNT_AR_DATA:
      return {...state, arData: action.arData};
    case SET_HOUSE_ACCOUNT_DEPOSIT:
      return {...state, deposit: action.deposit};
    case SET_PACK_DATE:
      return {...state, packDate: action.packDate};
    case SET_PACK_FREIGHT:
      return {...state, packfreight: action.packfreight};
    case SET_CBM:
      return {...state, cbm: action.cbm};
    case SET_PKG:
      return {...state, pkg: action.pkg};
    case SET_FLIGHT_DATA:
      return {...state, flightData: action.flightData};
    default:
      return state;
  }
}
