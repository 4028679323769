/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../../components/SimpleModal';
import MovingHouseBLAccountFormManager from '../../../forms/newCoship/moving/MovingHouseBLAccountFormManager';
import {useSimpleGrid} from '../../../components/SimpleGrid';

const editForm = new MovingHouseBLAccountFormManager();

export default function(onSave) {
  const gridRef = React.useRef();
  const grid = useSimpleGrid({
    columns: [
      {field: 'account_type', headerName: 'Account Type', width: 150},
      {field: 'unit', headerName: 'Unit', width: 100},
      {field: 'rate', headerName: 'Rate', width: 100},
      {field: 'volume', headerName: 'Volume', width: 100},
      {field: 'cur_rate', headerName: 'CUR. Rate', width: 120},
      {field: 'amount', headerName: 'Amount', width: 100},
      {field: 'remark', headerName: 'Remark', flex: 1},
    ],
    height: 300,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    onAction: (action, data) => {
      if (action === 'delete') {
        const currentGrid = gridRef.current;
        const index = currentGrid.rows.findIndex(i => i['id'] === data['id']);
        const newRows = [...currentGrid.rows];
        newRows.splice(index, 1);
        currentGrid.setRows(newRows);
      }
    },
  });
  const modal = useSimpleEditModal({
    title: 'Moving AP',
    centered: true,
    width: 900,
    form: editForm,
    onSave,
    children: grid.render(),
  });
  gridRef.current = grid;
  React.useEffect(() => {
    if (modal.isOpen) {
      // if (modal.data?.id > 0) {
      //   apiAccount.blRateData(modal.data.id).then(res => {
      //     const {data} = res;
      //     if (data && data.length > 0) {
      //       grid.setRows(data[0]['jitems']);
      //     }
      //   });
      // } else {
      //   grid.setRows([]);
      // }
    }
  }, [modal.isOpen]);
  return {grid, ...modal};
};
