/**
 * @flow
 */
import React from 'react';
import useCurrencyListGridView from "../../grids/useCurrencyListGridView";
import useCurrencyModal from "../../modals/settings/useCurrencyModal";
import {PrimaryButton} from "../../components/buttons";
import {api, util} from "../../services/service";
import {apiForwarding} from '../../services/newCoship/service';
import type {QueryListParams} from "../../services/API";
import moment from 'moment';

const todayDate = moment().format('YYYY-MM-DD');
const CurrencyList = () => {
  const {grid, onAdd, editModal} = useCurrencyList();
  return (
    <div className={'flex'}>
      <div className={'flex-1'} style={{position: 'relative'}}>
        <div style={{position: 'absolute', top: -12}}>
          <PrimaryButton label={'TODAY RATE'} onClick={onAdd} />
        </div>
        {grid.render()}
      </div>
      {editModal.render()}
    </div>
  );
};

function useCurrencyList() {
  const grid = useCurrencyListGridView((action, data) => {
    if (action === 'edit') {
      editModal.open('edit', data);
    }
  });
  const onSearch = () => {
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      qryText: '',
      page: 1,
    };
    grid.query(listParam);
  };
  const onSave = async (mode, data) => {
    if (data === undefined || (!data?.usd_buy || !data?.usd_sell || !data?.krw_buy || !data?.krw_sell)) {
      return util.showWarning(`Please fill all the required fields`);
    }
    const payload = {
      // ...data,
      rate_date: todayDate,
      usd_buy: util.toFloat(data.usd_buy),
      usd_sell: util.toFloat(data.usd_sell),
      krw_buy: util.toFloat(data.krw_buy),
      krw_sell: util.toFloat(data.krw_sell),
      remark: data?.remark ?? '',
    }
    const res = await apiForwarding.setCurrencyRate(payload);
    if (res) {
      util.showSuccess('Today currency rate has been saved successfully!');
      editModal.close();
    }
  };
  const editModal = useCurrencyModal(todayDate, async (mode, data) => {
    await onSave(mode, data);
  });
  const onAdd = () => {
    // editModal.open('add', {});
    editModal.open('edit', {});
  };
  React.useEffect(() => {
    if (editModal.isOpen === false) {
      onSearch();
    }
  }, [editModal.isOpen]);
  return {grid, onAdd, editModal};
}

export default CurrencyList;
