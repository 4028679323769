/**
 * @flow
 */
import {useSimpleEditModal} from '../../components/SimpleModal';
import MasterCurrencyModalFormManager from '../../forms/settings/MasterCurrencyModalFormManager';

const editForm = new MasterCurrencyModalFormManager();

export default function useCurrencyModal(todayDate, onSave) {
  editForm.setTodayDate(todayDate);
  const modal = useSimpleEditModal({
    title: `TODAY(${todayDate}) CURRENCY RATE`,
    centered: true,
    width: 600,
    form: editForm,
    onSave,
  });
  return {...modal};
}
