/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import AccountInvoiceARFormManager, {getAccountEntryData, getAccountFormData} from '../../forms/account/AccountInvoiceARFormManager';
import AccountTopSearchFormManager from '../../forms/account/AccountTopSearchFormManager';
import AccountInvoiceAddItemFormManager from '../../forms/account/AccountInvoiceAddItemFormManager';
import {DangerButton, DefaultButton} from '../../components/buttons';
import {apiAccount, util} from '../../services/service';
import {apiForwarding} from "../../services/newCoship/service";
import {useUser} from "../../redux/reducers/userReducer";
import {INVOICE} from "../../forms/field-defs/account";
import useAccountPaymentModal from "../../modals/account/useAccountPaymentModal";
import {useInvoiceARPreviewModal} from '../../print-layouts/InvoiceARPrint2';

const type = INVOICE.TYPE_AR;
const title = `Invoice ${INVOICE.TYPE_AR_LABEL}`;

const accountForm = new AccountInvoiceARFormManager();
const accountSearchForm = new AccountTopSearchFormManager(type);
const accountInvoiceAddItem = new AccountInvoiceAddItemFormManager(type);

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const AccountInvoiceAR = () => {
  useTopbar({label: 'Account'}, {label: title});
  const invoiceNoRef = React.useRef();
  const [balance, setBalance] = React.useState(0);
  const [pId, setPId] = React.useState(0);
  const [mode, setMode] = React.useState('add');
  const [isDisable, setIsDisable] = React.useState(false);
  const [editTime, setEditTime] = React.useState();
  const [isBranch, setIsBranch] = React.useState(false);
  const [branchId, setBranchId] = React.useState();
  const invoiceDateRef = React.useRef();
  const qs = util.getQS();
  const {id, blNo} = qs;
  const accountPaymentModal = useAccountPaymentModal();
  const invoiceARPrintModal = useInvoiceARPreviewModal();
  const user = useUser();
  const userBranchId = user.branch_id;
  const userBranchIdRef = React.useRef();
  const isBranchRef = React.useRef(false);
  const isManagerRef = React.useRef();
  const isShowDeleteRef = React.useRef(false);
  const isShowSaveRef = React.useRef(false);
  const isShowPaymentRef = React.useRef(false);
  const isAccountRef = React.useRef();
  const isBeforeOctRef = React.useRef();
  const isAccountManager = user.isManager && user.team_main === 'A';
  const isAccountDirector = user.isDirector || user.team_main === 'A';
  userBranchIdRef.current = user.branch_id;
  isManagerRef.current = user.isManager;
  isBeforeOctRef.current = util.toEndDateTS(util.getSpecificDate('2022-09-30')) >= invoiceDateRef.current;
  isAccountRef.current = isAccountDirector && id;
  isBranchRef.current = onChcekBranch(branchId, userBranchIdRef.current, accountForm);
  // isAccountRef.current = getBranchName(userBranchId, accountForm) === 'TORONTO' ? true : (user.isDirector || isAccountManager) && id;
  // isSaveDisabledRef.current = getBranchName(userBranchId, accountForm) === 'TORONTO' ? (isBeforeOctRef.current ? !isManagerRef.current : !(isBranch && isManagerRef.current)) : !isManagerRef.current;
  // isDeleteDisabledRef.current = getBranchName(userBranchId, accountForm) === 'TORONTO' ? (isBeforeOctRef.current ? !isAccountRef.current : !(isBranch && isAccountRef.current)) : !isAccountRef.current;
  // isShowPaymentRef.current = getBranchName(userBranchId, accountForm) === 'TORONTO' ? false : (user.isDirector || isAccountManager) && id;
  isShowSaveRef.current = getBranchName(userBranchIdRef.current, accountForm) === 'TORONTO' ? isBranchRef.current : id;
  isShowDeleteRef.current = getBranchName(userBranchIdRef.current, accountForm) === 'TORONTO' ? false : isAccountRef.current;
  isShowPaymentRef.current = getBranchName(userBranchIdRef.current, accountForm) === 'TORONTO' ? false : user.team_main === 'A' && id;
  accountSearchForm.mode = mode;
  document.title = `${title} ${mode}`;
  const onSave = async (isShowPopup = true) => {
    // if(!util.isValidPermitForAccount(user, mode)) {
    //   return util.showError('Sorry, you can not edit Account Info.');
    // }
    const values = accountForm.getValues(undefined, true);
    if (!values) {
      return;
    }
    const {financialOffice: branchId} = values;
    values['branchId'] = branchId ?? userBranchId;
    // console.log(" ***** SAVE - branchId: ", branchId);
    const gridData = accountInvoiceAddItem.getGridData();
    // console.log(" ***** SAVE - gridData: ", gridData);
    const data = getAccountEntryData(values, gridData);
    // console.log(" ***** SAVE: ", data);
    const res = await apiAccount.accountSet(data);
    if (res) {
      isShowPopup && await apiForwarding.setProfitReCal(data.blId);
      isShowPopup && util.showSuccess('Account Entry has been saved successfully.');
      // 추가로 할일 있으면 여기서 하면 됩니다.
      setMode('edit');
      accountSearchForm.mode = 'edit';
      setPId(res.id);
      document.title = `${title} Edit`;
      if(id) {
        onSearch();
      } else {
        util.nav(`/admin/account/list/ar?id=${res.id}`);
      }
    }
  };

  const onDelete = () => {
    if(id) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = await apiAccount.deleteAccountData(parseInt(id));
        if(res) {
          const values = accountForm.getValues(undefined, true);
          if (values) {
            const gridData = accountInvoiceAddItem.getGridData();
            const data = getAccountEntryData(values, gridData);
            await apiForwarding.setProfitReCal(data.blId);
          }
          util.showSuccess('Account has been deleted successfully!');
          util.nav('/admin/account/list');
        }
      })
    }
  };

  const onPrint = (isPreview) => {
    const branchId = accountForm.getValue('financialOffice');
    const bankInfo = accountForm.getValue('bankInfo');
    onSave(false).then(() => {
      apiAccount.branchList().then(res => {
        apiAccount.bankInfo(branchId, bankInfo).then(response => {
          invoiceARPrintModal.open(accountForm, accountSearchForm, accountInvoiceAddItem, res.data.lists, response.data[0], isPreview);
        });
      });
    });
  };

  accountSearchForm.onEnter = async () => {
    const res = await apiAccount.accountTradeBLData(accountSearchForm.getValues());
    const {data} = res;
    if (Array.isArray(data) && data.length === 1) {
      const {formData} = getAccountFormData(data[0]);
      formData.id = '';
      formData.invoiceNo = ''
      let branchId = formData.branchId;
      accountForm.setValuesFast({...formData, financialOffice: branchId ?? userBranchId});
    } else {
      // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
    }
  };

  const onSearch = () => {
    const res = apiAccount.accountTradeBLData({id: id}).then(({data}) => {
      if (Array.isArray(data) && data.length === 1) {
        const {formData, gridData} = getAccountFormData(data[0]);
        const [{bl_no, inv_no, jextra: {financialOffice, invoiceDate}, jcommon: {filingNo, branchId}}] = data;
        invoiceNoRef.current = inv_no;
        accountSearchForm.setValues({bl_no, inv_no, filingNo});
        accountForm.setValuesFast({...formData, bl_no, invoiceNo: inv_no, financialOffice: financialOffice ?? branchId ?? userBranchId});
        let gridRowsData = gridData.map((row, index) => row = {...row, id: index});
        setBalance(data[0].balance);
        setIsDisable(data[0].btn_disable);
        accountInvoiceAddItem.isDisabled = data[0].btn_disable;
        invoiceDateRef.current = invoiceDate;
        setTimeout(() => {
          accountInvoiceAddItem.setGridData(gridRowsData);
          accountInvoiceAddItem.setAccountId(id);
        }, 0);
        setEditTime(formData.footer.edit[0]);
        setBranchId(financialOffice);
      } else {
        // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
      }
    });
  };

  const onSearchWithBlNo = () => {
    accountSearchForm.setValue('blNo', blNo);
    apiAccount.accountTradeBLData({blNo: blNo }).then((res) => {
      const {data} = res;
      if (Array.isArray(data) && data.length === 1) {
        const {formData} = getAccountFormData(data[0]);
        formData.id = '';
        formData.invoiceNo = ''
        let branchId = formData.branchId;
        accountForm.setValuesFast({...formData, financialOffice: branchId ?? userBranchId});
      } else {
        // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
      }
    });
  };

  accountForm.accountInvoiceAddItem = accountInvoiceAddItem;

  React.useEffect(() => {
    if(id) {
      setMode('edit');
    }
  }, [id]);

  React.useEffect(() => {
    if (id) {
      onSearch();
    } else if(blNo) {
      onSearchWithBlNo();
    }
  }, [id, blNo]);

  // React.useEffect(() => {
  //   if (branchId && userBranchId) {
  //     setIsBranch(onChcekBranch(branchId, userBranchId, accountForm));
  //   }
  // }, [branchId, userBranchId]);

  const paymentHistory = () => {
    accountPaymentModal.open(id ?? 0, invoiceNoRef.current ?? '');
  }

  const renderButtons = () => {
    if(id) {
      return (
        <>
          {/*<DefaultButton disabled={isBeforeOctRef.current ? !isManagerRef.current : !(isBranch && isManagerRef.current)} label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />*/}
          {/*<DangerButton disabled={isBeforeOctRef.current ? !isAccountRef.current : !(isBranch && isAccountRef.current)} label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} />*/}
          {/*<DefaultButton disabled={isBeforeOctRef.current ? !isAccountRef.current : !(isBranch && isAccountRef.current)} label={'P. History'} onClick={paymentHistory} noMargin /><div className={'w-8'} />*/}
          <DefaultButton disabled={!isShowSaveRef.current} label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
          <DangerButton disabled={!isShowDeleteRef.current} label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} />
          <DefaultButton disabled={!isShowPaymentRef.current} label={'P. History'} onClick={paymentHistory} noMargin /><div className={'w-8'} />
          <DefaultButton label={'Preview'} onClick={() => onPrint(true)} noMargin /><div className={'w-8'} />
          <DefaultButton label={'Print'} onClick={() => onPrint(false)} noMargin /><div className={'w-8'} />
        </>
      );
    } else {
      return (
        <>
        <DefaultButton label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
        </>
      );
    }
  };

  const renderBottomButtons = () => {
    if (id) {
      return (
        <>
        {/*<DefaultButton disabled={isBeforeOctRef.current ? !isManagerRef.current : !(isBranch && isManagerRef.current)} label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />*/}
        <DefaultButton disabled={!isShowSaveRef.current} label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
        <DefaultButton label={'Preview'} onClick={() => onPrint(true)} noMargin /><div className={'w-8'} />
        <DefaultButton label={'Print'} onClick={() => onPrint(false)} noMargin />
        </>
      );
    } else {
      return (
        <DefaultButton label={'Save'} onClick={onSave} noMargin />
      );
    }
  };

  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'} style={{position: 'relative'}}>
          <div className={'flex between w-full mb-12'}>
            <b className={'ml-12'}>{title}</b>
            <div className={'flex right'}>
              {renderButtons()}
            </div>
          </div>
          {accountSearchForm.renderForm()}
          {!!id && accountForm.formik && util.renderFooter(accountForm.getValue('footer'), 'house-footer')}
        </CardBody>
      </Card>
      {accountForm.renderForm()}
      {accountInvoiceAddItem.renderForm()}
      {accountPaymentModal.render()}
      {invoiceARPrintModal.render()}
      <div className={'flex center'} style={{marginTop: '-20px', paddingBottom: '20px'}}>
        {renderBottomButtons()}
      </div>
    </>
  );
};

// Toronto 브랜치는 Vancouver, Seoul 브랜치 데이터 수정 못하고, Vancouver, Seoul 브랜치는 Toronto 브랜치 데이터 수정 못함.
export const onChcekBranch = (branchId, userBranchId, form) => {
  const isUserVancouverSeoul = getBranchName(userBranchId, form) === 'VANCOUVER' || getBranchName(userBranchId, form) === 'SEOUL';
  const isUserToronto = getBranchName(userBranchId, form) === 'TORONTO';
  const isDataVancouverSeoul = getBranchName(branchId, form) === 'VANCOUVER' || getBranchName(branchId, form) === 'SEOUL';
  const isDataToronto = getBranchName(branchId, form) === 'TORONTO';
  if (branchId && userBranchId && userBranchId !== branchId) {
    if ((isUserVancouverSeoul && isDataToronto) || (isUserToronto && isDataVancouverSeoul)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const getBranchName = (branchId, form) => {
  let branchOptions;
  try {
    branchOptions = form.getField('financialOffice').options;
  } catch (error) {
    branchOptions = [];
  }
  return branchOptions.find(i => i.value === parseInt(branchId))?.label;
}

export default AccountInvoiceAR;
