/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {util} from '../../../services/service';
import {FieldArray, Form, Formik} from 'formik';
import {DefaultButton, PrimaryButton} from "../../../components/buttons";
import {Button} from "reactstrap";
import {apiMoving} from "../../../services/newCoship/service";
import {useSelector} from "react-redux";
import {setPackDate} from "../../../redux/reducers/newCoshipReducer";

class ForwardingHouseBLPackingListFormManager extends FormManager {
  modal;
  printModal;
  houseBLForm;
  customerForm;
  workDetailsForm;
  constructor() {
    super({
      prefix: `forwarding-house-packing-list-form`,
      fields: [{name: 'pack_date', serverName: 'pack_date', label: 'Date', type: 'date', mask: util.MASK_DATE, smallMargin: true}],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f} = this;
    const qs = util.getQS();
    const {id} = qs;
    const {kind, packDate, packFreight} = useSelector(state => state['newCoship']);
    const [dataSource, setDataSource] = React.useState({packingList: [{item: '', material: '', qty: '', unit_price: '', value: ''}]});
    _f('pack_date').onChange = (_, value) => {
      setPackDate(util.dispatch, value);
    };

    const onSearch = () => {
      apiMoving.getPackingList({bl_id: util.toInt(id)}).then((res) => {
        if (res) {
          const list = res?.data?.map((data) => ({...data, value: data.qty * data.unit_price}));
          setDataSource({packingList: list?.length ? list : [{item: '', material: '', qty: '', unit_price: '', value: ''}]});
        }
      });
      this.setValue('pack_date', packDate);
    };

    const onSubmit = () => {
      const packing_list = dataSource.packingList.map((data) => {
        return {item: data.item, material: data.material, qty: data.qty ? util.toInt(data.qty) : 0, unit_price: data.unit_price ? util.toFloat(data.unit_price) : 0};
      });
      const payload = {
        bl_id: util.toInt(id),
        packing_list,
      };
      apiMoving.setPackingList(payload).then((res) => {
        if (res) {
          util.showSuccess('Packing List has been saved successfully!');
          onClose();
        }
      });
    };

    const onPrint = () => {
      const blData = {...this.houseBLForm.getValues(), ...this.customerForm.getValues(), ...this.workDetailsForm.getValues()};
      const data = [{...blData, pack_list: dataSource.packingList}];
      this.printModal.open(data);
    };

    const onClose = () => {
      this.modal.close()
    };

    React.useEffect(() => {
      onSearch();
    }, []);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div style={{height: 500, overflow: 'auto'}}>
          <div className={'flex w-full'}>
            <div className={'flex-1 ml-2 mr-4'}>
              <div>
                <Formik initialValues={dataSource} onSubmit={onSubmit}>
                  {({ values, handleChange}) => {
                    return (
                      <Form>
                        <FieldArray name={'packingList'}>
                          {({push, remove}) => {
                            const onAdd = () => {
                              setDataSource({packingList: [...dataSource.packingList, {item: '', material: '', qty: '', unit_price: '', value: ''}]});
                              push({item: '', material: '', qty: '', unit_price: '', value: ''});
                            };
                            const onRemove = (index) => {
                              setDataSource({packingList: dataSource.packingList.filter((_, idx) => idx !== index)});
                              remove(index);
                            };
                            const onChange = (e, index) => {
                              const item = document.getElementById(`packingList[${index}].item`).value;
                              const material = document.getElementById(`packingList[${index}].material`).value;
                              const qty = document.getElementById(`packingList[${index}].qty`).value;
                              const unitPrice = document.getElementById(`packingList[${index}].unit_price`).value;
                              const value = (qty ? util.toInt(qty) : 0) * (unitPrice ? util.toFloat(unitPrice) : 0);
                              document.getElementById(`packingList[${index}].value`).innerHTML = value;
                              const newDataSource = {packingList: dataSource.packingList.map((data, idx) => {
                                if (index === idx) {
                                  return {item, material, qty, unit_price: unitPrice, value};
                                } else {
                                  return data;
                                }
                              })}
                              setDataSource(newDataSource);
                              handleChange(e);
                            };
                            const onEnter = (e) => {
                              if (e.key === 'Enter') {
                                onAdd();
                              }
                            };
                            const packingListLen = dataSource.packingList?.length ?? 0;
                            return (
                              <div>
                                {dataSource?.packingList?.map((data, index) => (
                                  <div key={index} className={'flex mt-2'}>
                                    <div className={'flex-5 mr-2'}>
                                      {index === 0 && <div>Item</div>}
                                      <input id={`packingList[${index}].item`} name={`packingList[${index}].item`} value={data.item} onChange={(e) => onChange(e, index)} onKeyUp={onEnter}/>
                                    </div>
                                    <div className={'flex-5 mr-2'}>
                                      {index === 0 && <div>Material</div>}
                                      <input id={`packingList[${index}].material`} name={`packingList[${index}].material`} value={data.material} onChange={(e) => onChange(e, index)} onKeyUp={onEnter}/>
                                    </div>
                                    <div className={'flex-1 mr-2'}>
                                      {index === 0 && <div>Qty</div>}
                                      <input id={`packingList[${index}].qty`} name={`packingList[${index}].qty`} value={data.qty} onChange={(e) => onChange(e, index)} onKeyUp={onEnter}/>
                                    </div>
                                    <div className={'flex-2 mr-2'}>
                                      {index === 0 && <div>Unit Value</div>}
                                      <input id={`packingList[${index}].unit_price`} name={`packingList[${index}].unit_price`} value={data.unit_price} onChange={(e) => onChange(e, index)} onKeyUp={onEnter}/>
                                    </div>
                                    <div className={'flex-2 mr-2'}>
                                      {index === 0 && <div>Value</div>}
                                      <div style={{border: '1px solid lightGray', height: 32}}><div id={`packingList[${index}].value`} style={{paddingTop: 5, paddingLeft: 15}}>{dataSource.packingList[index].value}</div></div>
                                    </div>
                                    <div className={'mr-2'}>
                                      {index === 0 && <div style={{marginTop: -6}}><PrimaryButton label={'+'} onClick={onAdd} noMargin/></div>}
                                      <div style={{paddingTop: 2}}><DefaultButton label={'x'} disabled={packingListLen === 1} onClick={() => onRemove(index)} noMargin/></div>
                                    </div>
                                  </div>
                                ))}
                                <div style={{textAlign: 'right', marginRight: 8}}><PrimaryButton label={'+'} onClick={onAdd} noMargin/></div>
                              </div>
                            )
                          }}
                        </FieldArray>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: 50, textAlign: 'center'}}>
          <Button size={'sm'} type={'button'} color={'primary'} className={'no-margin mr-1'} onClick={onSubmit}>{'Save'}</Button>
          <Button size={'sm'} type={'button'} color={'primary'} className={'no-margin mr-1'} onClick={onPrint}>{'Print'}</Button>
          <Button size={'sm'} type={'button'} className={'no-margin'} onClick={onClose}>Close</Button>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default ForwardingHouseBLPackingListFormManager;
