/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {api, util} from '../../../services/service';
import {setCarrierType, setBlStatus} from "../../../redux/reducers/newCoshipReducer";
import {FD, FN} from '../../field-defs/newCoship';

class MovingMasterBLFormManager extends FormManager {
  constructor() {
    super({
      prefix: `moving-master-form`,
      fields: [
        FD.MBL_NO,
        FD.MAWB_NO,
        FD.BOOKING_NO,
        FD.PORT_TYPE,
        FD.BRANCH_ID,
        FD.KIND,
        FD.TRADE_TYPE,
        FD.BL_STATUS,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const carrierType = _v(FN.PORT_TYPE);
    _f(FN.BRANCH_ID).options = api.useAreaOptions(true);

    // React.useEffect(() => {
    //   if (carrierType) {
    //     setCarrierType(util.dispatch, carrierType);
    //   } else {
    //     _c(FN.CARRIER_TYPE, 'O');
    //     setCarrierType(util.dispatch, 'O');
    //   }
    // }, [carrierType]);
    React.useEffect(() => {
      if (!_v(FN.PORT_TYPE)) {
          _c(FN.PORT_TYPE, 'O');
          setCarrierType(util.dispatch, 'O');
      }
    }, [_v(FN.PORT_TYPE)]);


    React.useEffect(() => {
      if (_v(FN.BL_STATUS)) {
        setBlStatus(util.dispatch, _v(FN.BL_STATUS));
      } else {
        _c(FN.BL_STATUS, 'CREATED');
        setBlStatus(util.dispatch, 'CREATED');
      }
    }, [_v(FN.BL_STATUS)]);

    return(
      <div style={{width: 'calc(100% + 8px)',  marginTop: 30}}>
        <div className={'flex w-full'}>
          {carrierType !== 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>}
          {carrierType === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MAWB_NO)}</div>}
          <div className={'flex-1 mr-2'}>{_r(FN.BOOKING_NO)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.PORT_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.BRANCH_ID)}</div>
        </div>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.TRADE_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.BL_STATUS)}</div>
          <div className={'flex-1 mr-2'}>{''}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default MovingMasterBLFormManager;
