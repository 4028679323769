/**
* @flow
*/
import {util} from "../../services/service";
import {apiForwarding} from "../../services/newCoship/service";
import {useDataGridView} from "../../components/DataGridView";
import React from "react";

// export default function useAccountProfitGridView(onActionCb) {
export default function useAccountProfitGridView(onGridReady, onQueryData) {
  return useDataGridView({
    async onAction(action, data) {
      // await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      // return apiAccount.profitList(gridInfo);
      const res = await apiForwarding.getProfitList(gridInfo);
      let tMargin = 0;
      if (res?.data?.length > 0) {
        res.data.forEach(value => {
          tMargin += value.margin;
        })
      }
      await onQueryData(tMargin);
      return res;
    },
    onCellValueChange(e) {
      // TODO
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
      if (headerName !== 'Actions') {
        // TODO
      }
    },
    name: `ListProfit`,
    label: 'Profit List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Account'},
    menuLabel: {label: 'Profit List'},
    sortCol: 'id',
    sortDesc: true,
    pageRows: 20,
    // actions: ['detail'],
    actions: [],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    onGridReady,
    columns: [
      {field: 'bl_id', hide: true},
      {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, flex: 1},
      // {field: 'filing_no', headerName: 'Filing No', cellRendererFramework: (param) => blAccountLinkFormatter(param, 'FN'), flex: 1},
      // {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: (param) => blAccountLinkFormatter(param, 'BL'), flex: 1},
      {field: 'bl_no', headerName: 'HB/L No.', cellRendererFramework: (param) => blLinkFormatter(param), flex: 1},
      {field: 'pol', headerName: 'POL', flex: 1},
      {field: 'pod', headerName: 'POD', flex: 1},
      // {field: 'amount_ar', headerName: 'A/R', valueFormatter: util.currencyFormatter, flex: 1},
      // {field: 'amount_ap', headerName: 'A/P', valueFormatter: util.currencyFormatter, flex: 1},
      // {field: 'amount_cm', headerName: 'C/M', valueFormatter: util.currencyFormatter, flex: 1},
      // {field: 'amount_dc', headerName: 'D/C', valueFormatter: util.currencyFormatter, flex: 1},
      // {field: 'amount_balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, flex: 1},
      // {field: 'amount_revenue', headerName: 'Revenue', valueFormatter: util.currencyFormatter, flex: 1}
      {field: 'ar_total', headerName: 'A/R', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'ap_total', headerName: 'A/P', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'cm_total', headerName: 'C/M', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'dc_total', headerName: 'D/C', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'margin', headerName: 'Margin', valueFormatter: util.currencyFormatter, flex: 1}
    ],
  });
}

// function blAccountLinkFormatter(param, mode) {
//   const accountURL = mode === 'FN' ? `filingNo=${param.data['filing_no']}` : `blNo=${param.data['bl_no']}`;
//   return (
//     <div>
//       {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
//       <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
//         e.preventDefault();
//         util.openTab(`/admin/account/list?${accountURL}`);
//       }}>
//         {param.value}
//       </a>
//     </div>
//   );
// }
function  blLinkFormatter(param) {
    const blId = param.data['bl_id'] ?? param.data['id'];
    // const HM = param.data['h_m'] ?? 'H';
    let kind_str = 'courier';
    if(param.data['kind'] === 'M' || param.data['kind'] === 'V') {
      kind_str = 'moving';
    } else if(param.data['kind'] === 'F') {
      kind_str = 'forwarding';
    }
    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
          e.preventDefault();
          util.openTab(`/admin/${kind_str}/house/customer?id=${blId}`);
          // if(HM === 'H') {
          //   util.openTab(`/admin/${kind_str}/house/customer?id=${blId}`);
          // } else {
          //   util.openTab(`/admin/${kind_str}/master/mbl?id=${blId}`);
          // }
        }}>
          {param.value}
        </a>
      </div>
    );
  }
