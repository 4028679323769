/**
 * @flow
 */
import React from 'react';
import {useFormik} from 'formik';
import {Button, Card, CardBody} from 'reactstrap';
import MaskedInput from 'react-text-mask';
import isEmail from 'validator/es/lib/isEmail';
import isNumeric from 'validator/es/lib/isNumeric';
import {CheckBoxField} from '../shared/components/form/CheckBox';
import {util} from '../services/service';
import DatePicker from 'react-datepicker';
import {isMobileOnly} from "react-device-detect";
import moment from 'moment';
import Select from "react-select";

export type FormFieldType = 'email' | 'date' | 'password' | 'numeric' | 'textarea' | 'checkbox' | 'radio' | 'datePicker';
export type FormFieldOption = {value: any, label: string};

export interface FormField {
  name: string;
  serverName?: string;
  label?: string;
  headerName?: string;
  type?: FormFieldType;
  required?: boolean;
  mask?: string;
  regex?: RegExp;
  maxLength?: number;
  disabled?: boolean;
  readonly?: boolean;
  options?: FormFieldOption[];
  defaultValue?: any;
  textAreaRows?: number;
  radioValue?: any;
  radioName?: string;
  onChange?: (e: any) => void;
  onEnter?: (name: string, value: string) => void;
  smallMargin?: boolean;
  smallLabel?: boolean;
  noDefOption?: boolean;
  placeholder?: string;
  noAutoFill?: boolean; // 자동완성 팝업이 뜨지 않도록 설정
  onBlur?: (e: any) => void; // 포커스 블러 핸들러
  doNotClearOnBlur?: boolean; // 포커스 블러시 필드를 지우지 않음
  useSameAsInput?: boolean; // 대문자로 자동변화하지 않음
  mutilSelect?: boolean;
  style?: any;
}

export interface FormProps {
  title?: string;
  subtitle?: string;
  horizontal?: boolean;
  doNotUseCard?: boolean;
  doNotUseButtons?: boolean;
  fields: FormField[],
  values?: any;
  onSubmit?: (values: Object) => void;
  onDelete?: (values: Object) => void;
  onCancel?: () => void;
  onChange?: (name: string, value: any) => void;
  render: (formik: any, fields: FormField[]) => React$Node;
  renderHeader?: () => React$Node;
  onFormik?: (formik: any) => void;
}

let onChangeFromProps; // hacky but very effective!

const Form = (props: FormProps) => {
  const [errors, setErrors] = React.useState();
  const formik = useFormik({
    initialValues: props.values ?? {},
    validateOnBlur: true,
    onSubmit: values => {
      const errors = {};
      let errorCount = 0;
      for (const field of props.fields) {
        const value = values[field.name] ?? '';
        const errorMessage = validateField(field, value);
        if (errorMessage !== '') {
          errors[field.name] = errorMessage;
          ++errorCount;
        }
      }
      if (errorCount > 0) {
        setErrors(errors);
      } else {
        const valuesToSubmit = {};
        for (const field of props.fields) {
          if (field.type === 'checkbox') {
            if (Array.isArray(values[field.name])) {
              valuesToSubmit[field.name] = values[field.name][0] === 'on';
            } else if (values[field.name] === true || values[field.name] === false) {
              valuesToSubmit[field.name] = values[field.name];
            } else {
              valuesToSubmit[field.name] = false;
            }
          } else {
            valuesToSubmit[field.name] = values[field.name];
          }
        }
        props.onSubmit(valuesToSubmit);
      }
    },
  });
  props.onFormik && props.onFormik(formik);
  onChangeFromProps = props.onChange;
  if (props.doNotUseCard === true) {
    return (
      <>
        {(props.title || props.subtitle) && (
          <div className="card__title">
            {props.title && <h5 className="bold-text">{props.title}</h5>}
            {props.subtitle && <h5 className="subhead">{props.subtitle}</h5>}
          </div>
        )}
        {props.renderHeader && props.renderHeader()}
        <form
          className={`form${props.horizontal === true ? ' form--horizontal' : ''}`}
          onSubmit={formik.handleSubmit}
        >
          {props.render(formik, props.fields, errors)}
          {props.doNotUseButtons !== true && (
            <div>
              {props.onSubmit && (
                <Button color={'primary'} size={'sm'} type={'submit'} className={'no-margin mr-1'}>Save</Button>
              )}
              {props.onDelete && (
                <Button
                  color={'danger'}
                  size={'sm'}
                  type={'button'}
                  className={'no-margin mr-1'}
                  onClick={() => props.onDelete(formik.values)}
                >Delete</Button>
              )}
              <Button size={'sm'} onClick={props.onCancel} className={'no-margin ml-1'}>Cancel</Button>
            </div>
          )}
        </form>
      </>
    );
  } else {
    return (
      <Card className={'pb-0'}>
        <CardBody className={'p-24'}>
          <div className={'card__title mb-12'}>
            {props.title && <h5 className="bold-text">{props.title}</h5>}
            {props.subtitle && <h5 className="subhead">{props.subtitle}</h5>}
          </div>
          {props.renderHeader && props.renderHeader()}
          <form className={`form${props.horizontal === true ? ' form--horizontal' : ''}`} onSubmit={formik.handleSubmit}>
            <div className={'p-16 w-full mb-16'} style={{border: '1px solid #e0e0e0', borderRadius: 4, paddingBottom: 8}}>
              {props.render(formik, props.fields, errors)}
              {renderEditorInfo(props.values)}
            </div>
            {props.doNotUseButtons !== true && (
              <div className={'flex center w-full'}>
                {props.onCopy && (
                  <Button
                     color={'success'} size={'sm'} type={'button'} className={'no-margin mr-1'}
                    onClick={() => props.onCopy(formik.values)}
                  >
                    Copy
                  </Button>
                )}
                {props.onSubmit && <Button color={'primary'} size={'sm'} type={'submit'} className={'no-margin mr-1'}>Save</Button>}
                {props.onDelete && (
                  <Button
                    color={'danger'} size={'sm'} type={'button'} className={'no-margin mr-1'}
                    onClick={() => props.onDelete(formik.values)}
                  >
                    Delete
                  </Button>
                )}
                <Button size={'sm'} onClick={props.onCancel} className={'no-margin ml-1'}>Cancel</Button>
              </div>
            )}
          </form>
        </CardBody>
      </Card>
    );
  }
};

export function isChecked(formik, name: string, defaultValue = false) {
  if (formik) {
    return (formik.values[name])?.[0] === 'on' || formik.values[name] === true;
  }
  return defaultValue;
}

export function renderField(formik, name: string, fields: FormField[], errors: Object, startIcon, endComponent) {
  const field = fields.find(i => i.name === name);
  const {label, headerName, required} = field;
  const hasError = errors && errors.hasOwnProperty(name);
  const labelCName = field.type !== 'radio' && (field.label || field.headerName) ? 'form__form-group-field' : 'form__form-group-field-no-label'
  return (
    <div className={`form__form-group${field.smallMargin === true ? ' mb-1' : ''}`} key={name}>
      {(field.type !== 'checkbox' && field.type !== 'radio') && (field.label || field.headerName) && (
        <label className={`form__form-group-label${field.smallLabel === true ? ' f-12' : ''}`} htmlFor={name}>
          {label ?? headerName}
          {required === true ? <b className={'red-text'} style={{marginLeft: 4}}>*</b> : undefined}
        </label>
      )}
      <div className={labelCName}>
        {startIcon && <div className="form__form-group-icon">{startIcon}</div>}
        <div className={'form__form-group-input-wrap'}>
        {hasError ? (
          <>
            {renderInput(formik, field)}
            {hasError && <span className={'form__form-group-error'}>{errors[name]}</span>}
          </>
        ) : (
          renderInput(formik, field)
        )}
        </div>
        {endComponent}
      </div>
    </div>
  );
}

const orgTypes = ['password', 'checkbox'];

function getInputType(field: FormField) {
  if (orgTypes.find(i => i === field.type)) {
    return field.type;
  }
  return 'text';
}

function handleChange(formik, field: FormField, e) {
  const {target: {value}} = e;
  formik.handleChange(e);
  if (field.onChange) {
    field.onChange(field.name, value, e);
  }
  if (onChangeFromProps) {
    onChangeFromProps(field.name, value);
  }
}

function handleEnter(formik, field: FormField, e) {
  if (field.onEnter&& e.key === 'Enter') {
    field.onEnter(e.target.name, e.target.value, e);
  }
}

function renderInput(formik, field: FormField) {
  const {name} = field;
  if (field.type === 'datePicker') {
    return renderDatePicker(formik, field);
  }
  if (field.type === 'date') {
    return renderDatePicker(formik, field);
  }
  if (field.type === 'checkbox') {
    return renderCheckbox(formik, field);
  }
  if (field.type === 'radio') {
    return renderRadio(formik, field);
  }
  if (field.options !== undefined) {
    if (field.mutilSelect) {
      const serverData = formik.values[name] || [];
      const theme = (theme: Theme) => ({
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 32,
          baseUnit: 0,
        }
      });
      const styles = {
        control: base => ({...base, borderRadius: 0}),
        option: base => ({...base, paddingLeft: 10, paddingTop: 5}),
        valueContainer: base => ({...base, paddingLeft: 5}),
        multiValue: base => ({...base, margin: 3}),
      };
      const handleMutilSelected = (data) => {
        const values = data?.map(({value}) => value);
        const isClear = values?.includes(-1);
        const dataInfo = isClear ? [-1] : values;
        if (field.onChange) {
          field.onChange(field.name, dataInfo);
        }
        if (onChangeFromProps) {
          onChangeFromProps(field.name, dataInfo);
        }
      }
      const selectedData = field.options.filter(({value}) => serverData.includes(value));
      return (
        <Select
          id={name}
          name={name}
          onChange={handleMutilSelected}
          value={selectedData}
          disabled={field.disabled}
          className={field.required === true ? 'required-color' : 'not-required-color'}
          options={field.options}
          theme={theme}
          styles={styles}
          isMulti={true}
        />
      );
    } else {
      return (
        <select
          id={name}
          name={name}
          onChange={e => handleChange(formik, field, e)}
          value={formik.values[name] ?? field.defaultValue ?? ''}
          disabled={field.disabled}
          className={field.required === true ? 'required-color' : 'not-required-color'}
        >
          {field.noDefOption !== true && <option value={''}>Select...</option>}
          {field.options.map(o => <option value={o.value} key={o.value}>{o.label}</option>)}
        </select>
      );
    }
  }
  if (field.mask) {
    return (
      <MaskedInput
        id={name}
        name={name}
        type={getInputType(field)}
        onChange={e => handleChange(formik, field, e)}
        onKeyUp={e => handleEnter(formik, field, e)}
        value={formik.values[name] ?? field.defaultValue ?? ''}
        mask={field.mask}
        guide={false}
        disabled={field.disabled}
        readOnly={field.readonly}
        placeholder={field.placeholder}
        onBlur={field.onBlur}
        className={field.required === true ? 'required-color' : 'not-required-color'}
      />
    );
  } else {
    if (field.type === 'textarea') {
      return (
        <textarea
          id={name}
          name={name}
          onChange={e => handleChange(formik, field, e)}
          disabled={field.disabled}
          rows={field.textAreaRows ?? 5}
          style={{height: 'initial', minHeight: 'initial', ...field.style}}
          value={formik.values[name] ?? field.defaultValue ?? ''}
          readOnly={field.readonly}
          placeholder={field.placeholder}
          className={field.required === true ? 'required-color' : 'not-required-color'}
        />
      );
    } else {
      return (
        <input
          id={name}
          name={name}
          type={getInputType(field)}
          onChange={e => handleChange(formik, field, e)}
          onKeyUp={e => handleEnter(formik, field, e)}
          value={formik.values[name] ?? field.defaultValue ?? ''}
          disabled={field.disabled}
          readOnly={field.readonly}
          placeholder={field.placeholder}
          autoComplete={field.noAutoFill === true ? 'off' : undefined}
          onBlur={field.onBlur}
          className={field.required === true ? 'required-color' : 'not-required-color'}
        />
      );
    }
  }
}

function renderDatePicker(formik, field: FormField) {
  // let value = formik.values[field.name];
  let value = formik.values[field.name] || field.defaultValue;
  if (typeof value === 'string' || typeof value === 'number') {
    const d = moment(value);
    value = d.isValid() ? d.toDate() : undefined;
    // const val = util.formatD(value);
    // handleChange(formik, field, {target: {name: field.name, val}});
  }
  const onChange = (date) => {
    const value = util.formatD(date);
    handleChange(formik, field, {target: {name: field.name, value}});
  };
  const requiredStyle = field.required ? 'required-color' : 'date-picker';
//   return (
//     <div className={requiredStyle}>
//       <DatePicker
//         selected={value}
//         onChange={onChange}
//         dateFormat={'yyyy-MM-dd'}
//         withPortal={isMobileOnly}
//         disabled={field.disabled}
//       />
//     </div>
//   );
  return (
    <div className={requiredStyle}>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div style={{margin: 10, display: "flex", justifyContent: "center"}}>
            <button type={'button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
            <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
              {util.getRangeYears().map(option => (<option key={option} value={option}>{option}</option>))}
            </select>
            <select value={util.getMonths()[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(util.getMonths().indexOf(value))}>
              {util.getMonths().map(option => (<option key={option} value={option}>{option}</option>))}
            </select>
            <button type={'button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
          </div>
        )}
        selected={value}
        onChange={onChange}
        dateFormat={'yyyy-MM-dd'}
        withPortal={isMobileOnly}
        disabled={field.disabled}
      />
    </div>
  );
}

function renderCheckbox(formik, field: FormField) {
  let value;
  const {name} = field;
  if (Array.isArray(formik.values[name])) {
    value = formik.values[name][0] === 'on';
  } else if (formik.values[name] === true || formik.values[name] === false) {
    value = formik.values[name];
  } else {
    value = field.defaultValue ?? false;
  }
  return (
    <CheckBoxField
      onChange={e => handleChange(formik, field, e)}
      value={value}
      name={name}
      disabled={field.disabled}
      label={field.label ?? field.headerName}
    />
  );
}

function renderRadio(formik, field: FormField) {
  return (
    <label className={'radio-btn'}>
      <input
        className={'radio-btn__radio'}
        name={field.radioName}
        value={field.radioValue}
        type={'radio'}
        onChange={e => handleChange(formik, field, e)}
        checked={formik.values[field.radioName] === field.radioValue}
        disabled={field.disabled}
      />
      <span className="radio-btn__radio-custom" />
      <span className="radio-btn__label">{field.label ?? field.headerName}</span>
    </label>
  );
}

function validateField(field: FormField, value: string): string {
  if (field.required === true && value.length <= 0) {
    if(field.name === 'craftNo' && field.hide && field.label === undefined && field.headerName === undefined) {
      return `Please enter VOY.`;
    } else {
      return `Please enter ${field.label ?? field.headerName}.`;
    }
  }
  if (value.length > 0) {
    if (field.type === 'email' && !isEmail(value)) {
      return `Please enter a valid email.`;
    }
    if (field.type === 'date') {
      // if (!isBefore(value)) {
      //   return 'Please enter a valid date.';
      // }
    }
    if (field.type === 'numeric' && !isNumeric(value)) {
      return `Please enter a number.`;
    }
    if (field.maxLength !== undefined && value.length > field.maxLength) {
      return `Too long. (max ${field.maxLength} letters)`;
    }
    if (field.regex !== undefined) {
      if (value.match(field.regex) === null) {
        return 'Please enter a valid value.';
      }
    }
  }
  return '';
}

function renderEditorInfo(values?: any) {
  const labels = [];
  const [c_ts = 0, c_name] = values?.footer?.create ?? [];
  const [e_ts = 0, e_name] = values?.footer?.edit ?? [];
  if (c_ts > 0) {
    labels.push(`Created by ${c_name} (${util.formatDate(c_ts)})`);
  }
  if (e_ts > 0) {
    labels.push(`Edited by ${e_name} (${util.formatDate(e_ts)})`);
  }
  if (labels.length > 0) {
    return labels.map(label => <div key={label} style={{fontSize: 11, color: '#606060'}}>{label}</div>);
  } else {
    return null;
  }
}

export default Form;
