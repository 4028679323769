/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {api, apiBL, util} from '../../../services/service';
import {FD, FN} from '../../field-defs/newCoship';
import {apiCourier} from '../../../services/newCoship/service';
import {setBlStatus, setBound, setBranch, setCarrierType, setFilingNo, setFlightData, setKind, setMawbNo} from "../../../redux/reducers/newCoshipReducer";
import {useUser} from "../../../redux/reducers/userReducer";
import {useSelector} from "react-redux";
import {DefaultButton} from "../../../components/buttons";

class CourierHouseBLFormManager extends FormManager {
  mawbList;
  constructor() {
    super({
      prefix: `courier-house-form`,
      fields: [
        FD.ID, FD.HBL_NO, FD.HAWB_NO, FD.BRANCH_ID, FD.TRADE_TYPE, FD.MBL_NO, FD.MAWB_NO, {...FD.PORT_TYPE, defaultValue: 'A'},
        {...FD.KIND, defaultValue: 'C'}, FD.BL_STATUS, {...FD.P_DATE, defaultValue: util.getCurrentDate()}, FD.P_TIME,
        FD.FLIGHT_DATE, FD.ETD, FD.ETA, FD.MAPLE_TYPE,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.mawbList = [];
  };

  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const qs = util.getQS();
    const {id} = qs;
    const user = useUser();
    const [carrier, setCarrier] = React.useState();
    const [service, setService] = React.useState();
    const [mawbOps, setMawbOps] = React.useState([]);
    // const [masterOptions, setMasterOptions] = React.useState([]);
    // const blStatus = _v(FN.BL_STATUS);
    // const branchId = _v(FN.BRANCH_ID);
    const {carrierType, bound, filingNo} = useSelector(state => state['newCoship']);
    const branchOptions = api.useAreaOptions(true);
    const useMawbOptions = (kind, bound, branch) => {
      let options = [];
      const bl_id = id ? id : _v(FN.ID);
      if(branch === undefined) {
        branch = _v(FN.BRANCH_ID) ?? user.branch_id;
      }
      if (kind === undefined) {
        kind = _v(FN.KIND) ?? "C";
      }
      if (bound === undefined) {
        bound = _v(FN.TRADE_TYPE) ?? "E";
      }
      apiCourier.getComingMAWB({bl_id: util.toInt(bl_id), kind: kind ?? "C", bound: bound ?? "E", branch_id: util.toInt(branch) ?? 4}).then((res) => {
        if (res) {
          const list = res.data ?? [];
          for (const [masterNo, filingNo] of list) {
            options.push({key: masterNo, value: masterNo, label: masterNo, filingNo, masterNo});
          }
          setMawbOps(options);
        }
      }).catch();
    };
    _f(FN.BRANCH_ID).options = branchOptions;
    _f(FN.FLIGHT_DATE).disabled = true;
    _f(FN.ETD).disabled = true;
    _f(FN.ETA).disabled = true;
    _f(FN.BRANCH_ID).onChange = (_, value) => onChangeBranch(value);
    _f(FN.KIND).onChange = (_, value) => onChangeKind(value);
    _f(FN.TRADE_TYPE).onChange = (_, value) => onChangeBound(value);
    _f(FN.BL_STATUS).onChange = (_, value) => onChangeBlStatus(value);
    _f(FN.PORT_TYPE).onChange = (_, value) => onChangeCarrierType(value);
    // _f(FN.MAWB_NO).options = this.mawbList;
    // if (id) {
    //   // setMawbOps(this.mawbList);
    // } else {
    //   useMawbOptions();
    // }
    _f(FN.MAWB_NO).options = mawbOps;
    _f(FN.MAWB_NO).onChange = (_, value) => onChangeMawbNo(value);

    const onChangeBranch = (value) => {
      useMawbOptions(undefined, undefined, value);
    };

    const onChangeKind = (value) => {
      _c(FN.KIND, value);
      setService(value);
      setKind(util.dispatch, value);
      useMawbOptions(value, undefined, undefined);
    };

    const onChangeBound = (value) => {
      _c(FN.TRADE_TYPE, value);
      setBound(util.dispatch, value);
      useMawbOptions(undefined, value, undefined);
    };

    const onChangeBlStatus = (value) => {
      _c(FN.BL_STATUS, value);
    };

    const onChangeCarrierType = (value) => {
      _c(FN.PORT_TYPE, value);
      setCarrier(value);
      setCarrierType(util.dispatch, value);
    };

    const onChangeMawbNo = (value) => {
      _c(FN.MAWB_NO, value);
      if (value) {
        apiBL.getCraftInfo(value).then(res => {
          const data = res.data[0];
          if(data) {
            _c(FN.FLIGHT_DATE, data.jextra.etdDate);
            const flightData = {flightNo: data.vol_voy, flightDate: data.jextra.etdDate, arrivalDate: data.jextra.etaDate, departure: data.pol, destination: data.pod};
            setFlightData(util.dispatch, flightData);
            setFilingNo(util.dispatch, data.filing_no);
          }
        });
      }
    };

    // const getMasterOptions = () => {
    //   let options = [];
    //   const branchId = _v(FN.BRANCH_ID);
    //   // if (bound && carrierType === 'A' && branchId && kind) {
    //   // console.log("..............bound:", bound, ", carrier:", carrierType, ", branchId:", branchId, ", service:", service);
    //   if (bound && carrierType === 'A' && branchId && service)  {
    //     // console.log(".............this.mawbNo:", this.cMawbNo);
    //     // options.push({key: mawbNo, value: mawbNo, label: mawbNo, filingNo, mawbNo});
    //     options.push({key: this.cMawbNo, value: this.cMawbNo, label: this.cMawbNo, filingNo, mawbNo: this.cMawbNo});
    //     // apiBL.getMasterList({carrierType, bound, branchId, kind: _v(FN.KIND)}).then((res) => {
    //     apiBL.getMasterList({carrierType, bound, branchId, kind: service}).then((res) => {
    //       const {data: {lists = []}} = res;
    //       for (const [masterNo, filingNo] of lists) {
    //         if (masterNo) {
    //           options.push({key: masterNo, value: masterNo, label: masterNo, filingNo, masterNo});
    //         }
    //       }
    //       // setMasterOptions([{key: undefined, value: undefined, label: undefined, filingNo: undefined, masterNo: undefined}, ...options]);
    //       setMasterOptions([...options]);
    //     });
    //   } else {
    //     setMasterOptions([]);
    //   }
    // };


    const onDeleteMawbNo = () => {
      // const mawbNo = _v('mawbNo');
      // if (mawbNo) {
        util.showConfirm('Are you sure to delete Master No?', () => {
          const id = _v(FN.ID);
          if (id) {
            apiBL.resetMbl(util.toInt(id)).catch();
          }
          _c(FN.MAWB_NO, '');
          _c(FN.FLIGHT_DATE, undefined);
          setFlightData(util.dispatch, {});
          setFilingNo(util.dispatch, undefined);
          setMawbNo(util.dispatch, undefined);
          // setMasterOptions([]);
          this.mawbList = [];
        });
      // }
    };

    React.useEffect(() => {
      let branchId = _v(FN.BRANCH_ID);
      branchId = branchId ? util.toInt(branchId) : branchId;
      if (!branchId) {
        _c(FN.BRANCH_ID, user.branch_id);
       } else {
        _c(FN.BRANCH_ID, branchId);
      }
      const branch = branchOptions.find(x => x.value === branchId)?.label;
      setBranch(util.dispatch, branch);
    }, [_v(FN.BRANCH_ID), branchOptions]);
    // }, [user.branch_id, _v(FN.BRANCH_ID), branchOptions]);

    React.useEffect(() => {
      setCarrier(carrierType);
    }, [carrierType]);

    React.useEffect(() => {
      if (_v(FN.KIND) === undefined) {
        setService('C');
        _c(FN.KIND, 'C');
        setKind(util.dispatch, 'C');
      }
    }, [_v(FN.KIND), user.branch_id, branchOptions]);

    React.useEffect(() => {
      if (!_v(FN.PORT_TYPE)) {
        const loginBranch = branchOptions.find(i => i.value === parseInt(user.branch_id))?.label;
        if (loginBranch === 'SEOUL') {
          setCarrier('O');
          _c(FN.PORT_TYPE, 'O');
          setCarrierType(util.dispatch, 'O');
        } else {
          setCarrier('A');
          _c(FN.PORT_TYPE, 'A');
          setCarrierType(util.dispatch, 'A');
        }
      }
    }, [_v(FN.PORT_TYPE), user.branch_id, branchOptions]);

    React.useEffect(() => {
      if (!_v(FN.TRADE_TYPE)) {
        _c(FN.TRADE_TYPE, 'E');
        setBound(util.dispatch, 'E');
      }
    }, [_v(FN.TRADE_TYPE), user.branch_id, branchOptions]);

   React.useEffect(() => {
      if (_v(FN.BL_STATUS)) {
        setBlStatus(util.dispatch, _v(FN.BL_STATUS));
      } else {
        _c(FN.BL_STATUS, 'CREATED');
        setBlStatus(util.dispatch, 'CREATED');
      }
    }, [_v(FN.BL_STATUS)]);

   React.useEffect(() => {
     useMawbOptions(undefined, undefined, undefined);
     }, [this.mawbList]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'flex w-full mt-2'}>
          <div className={'flex-1 mr-2'}>{_r(FN.P_DATE)}</div>
          {carrier !== 'A' && <div className={'flex-1 mr-2'}>{_r(FN.HBL_NO)}</div>}
          {carrier === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.HAWB_NO)}</div>}
          {carrier === 'O' && <div className={'flex-1 mr-2'}>{_r(FN.ETD)}</div>}
          {/*{(carrier === 'A' && service === 'C') && <div className={'flex-1'}>{_r(FN.FLIGHT_DATE)}</div>}*/}
          {(carrier === 'A' && _v(FN.KIND) === 'C') && <div className={'flex-1'}>{_r(FN.FLIGHT_DATE)}</div>}
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.TRADE_TYPE)}</div>
          <div className={'flex-1'}>{_r(FN.BL_STATUS)}</div>
        </div>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.P_TIME)}</div>
          {carrier === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MAWB_NO)}</div>}
          {carrier === 'G' && <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>}
          {(carrier === 'O' && _v(FN.KIND) === 'E') && <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>}
          {/*{(carrier === 'O' && _v(FN.KIND) !== 'E') && <div className={'flex-1 mr-2'}>{_r(FN.MAPLE_TYPE)}</div>}*/}
          {(carrier === 'O' && _v(FN.KIND) === 'C') && <div className={'flex-1 mr-2'}>{_r(FN.MAPLE_TYPE)}</div>}
          {carrier === 'O' && <div className={'flex-1 mr-2'}>{_r(FN.ETA)}</div>}
          {(carrier === 'A' && _v(FN.KIND) === 'C') && <div className={'flex-1 mt-1'}><DefaultButton label={'Reset'} onClick={onDeleteMawbNo}/></div>}
          <div className={'flex-1 mr-2'}>{_r(FN.BRANCH_ID)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.PORT_TYPE)}</div>
          {(carrier === 'O' && _v(FN.KIND) !== 'E') && <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>}
          {carrier === 'A' && <div className={'flex-1'}>{''}</div>}
          {carrier === 'G' && <div className={'flex-1'}>{''}</div>}
          {/*{(carrier === 'O' && _v(FN.KIND) === 'E') && <div className={'flex-1'}>{''}</div>}*/}
          {(carrier === 'O' && _v(FN.KIND) !== 'C') && <div className={'flex-1'}>{''}</div>}
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default CourierHouseBLFormManager;
