/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {PrimaryButton} from "../../../components/buttons";
import {FD, FN} from "../../field-defs/newCoship";
import {TradePartnerSearchInput} from "../../../components/SearchInput";
import {useSelector} from "react-redux";

class ForwardingHouseBLCustomerFormManager extends FormManager {
  isShowForm;
  houseBLForm;
  constructor() {
    super({
      prefix: `forwarding-house-customer-form`,
      fields: [
        FD.SHIPPER_ID, FD.SHIPPER_LOCAL, FD.SHIPPER, FD.SHIPPER_ADDR, FD.SHIPPER_ADDR_ENG, FD.SHIPPER_EMAIL, FD.SHIPPER_PHONE1, FD.SHIPPER_PHONE2,
        FD.CONSIGNEE_ID, FD.CONSIGNEE_LOCAL, FD.CONSIGNEE, FD.CONSIGNEE_ADDR_LOCAL, FD.CONSIGNEE_ADDR, FD.CONSIGNEE_EMAIL, FD.CONSIGNEE_PHONE1, FD.CONSIGNEE_PHONE2,
        FD.NOTIFY_ID, FD.NOTIFY_LOCAL, FD.NOTIFY, FD.NOTIFY_ADDR, FD.NOTIFY_EMAIL, FD.NOTIFY_PHONE, FD.SAME_AS_CONSIGNEE,
        FD.MEMO, FD.SHIPPER_STAFF, FD.CONSIGNEE_STAFF, FD.GROSS_WEIGHT_KG, FD.GROSS_WEIGHT_LB, FD.SHIPPING_ADDR,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  getBranchName(branchId) {
    let branchOptions;
    try {
      branchOptions = this.houseBLForm.getField('branchId').options;
    } catch (error) {
      branchOptions = [];
    }
    return branchOptions.find(i => i.value === parseInt(branchId))?.label;
  }
  onRender = () => {
    const {renderField: _r, setValue: _c, getValue: _v, getField: _f, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const shipperPostRef = React.useRef();
    const consigneePostRef = React.useRef();
    const sameAsConsignee = _v(FN.SAME_AS_CONSIGNEE);
    const {branch, bound} = useSelector(state => state['newCoship']);
    const isExport = bound === 'E';
    const isSeoul = branch === 'SEOUL';
    _f(FN.SHIPPER_ADDR_ENG).label = isSeoul ? ' ' : '';
    _f(FN.CONSIGNEE_ADDR).label = !isSeoul && isExport ? ' ' : '';
    _f(FN.SHIPPER_ADDR).placeholder = isSeoul ? 'Korean address' : 'English address';
    _f(FN.CONSIGNEE_ADDR_LOCAL).placeholder = isSeoul ? 'English address' : 'Korean address';
    _f(FN.SHIPPER_STAFF).label = 'Contact';
    _f(FN.CONSIGNEE_STAFF).label = 'Contact';

    const onTPartner = (name, data) => {
      const {id, value, Address, shipping_addr, phone, cell, localName, email} = data;
      _c(`${name}`, value);
      _c(`${name}Local`, localName);
      _c(`${name}Id`, id);
      _c(`${name}Email`, email);
      if (name === FN.SHIPPER) {
        // if (isSeoul) {
        //   _c(`${name}Addr`, '');
        //   _c(`${name}AddrEng`, Address);
        // } else {
        //   _c(`${name}Addr`, Address);
        //   _c(`${name}AddrEng`, Address);
        // }
        _c(`${name}Addr`, Address);
        _c(`${name}AddrEng`, Address);
        _c(`${name}Phone`, phone);
        _c(`${name}Phone2`, cell);
      }
      if (name === FN.CONSIGNEE) {
        _c(`${name}Addr`, Address);
        _c(`${name}Phone1`, phone);
        _c(`${name}Phone2`, cell);
        _c(FN.SHIPPING_ADDR, shipping_addr);
      }
      if (name === FN.NOTIFY) {
        _c(`${name}Addr`, Address);
        _c(`${name}Phone`, phone);
      }
    };

    const _tpa = (name, partner, vertical = true) => {
      _f(name).smallMargin = name !== FN.PARTNER ? true : undefined;
      return (
        <TradePartnerSearchInput
          idField={_n(`${partner}Id`)} displayField={_n(name)}
          formik={formik} fields={fields} errors={errors} vertical={vertical} onOK={(data) => onTPartner(partner, data)}
        />
      );
    };

    const onSearchAddress = (searchType: 'shipper' | 'consignee') => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          const {zonecode, roadAddress, addressEnglish, buildingName, buildingCode} = data;
          if (searchType === 'shipper') {
            shipperPostRef.current = zonecode.toUpperCase();
            _c(FN.SHIPPER_ADDR, roadAddress.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
            _c(FN.SHIPPER_ADDR_ENG, addressEnglish.toUpperCase());
            _c(FN.SHIPPER_POST, zonecode.toUpperCase());
            _c(FN.SHIPPER_BUILDING_CODE, buildingCode);
          } else {
            consigneePostRef.current = zonecode.toUpperCase();
            _c(FN.CONSIGNEE_ADDR_LOCAL, roadAddress.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
            _c(FN.CONSIGNEE_ADDR, _v(FN.CONSIGNEE) + '\n' + addressEnglish.toUpperCase());
            _c(FN.CONSIGNEE_POST, zonecode.toUpperCase());
            _c(FN.CONSIGNEE_BUILDING_CODE, buildingCode);
          }
        }
      }).open();
    };

    React.useEffect(() => {
      _f(FN.SAME_AS_CONSIGNEE).onChange = (name, value, e) => {
        const isChecked = e.target.checked;
        const consigneeLocal = isChecked ? _v(FN.CONSIGNEE_LOCAL) : undefined;
        const consignee = isChecked ? _v(FN.CONSIGNEE) : undefined;
        const consigneeId = isChecked ? _v(FN.CONSIGNEE_ID) : undefined;
        // const consigneeAddr = isChecked ? _v(FN.CONSIGNEE_ADDR) : undefined;
        const consigneeAddr = isChecked ? 'SAME AS CONSIGNEE' : undefined;
        const consigneeEmail = isChecked ? _v(FN.CONSIGNEE_EMAIL) : undefined;
        const consigneePhone1 = isChecked ? _v(FN.CONSIGNEE_PHONE1) : undefined;
        _c(FN.NOTIFY_LOCAL, consigneeLocal);
        _c(FN.NOTIFY, consignee);
        _c(FN.NOTIFY_ID, consigneeId);
        _c(FN.NOTIFY_ADDR, consigneeAddr);
        _c(FN.NOTIFY_EMAIL, consigneeEmail);
        _c(FN.NOTIFY_PHONE, consigneePhone1);
      };
    }, [sameAsConsignee]);

    return (
      <div style={{width: 'calc(100% + 8px)'}}>
        {this.isShowForm &&
          <>
            <div className={'flex pl-8 pr-20 pt-12'}>
              <div className={'flex-1'}>
                <div>{_tpa(FN.SHIPPER_LOCAL, 'shipper')}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER)}</div>
                <div className={'flex flex-1'}>
                  <div>
                    <div className={'ml-3'}>Address</div>
                    {isSeoul &&
                      <div className={'ml-2'}>
                        <PrimaryButton label={'Search'} onClick={() => onSearchAddress('shipper')} style={{whiteSpace: 'pre-line'}}/>
                      </div>
                    }
                  </div>
                  <div className={'w-full'} style={isSeoul ? {marginLeft: 5} : {marginLeft: 12}}>{isSeoul ? _r(FN.SHIPPER_ADDR) : _r(FN.SHIPPER_ADDR_ENG)}</div>
                </div>
                {isSeoul && <div className={'flex-1'} style={{marginTop: -5}}>{_r(FN.SHIPPER_ADDR_ENG)}</div>}
                <div className={'flex-1'} style={isSeoul ? {marginTop: 35} : {marginTop: 104}}>{_r(FN.SHIPPER_STAFF)}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER_EMAIL)}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER_PHONE1)}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER_PHONE2)}</div>
              </div>
              <div className={'flex-1'}>
                <div>{_tpa(FN.CONSIGNEE_LOCAL, 'consignee')}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE)}</div>
                <div className={'flex flex-1'}>
                  <div>
                    <div className={'ml-3'}>Address</div>
                    {!isSeoul && isExport &&
                      <div className={'ml-2'}>
                        <PrimaryButton label={'Search'} onClick={() => onSearchAddress('consignee')} style={{whiteSpace: 'pre-line'}}/>
                      </div>
                    }
                  </div>
                  <div className={'w-full'} style={!isSeoul && isExport ? {marginLeft: 5} : {marginLeft: 12}}>{!isSeoul && isExport ? _r(FN.CONSIGNEE_ADDR_LOCAL) : _r(FN.CONSIGNEE_ADDR)}</div>
                </div>
                {!isSeoul && isExport && <div className={'flex-1'} style={{marginTop: -5}}>{_r(FN.CONSIGNEE_ADDR)}</div>}
                {!isSeoul && !isExport && <div className={'flex-1'} style={{marginTop: -5}}>{_r(FN.SHIPPING_ADDR)}</div>}
                <div className={'flex-1'}>{''}</div>
                <div className={'flex-1'} style={!isSeoul && isExport ? {marginTop: 35} : {marginTop: 104}}>{_r(FN.CONSIGNEE_STAFF)}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE_EMAIL)}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE_PHONE1)}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE_PHONE2)}</div>
              </div>
              <div className={'flex-1'}>
                <div>{_tpa(FN.NOTIFY_LOCAL, 'notify')}</div>
                <div className={'flex-1'}>{_r(FN.NOTIFY)}</div>
                <div className={'flex-1'}>
                  <div>{_r(FN.NOTIFY_ADDR)}</div>
                </div>
                <div className={'flex-1'} style={{marginTop: -5}}>
                  <div>{_r(FN.NOTIFY_EMAIL)}</div>
                  <div>{_r(FN.NOTIFY_PHONE)}</div>
                </div>
                <div className={'flex-1'} style={{marginTop: 8}}>{_r(FN.SAME_AS_CONSIGNEE)}</div>
              </div>
            </div>
            <div className={'flex pl-8 pr-20 pb-1'} style={{marginTop: 10}}>
              <div className={'flex-1'}>{_r(FN.MEMO)}</div>
            </div>
          </>
        }
      </div>
    );
  };
  onValidate = (values) => {};
}

export default ForwardingHouseBLCustomerFormManager;
