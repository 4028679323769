/**
 * @flow
 */
import React from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import type {DataGridInfo} from '../redux/reducers/dataGridReducer';
import {updateDataGridInfo, useDataGridInfo} from '../redux/reducers/dataGridReducer';
import KeyboardArrowUpIcon from 'mdi-react/KeyboardArrowUpIcon';
import KeyboardArrowDownIcon from 'mdi-react/KeyboardArrowDownIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import FirstPageIcon from 'mdi-react/FirstPageIcon';
import LastPageIcon from 'mdi-react/LastPageIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import {isMobileOnly} from 'react-device-detect';
import {PrimaryButton} from './buttons';
import {util} from '../services/service';
import moment from 'moment';
import type {DataGridProps} from "./DataGrid";
import {defaultColDef, queryGridData} from "./DataGrid";

const rowCountOptions = [
  {value: 20, label: '20 rows'},
  {value: 40, label: '40 rows'},
  {value: 80, label: '80 rows'},
  {value: 120, label: '120 rows'},
];

interface ModalDataGridProps extends DataGridProps {
  height?: number;
}

const ModalDataGrid = (props: ModalDataGridProps) => {
  let gridInfo = useDataGridInfo(props.name) ?? props.gridInfo;
  gridInfo = gridInfo ?? props.gridInfo;
  util.setGridInfo(props.name, gridInfo);
  const [dateInfo, setDateInfo] = React.useState({});
  return (
    <div>
      {renderGridHeader(props, gridInfo, dateInfo, setDateInfo)}
      <div className={`ag-theme-balham`} style={{width: props.width, height: props.height, padding: props.padding}}>
        <AgGridReact
          columnDefs={props.columns}
          rowData={gridInfo?.rows ?? []}
          defaultColDef={{
            ...defaultColDef,
            headerComponentParams: {
              dataGridName: props.name,
              dataGridOnQuery: props.onQuery,
              dataSortableColumns: props.sortableColumns ?? {},
            }
          }}
          frameworkComponents={{agColumnHeader: HeaderCell}}
          onRowClicked={param => props.onRowClick?.(param.data, param.rowIndex, param)}
          onCellClicked={props.onCellClick}
          onCellValueChanged={props.onCellValueChange}
          enableCellTextSelection={true}
          onRowDoubleClicked={param => props.onRowDoubleClick?.(param.data, param.rowIndex, param)}
        />
      </div>
      {renderPagination(props, gridInfo)}
    </div>
  );
};

function renderIntervalDate(props, gridInfo, dateInfo, setDateInfo) {
  const onChange = (date, name) => {
    let start, end;
    start = name === 'start' ? date : dateInfo.start;
    end = name === 'end' ? date : dateInfo.end;
    if (start || end) {
      start = start ?? end;
      end = end ?? start;
      queryGridData(props.name, {...gridInfo, fromDate: start.getTime(), toDate: end.getTime(), page: 1}, props.onQuery).catch(console.error);
    } else {
      const newGridInfo = {...gridInfo};
      newGridInfo.fromDate = undefined;
      newGridInfo.toDate = undefined;
      newGridInfo.page = 1;
      queryGridData(props.name, newGridInfo, props.onQuery).catch(console.error);
    }
    setDateInfo({...dateInfo, [name]: date});
  };
  const width = props.hideSearchInput === true ? 140 : 100;
  return (
    <div className="date-picker date-picker--interval">
      <div style={{width, backgroundColor: '#fafbfe'}}>
        <DatePicker
          selected={dateInfo.start}
          selectsStart
          startDate={dateInfo.start}
          endDate={dateInfo.end}
          onChange={date => onChange(date, 'start')}
          dateFormat={'yyyy-MM-dd'}
          placeholderText={'From'}
          withPortal={isMobileOnly}
        />
      </div>
      <span className={'mx-2'}>~</span>
      <div style={{width, backgroundColor: '#fafbfe'}}>
        <DatePicker
          selected={dateInfo.end}
          selectsEnd
          minDate={dateInfo.start}
          startDate={dateInfo.start}
          endDate={dateInfo.end}
          onChange={date => onChange(date, 'end')}
          dateFormat={'yyyy-MM-dd'}
          placeholderText={'To'}
          withPortal={isMobileOnly}
        />
      </div>
    </div>
  );
}

function renderGridHeader(props: DataGridProps, gridInfo: DataGridInfo, dateInfo, setDateInfo) {
  const onChange = ({target: {value}}) => {
    if (value.length >= 2) {
      // 더이상 자동으로 쿼리를 보내지 않음
      //queryGridData(props.name, {...gridInfo, qryText: value, page: 1}, props.onQuery).catch(console.error);
      updateDataGridInfo(util.dispatch, props.name, {...gridInfo, qryText: value, page: 1});
    } else if (value.length === 0) {
      queryGridData(props.name, {...gridInfo, qryText: '', page: 1}, props.onQuery).catch(console.error);
    } else {
      updateDataGridInfo(util.dispatch, props.name, {...gridInfo, qryText: value, page: 1});
    }
  };
  const onKeyDown = e => {
    const {target: {value}, key} = e;
    if (key === 'Enter' && value) {
      queryGridData(props.name, {...gridInfo, qryText: value, page: 1}, props.onQuery).catch(console.error);
    }
  };
  const onSearchIconClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const el = document.querySelector(`#${props.name}-search`);
    const {value} = el ?? {};
    if (value) {
      queryGridData(props.name, {...gridInfo, qryText: value, page: 1}, props.onQuery).catch(console.error);
    }
  };
  return (
    <div style={{/*height: 50*/}} className={'flex between middle'}>
      {props.leftButton && (
        <div>
          <PrimaryButton label={props.leftButton.label} onClick={props.leftButton.onClick} noMargin />
        </div>
      )}
      {!props.leftButton && <div style={{width: 10}}/>}
      <form className={'form'} onSubmit={e => e.preventDefault()}>
        <div className={'flex middle'}>
          {props.showDateSearch === true && (
            <div className={'mr-2'}>
              {renderIntervalDate(props, gridInfo, dateInfo, setDateInfo)}
            </div>
          )}
          <div className={'theme-light ltr-support flex between middle px-20 mb-12'}>
          {props.hideSearchInput !== true && (
            <div className={'inbox__emails-control-search flex middle'}>
              <input
                id={`${props.name}-search`}
                placeholder={'Search...'}
                value={gridInfo.qryText}
                onChange={onChange}
                onKeyUp={onKeyDown}
                style={{width: 150}}
              />
              <a href={'/#search'} className="inbox__emails-control-search-icon" onClick={(e) => {
                e.preventDefault();
                onSearchIconClick(e);
              }}>
                <MagnifyIcon />
              </a>
            </div>
          )}
          </div>
          {props.showRowSearch === true && (
            <select style={{backgroundColor: '#fafbfe'}} className={'ml-2'} value={gridInfo.rowCount ?? 20} onChange={({target: {value}}) => {
              queryGridData(props.name, {...gridInfo, rowCount: parseInt(value)}, props.onQuery).catch(console.error);
            }}>
              {rowCountOptions.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
            </select>
          )}
        </div>
      </form>
    </div>
  );
}

function renderPagination(props: DataGridProps, gridInfo: DataGridInfo) {
  const paginationInfo = util.getPaginationInfo(gridInfo ?? props.gridInfo);
  //console.log('hello', paginationInfo);
  if (!paginationInfo) {
    return <div/>;
  }
  const {page, totalPages, totalCount, startIndex, endIndex} = paginationInfo;
  const onClick = (e, pageToJump) => {
    e.preventDefault();
    if (page !== pageToJump) {
      queryGridData(props.name, {...gridInfo, page: pageToJump}, props.onQuery).catch(console.error);
    }
  };
  return (
    <div className={'flex middle right mt-2'}>
      <span className={'mr-24'}>{`${startIndex + 1} to ${endIndex + 1} of ${totalCount}`}</span>
      <a href={'/#first'} onClick={e => onClick(e, 1)}><FirstPageIcon color={page === 1 ? '#bbbbbb' : '#666666'} /></a>
      <a href={'/#pref'} onClick={e => onClick(e, page === 1 ? 1 : page - 1)}><ChevronLeftIcon color={page === 1 ? '#bbbbbb' : '#666666'} /></a>
      <span className={'mx-12'}>{`Page ${page} of ${totalPages}`}</span>
      <a href={'/#next'} onClick={e => onClick(e, page === totalPages ? totalPages : page + 1)}><ChevronRightIcon color={page === totalPages ? '#bbbbbb' : '#666666'} /></a>
      <a href={'/#last'} onClick={e => onClick(e, totalPages)}><LastPageIcon color={page === totalPages ? '#bbbbbb' : '#666666'} /></a>
    </div>
  );
}

const HeaderCell = ({column, displayName, dataGridName, dataGridOnQuery, dataSortableColumns}) => {
  const gridInfo: DataGridInfo = useDataGridInfo(dataGridName) ?? {};
  let colId = column.colId;
  if (colId.endsWith('_1')) {
    colId = colId.substr(0, colId.length - 2);
  }
  const getDesc = () => {
    if (colId === gridInfo.orderBy) {
      if (gridInfo.isDesc === false) {
        return true;
      } else if (gridInfo.isDesc === true) {
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const onClick = async () => {
    if (displayName !== 'Actions') {
      // 헤더를 클릭하여 정렬을 변경한 경우 다시 목록을 쿼리함
      const newInfo = {...gridInfo, orderBy: colId, isDesc: getDesc()};
      queryGridData(dataGridName, newInfo, dataGridOnQuery).catch(console.error);
    }
  };
  const isSortable = dataSortableColumns[colId] !== false;
  if (!isSortable) {
    return displayName;
  }
  return (
    <div onClick={onClick} className={'flex middle'}>
      {displayName}
      {colId === gridInfo.orderBy && gridInfo.isDesc !== undefined && (
        gridInfo.isDesc ? <KeyboardArrowDownIcon size={18} className={'mx-1'} /> : <KeyboardArrowUpIcon size={18} className={'mx-1'} />
      )}
    </div>
  );
};

export default ModalDataGrid;
