/**
 * @flow
 */
import React from 'react';
import {Button, Modal} from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import {ColDef, RowDoubleClickedEvent} from 'ag-grid-community';
import {api, util} from '../services/service';
import {PrimaryButton} from './buttons';
import {useSimpleGrid} from './SimpleGrid';
import PortFormManager from '../forms/settings/PortFormManager';
import {useSimpleEditModal} from './SimpleModal';
import AccountTypeFormManager from '../forms/settings/AccountTypeFormManager';
import VehicleFormManager from '../forms/settings/VehicleFormManager';
import VesselFormManager from '../forms/settings/VesselFormManager';
import FlightFormManager from '../forms/craft/FlightFormManager';
import CraftVesselFormManager from '../forms/craft/VesselFormManager';

interface SearchModalProps {
  title: string;
  queryText: string;
  openModal: boolean;
  action: string;
  setOpenModal: (openModal: boolean) => void;
  onOK: (value: any) => void;
}

const commonColumns: ColDef[] = [
  {field: 'extraInfo', hide: true},
  {field: 'cdate', headerName: 'Date', width: 140, valueFormatter: util.dateTimeFormatter},
  {field: 'value', headerName: 'Value', flex: 1},
];

const extraColumns = {
  Fileno: [
    {field: 'craftCode', headerName: 'Code', width: 120},
    {field: 'fileNo', headerName: 'File No.', width: 120},
    {field: 'ETD', headerName: 'ETD', valueFormatter: util.dateFormatter, width: 100},
    {field: 'ETA', headerName: 'ETA', valueFormatter: util.dateFormatter, width: 100},
  ],
  AccountType: [
    {field: 'currency', headerName: 'Currency', width: 100},
    {field: 'price', headerName: 'Price', valueFormatter: util.currencyFormatter, width: 100},
    {field: 'reward', headerName: 'Reward Rate', width: 100},
  ],
  HBL: [
    {field: 'customer', headerName: 'Customer', width: 150},
    {field: 'entry_name', headerName: 'Entry Name', width: 100},
    {field: 'type', headerName: 'Type', width: 100},
  ],
  Customer: [
    {field: 'Address', headerName: 'Address', flex: 1},
  ],
  Staff: [
    {field: 'Branch', headerName: 'Branch', flex: 1},
  ],
  Partner: [
    {field: 'Address', headerName: 'Address', flex: 1},
  ],
  Dropzone: [
    {field: 'Address', headerName: 'Address', flex: 1},
  ],
  Port: [
    {field: 'FullName', headerName: 'Full Name', flex: 1},
    {field: 'Type', headerName: 'Type', flex: 1},
    {field: 'Country', headerName: 'County', flex: 1},
  ],
  Vehicle: [
    {field: 'Remark', headerName: 'Remark', flex: 1},
  ],
  TradePartner: [
    {field: 'Type', headerName: 'Type', flex: 1},
    {field: 'City', headerName: 'City', flex: 1},
    {field: 'Country', headerName: 'County', flex: 1},
  ],
  Vessel: [
    {field: 'CarrierId', hide: true},
    {field: 'Carrier', headerName: 'Carrier', flex: 1},
    {field: 'Country', headerName: 'County', flex: 1},
  ],
  CraftVslVoy: [
    {field: 'carrier_id', hide: true},
    {field: 'carrier_name', headerName: 'Carrier', flex: 1},
    {field: 'departure', headerName: 'POL', flex: 1},
    {field: 'destination', headerName: 'POD', flex: 1},
    {field: 'etd_date', headerName: 'ETD', valueFormatter: util.dateFormatter, flex: 1},
    {field: 'eta_date', headerName: 'ETA', valueFormatter: util.dateFormatter, flex: 1},
  ],
  CraftFlight: [
    {field: 'carrier_id', hide: true},
    {field: 'carrier_name', headerName: 'Carrier', flex: 1},
    {field: 'departure', headerName: 'Departure', flex: 1},
    {field: 'destination', headerName: 'Destination', flex: 1},
    {field: 'flightDate', headerName: 'Flight Date', valueFormatter: (param) => flightDTFormatter(param, 'etd'), width: 120},
    {field: 'arrivalDate', headerName: 'Arrival Date', valueFormatter: (param) => flightDTFormatter(param, 'eta'), width: 120},
  ],
  MBLFilingNo: [
    {field: 'MasterNo', headerName: 'Master No.', flex: 1},
    {field: 'Shipper', headerName: 'Shipper', flex: 1},
    {field: 'Consignee', headerName: 'Consignee', flex: 1},
    {field: 'Carrier', headerName: 'Carrier', flex: 1},
    {field: 'VslVoy', headerName: 'VSL/VOY', flex: 1},
    {field: 'POL', headerName: 'POL', flex: 1},
    {field: 'POD', headerName: 'POD', flex: 1},
  ],
  TradeHBL: [
    {field: 'filingNo', headerName: 'Filing No.', flex: 1},
  ],
};

function flightDTFormatter({data}, name) {
  return `${data[`${name}_date`]} ${data[`${name}_time`]}`;
}

function parseExtra(extra: string | Object) {
  if (typeof extra === 'string') {
    const extraInfo = JSON.parse(extra);
    return {...extraInfo, extraInfo};
  } else {
    return {...extra, extraInfo: extra};
  }
}

const mapRows = {
  Fileno: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  AccountType: ([id, cdate, value, extra])  => ({id, cdate, value, ...parseExtra(extra)}),
  HBL: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  Customer: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  Staff: ([id, cdate, value, extra]) => ({id, cdate, value, Branch: extra}),
  Partner: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  Port: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  Vehicle: ([id, cdate, value, extra]) => ({id, cdate, value, Remark: extra}),
  TradePartner: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  Vessel: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  CraftVslVoy: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  CraftFlight: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  MBLFilingNo: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
  TradeHBL: ([id, cdate, value, extra]) => ({id, cdate, value, ...parseExtra(extra)}),
}

const SearchModal = (props: SearchModalProps) => {
  const {
    openModal, setOpenModal,
    queryText, setQueryText, totalCount, /*columns,onOK,*/ canAdd, onEnter, grid,
    portModal, accountTypeModal, vehicleModal, vesselModal, flightModal, craftVesselModal, onAdd,
  } = useSearchModalInternal(props);
  return (
    <Modal isOpen={openModal} className={'modal-dialog--form'} centered style={{width: 720}}>
      <div className={'card__title no-margin pt-20 pl-20'}>
        <h5 className="bold-text">SEARCH {props.title}</h5>
      </div>
      <div className={'theme-light ltr-support flex between middle px-20 mb-12'}>
        <div className={'mt-8'}>{totalCount === undefined ? '' : `${grid.rows.length} of ${totalCount}`}</div>
        <div className={'inbox__emails-control-search flex middle'}>
          {canAdd && <PrimaryButton label={'NEW'} onClick={onAdd} noMargin />}
          {canAdd && <div className={'mr-8'} />}
          <input
            id={`common-search`}
            placeholder={'Search...'}
            value={queryText ?? ''}
            onChange={({target: {value}}) => setQueryText(value)}
            onKeyUp={({target: {value}, key}) => {
              if (key === 'Enter') {
                onEnter(value);
              }
            }}
            style={{width: 200}}
          />
          <a href={'/#search'} className="inbox__emails-control-search-icon" onClick={(e) => {
            e.preventDefault();
            onEnter(document.querySelector('#common-search').value);
          }}>
            <MagnifyIcon />
          </a>
        </div>
      </div>
      {grid.render()}
      <div className={'flex center w-full mb-24'}>
        <Button size={'sm'} onClick={() => setOpenModal(false)} className={'no-margin ml-1'}>Cancel</Button>
      </div>
      {props.action === 'Port' && portModal.render()}
      {props.action === 'AccountType' && accountTypeModal.render()}
      {props.action === 'Vehicle' && vehicleModal.render()}
      {props.action === 'Vessel' && vesselModal.render()}
      {props.action === 'CraftFlight' && flightModal.render()}
      {props.action === 'CraftVslVoy' && craftVesselModal.render()}
    </Modal>
  );
};

const editables = ['Port', 'Vehicle', 'Vessel', 'AccountType', 'TradePartner', 'CraftVslVoy', 'CraftFlight'];
const addables = ['Port', 'Vehicle', 'Vessel', 'AccountType', 'CraftVslVoy', 'CraftFlight', 'TradePartner'];
const deletables = ['Vehicle'];

const portForm = new PortFormManager();
const accountTypeForm = new AccountTypeFormManager();
const vehicleForm = new VehicleFormManager();
const vesselForm = new VesselFormManager();
const flightForm = new FlightFormManager();
const craftVesselForm = new CraftVesselFormManager();

function useSearchModalInternal(props: SearchModalProps) {
  // const propsAction = props.action;
  const {openModal, setOpenModal} = props;
  const [queryText, setQueryText] = React.useState(props.queryText);
  const [totalCount, setTotalCount] = React.useState();
  const [columns, setColumns] = React.useState([]);
  const portModal = usePortFormModal(async (mode, data) => {
    if (data) {
      const res = await (mode === 'add' ? api.addPort(data) : api.editPort(data));
      if (res) {
        portModal.close();
        search(props.action, queryText);
      }
    }
  });
  const accountTypeModal = useAccountTypeFormModal(async (mode, data) => {
    if (data) {
      const res = await (mode === 'add' ? api.accountTypeAdd({...data, isActive: true}) : api.accountTypeEdit(data));
      if (res) {
        accountTypeModal.close();
        search(props.action, queryText);
      }
    }
  });
  const vehicleModal = useVehicleFormModal(async (mode, data) => {
    if (data) {
      const res = await (mode === 'add' ? api.vehicleAdd({...data, isActive: true}) : api.vehicleEdit(data));
      if (res) {
        vehicleModal.close();
        search(props.action, queryText);
      }
    }
  });
  const vesselModal = useVesselFormModal(async (mode, data) => {
    if (data) {
      if (data) {
        const res = await (mode === 'add' ? api.addVessel(data) : api.editVessel(data));
        if (res) {
          vesselModal.close();
          search(props.action, queryText);
        }
      }
    }
  });
  const flightModal = useCraftFlightFormModal(async (mode, data) => {
    const {id, carrierId, craftNo, vslName, etdDate, etdTime, etaDate, etaTime, departure, destination, ...jextra} = data;
    const res = await api.craftSet({
      id,
      isVessel: false,
      carrierId,
      craftNo,
      vslName,
      etdDate,
      etdTime,
      etaDate,
      etaTime,
      departure,
      destination,
      jextra: {
        etdDate,
        etdTime,
        etaDate,
        etaTime,
        ...jextra,
      }
    });
    //console.log('hello craftSet', res);
    if (res) {
      flightModal.close();
    }
  });
  const craftVesselModal = useCraftVesselFormModal(async (mode, data) => {
    const {id, carrierId, craftNo, vslName, etdDate, etdTime, etaDate, etaTime, departure, destination, ...jextra} = data;
    const res = await api.craftSet({
      id,
      isVessel: true,
      carrierId,
      craftNo,
      vslName,
      etdDate,
      etdTime,
      etaDate,
      etaTime,
      departure,
      destination,
      jextra: {
        etdDate,
        etdTime,
        etaDate,
        etaTime,
        ...jextra,
      }
    });
    //console.log('hello craftSet', res);
    if (res) {
      craftVesselModal.close();
    }
  });
  const search = (action, qryText) => {
    setTotalCount(undefined);
    grid.setRows([]);
    api.search(action, qryText, 20).then(({data, totalCount}) => {
      if (data) {
        const {finds: rows = []} = data;
        const newRows = rows.map(mapRows[props.action]);
        setColumns([...commonColumns, ...extraColumns[props.action], {minWidth: 10, width: 10}]);
        grid.setRows(newRows);
        setTotalCount(totalCount);
        // if (process.env.NODE_ENV === 'production' && totalCount === 1 && props.action !== 'Vehicle') {
        //   onOK(newRows[0]);
        // }
      }
    });
  };
  React.useEffect(() => {
    if (openModal === true) {
      setTotalCount(undefined);
      grid.setRows([]);
      setQueryText(props.queryText);
      const el = document.querySelector('#common-search');
      el && el.focus();
      search(props.action, props.queryText);
    }
  }, [props.openModal]);
  React.useEffect(() => {
    if (flightModal.isOpen === false || craftVesselModal.isOpen === false) {
      search(props.action, props.queryText);
    }
  }, [flightModal.isOpen, craftVesselModal.isOpen]);
  const onEnter = (value) => {
    search(props.action, value);
  };
  const onOK = (data) => {
    props.setOpenModal(false);
    props.onOK(data);
  };
  const onDelete = (id) => {
    if (props.action === 'Vehicle') {
      api.vehicleDel({id}).then((res) => {
        if (res) {
          search(props.action, queryText);
        }
      });
    }
  };
  const onAdd = () => {
    if (props.action === 'Port') {
      portModal.open('add');
    } else if (props.action === 'AccountType') {
      accountTypeModal.open('add');
    } else if (props.action === 'Vehicle') {
      vehicleModal.open('add');
    } else if (props.action === 'Vessel') {
      vesselModal.open('add');
    } else if (props.action === 'CraftVslVoy') {
      craftVesselModal.open('add');
    } else if (props.action === 'CraftFlight') {
      flightModal.open('add');
    } else if (props.action === 'TradePartner') {
      util.openTab('/admin/partner/list?add=true');
    }
  }
  const canAdd = addables.includes(props.action);
  const grid = useSearchModalGrid(
    props.action, columns,
    portModal, vehicleModal, accountTypeModal, vesselModal, craftVesselModal, flightModal,
    onOK, onDelete,
  );
  return {
    openModal, setOpenModal,
    queryText, setQueryText, totalCount, columns, canAdd, onOK, onEnter, grid,
    portModal, accountTypeModal, vehicleModal, vesselModal, flightModal, craftVesselModal, onAdd,
  };
}

function useSearchModalGrid(
  searchAction, columns,
  portModal, vehicleModal, accountTypeModal, vesselModal, craftVesselModal, flightModal,
  onOK, onDelete
) {
  //const canEdit = searchAction === 'Port' || searchAction === 'AccountType' || searchAction === 'Vessel';
  const canEdit = editables.includes(searchAction);
  const canDelete = deletables.includes(searchAction);
  const actions = [];
  canEdit && actions.push('edit');
  canDelete && actions.push('delete');
  return useSimpleGrid({
    columns,
    height: 400,
    width: 'calc(100% - 40px)',
    actions,
    actionWidth: canEdit ? 70 : 0,
    onAction(action, data) {
      if (action === 'edit') {
        if (searchAction === 'Port') {
          const {id, value: portName, Type: portType, Country: country, FullName: fullName} = data; // 검색 데이터를 포트 데이터(서버이름)로 변환해 주어야 함!
          portModal.open('edit', {id, portName, portType, country, fullName});
        } else if (searchAction === 'Vehicle') {
          const {id, value: vName, Remark: remark} = data;
          vehicleModal.open('edit', {id, vName, remark});
        } else if (searchAction === 'AccountType') {
          const {id, value: accountType, currency, price, isexpense: isExpense, reward: reward_rate} = data;
          accountTypeModal.open('edit', {id, accountType, currency, price, isExpense, reward_rate});
        } else if (searchAction === 'Vessel') {
          const {id, value: vesselName, Carrier: carrier, CarrierId: carrierId, Country: country} = data;
          vesselModal.open('edit', {id, vesselName, carrier, carrierId, country});
        } else if (searchAction === 'TradePartner') {
          const {id, value} = data;
          util.openTab(`/admin/partner/list?edit=${id}&sk=${value}`);
        } else if (searchAction === 'CraftVslVoy') {
          const {jextra, ...editData} = data;
          //console.log('hello edit vsl/voy', editData, jextra);
          craftVesselModal.open('edit', {...data, ...jextra});
        } else if (searchAction === 'CraftFlight') {
          const {jextra, ...editData} = data;
          //console.log('hello edit flight', editData, jextra);
          flightModal.open('edit', {...data, ...jextra});
        }
      } else if (action === 'delete') {
        if (searchAction === 'Vehicle') {
          util.showConfirm('Are you sure to delete?', () => onDelete?.(data.id));
        }
      }
    },
    agGridProps: {
      onRowDoubleClicked({data}: RowDoubleClickedEvent) {onOK(data)}
    },
    className: 'mx-20 mb-12',
  });
}

function usePortFormModal(onSave) {
  return useSimpleEditModal({
    title: 'PORT',
    centered: true,
    width: 480,
    form: portForm,
    onSave,
  });
}

function useAccountTypeFormModal(onSave) {
  return useSimpleEditModal({
    title: 'ACCOUNT TYPE',
    centered: true,
    width: 480,
    form: accountTypeForm,
    onSave,
  });
}

function useVehicleFormModal(onSave) {
  return useSimpleEditModal({
    title: 'VEHICLE',
    centered: true,
    width: 480,
    form: vehicleForm,
    onSave,
  });
}

function useVesselFormModal(onSave) {
  return useSimpleEditModal({
    title: 'VESSEL',
    centered: true,
    width: 480,
    form: vesselForm,
    onSave,
  });
}

function useCraftFlightFormModal(onSave) {
  return useSimpleEditModal({
    title: 'FLIGHT',
    centered: true,
    width: 800,
    form: flightForm,
    onSave,
  });
}

function useCraftVesselFormModal(onSave) {
  return useSimpleEditModal({
    title: 'VESSEL',
    centered: true,
    width: 800,
    form: craftVesselForm,
    onSave,
  });
}

interface SearchOnOKParams {
  id: any;
  value: any;
}

type SearchOnOKCallback = (params: SearchOnOKParams) => void;

interface SearchState {
  open: boolean;
  value?: string;
  onOK?: SearchOnOKCallback;
}

export function useSearchModal(title: string, action: string, onOK: SearchOnOKCallback, queryText: string) {
  const [search, setSearch] = React.useState<SearchState>({open: false, onOK});
  return {
    show: (value = '') => {
      setSearch({...search, open: true, value});
    },
    hide: () => {
      setSearch({...search, open: false});
    },
    render: () => {
      return (
        <SearchModal
          openModal={search.open}
          setOpenModal={(open: boolean) => setSearch({...search, open})}
          title={title}
          action={action}
          queryText={queryText ?? search.value}
          onOK={search.onOK}
        />
      );
    },
  };
}

export function useStaffSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('STAFF', 'Staff', onOK);
}

export function useCustomerSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('CUSTOMER', 'Customer', onOK);
}

export function usePartnerSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('PARTNER', 'Partner', onOK);
}

export function useAirVesselSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('AIR/VESSEL', 'Fileno', onOK);
}

export function useAccountTypeSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('ACCOUNT TYPE', 'AccountType', onOK);
}

export function useHblSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('HBL', 'HBL', onOK);
}

export function usePortSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('PORT', 'Port', onOK);
}

export function useVehicleSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('VEHICLE', 'Vehicle', onOK);
}

export function useTradePartnerSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('TRADE PARTNER', 'TradePartner', onOK);
}

export function useVesselSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('VESSEL', 'Vessel', onOK);
}

export function useVslVoySearch(onOK: SearchOnOKCallback) {
  return useSearchModal('VSL/VOY', 'CraftVslVoy', onOK);
}

export function useFlightSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('FLIGHT', 'CraftFlight', onOK);
}

export function useMBLFilingNoSearch(onOK: SearchOnOKCallback) {
  return useSearchModal('FILING NO.', 'MBLFilingNo', onOK);
}

export function useTradeHBLSearch(onOK: SearchOnOKCallback, queryText: string) {
  return useSearchModal('TRADE HB/L', 'TradeHBL', onOK, queryText);
}

export default SearchModal;
