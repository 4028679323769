/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {api, util} from '../../../services/service';
import {FD, FN} from '../../field-defs/newCoship';
import {setBlStatus, setBound, setBranch, setCarrierType, setKind} from "../../../redux/reducers/newCoshipReducer";
import {useUser} from "../../../redux/reducers/userReducer";
import {TradePartnerSearchInput} from "../../../components/SearchInput";

class ForwardingHouseBLFormManager extends FormManager {
  constructor() {
    super({
      prefix: `forwarding-house-form`,
      fields: [
        FD.ID, FD.HBL_NO, FD.HAWB_NO, FD.BRANCH_ID, FD.TRADE_TYPE, FD.STAFF, FD.MBL_NO, FD.MAWB_NO, FD.PORT_TYPE,
        {...FD.KIND, defaultValue: 'F'}, FD.DEPARTURE, FD.DESTINATION, FD.PARTNER_ID, FD.PARTNER_NAME, FD.BL_STATUS,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const carrierType = _v(FN.PORT_TYPE);
    const kind = _v(FN.KIND);
    const bound = _v(FN.TRADE_TYPE);
    const user = useUser();
    const branchOptions = api.useAreaOptions(true);
    _f(FN.BRANCH_ID).options = branchOptions;
    _f(FN.PARTNER_NAME).label = 'Partner';
    _f(FN.KIND).onChange = (_, value) => onChangeKind(value);
    _f(FN.TRADE_TYPE).onChange = (_, value) => onChangeBound(value);
    _f(FN.BL_STATUS).onChange = (_, value) => onChangeBlStatus(value);

    const onChangeKind = (value) => {
      setKind(util.dispatch, value);
    };

    const onChangeBound = (value) => {
      _c(FN.TRADE_TYPE, value);
      setBound(util.dispatch, value);
    };

    const onChangeBlStatus = (value) => {
      _c(FN.BL_STATUS, value);
    };

    const onTPartner = (name, data) => {
      const {id, value} = data;
      _c(`${name}_id`, id);
      _c(`${name}_name`, value);
    };

    const _tpa = (name, partner, vertical = true) => {
      _f(name).smallMargin = name !== FN.PARTNER ? true : undefined;
      return (
        <TradePartnerSearchInput
          idField={_n(`${partner}_name`)} displayField={_n(name)}
          formik={formik} fields={fields} errors={errors} vertical={vertical} onOK={(data) => onTPartner(partner, data)}
        />
      );
    };

    React.useEffect(() => {
      if (kind) {
        setKind(util.dispatch, kind);
      } else {
        _c(FN.KIND, 'F');
        setKind(util.dispatch, 'F');
      }
    }, [kind]);

    React.useEffect(() => {
      if (!bound) {
        _c(FN.TRADE_TYPE, 'E');
        setBound(util.dispatch, 'E');
      }
    }, [bound]);

    React.useEffect(() => {
      if (carrierType) {
        setCarrierType(util.dispatch, carrierType);
      } else {
        _c(FN.CARRIER_TYPE, 'O');
        setCarrierType(util.dispatch, 'O');
      }
    }, [carrierType]);

    React.useEffect(() => {
      let branchId = _v(FN.BRANCH_ID);
      branchId = branchId ? util.toInt(branchId) : branchId;
      if (!branchId) {
        _c(FN.BRANCH_ID, user.branch_id);
      } else {
        _c(FN.BRANCH_ID, branchId);
      }
      const branch = branchOptions.find(x => x.value === branchId)?.label;
      setBranch(util.dispatch, branch);
    }, [_v(FN.BRANCH_ID)]);
    // }, [user.branch_id, _v(FN.BRANCH_ID), branchOptions]);

    React.useEffect(() => {
      if (_v(FN.BL_STATUS)) {
        setBlStatus(util.dispatch, _v(FN.BL_STATUS));
      } else {
        _c(FN.BL_STATUS, 'CREATED');
        setBlStatus(util.dispatch, 'CREATED');
      }
    }, [_v(FN.BL_STATUS)]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'flex w-full mt-2'}>
          {carrierType !== 'A' && <div className={'flex-1 mr-2'}>{_r(FN.HBL_NO)}</div>}
          {carrierType === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.HAWB_NO)}</div>}
          <div className={'flex-1 mr-2'}>{_r(FN.BRANCH_ID)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.TRADE_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.STAFF)}</div>
          <div className={'flex-1'}>{_r(FN.BL_STATUS)}</div>
        </div>
        <div className={'flex w-full'}>
          {carrierType !== 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>}
          {carrierType === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MAWB_NO)}</div>}
          <div className={'flex-1 mr-2'}>{_r(FN.PORT_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          <div className={'flex-1 mr-2'}>{_tpa(FN.PARTNER_NAME, 'partner')}</div>
          <div className={'flex-1'}>{''}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export const getBranchName = (branchId, form) => {
  let branchOptions;
  try {
    branchOptions = form.getField('financialOffice').options;
  } catch (error) {
    branchOptions = [];
  }
  return branchOptions.find(i => i.value === parseInt(branchId))?.label;
}

export default ForwardingHouseBLFormManager;
