/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import AEMPrint from '../../../print-layouts/AEMPrint';
import {usePrintModal} from './usePrintModal';
import {blPrintTitleOptions, getBLEntryData} from "../../field-defs/bl";
import {apiBL} from '../../../services/service';

const FN = {
  AWB_TYPE: 'awbType',
  AWB_NO: 'awbNo',
  SHIPPER: 'shipper',
  ISSUING_CARRIER: 'issuingCarrier',
  CONSIGNEE: 'consignee',
  DELIVERY_RECEIPT: 'deliveryReceipt',
  SCI: 'sci',
  DESTINATION_COUNTRY: 'destinationCountry',
  BY_FIRST_CARRIER: 'byFirstCarrier',
  SIGNATURE_OF_SHIPPER: 'signatureOfShipper',
  SIGNATURE_OF_ISSUING_CARRIER: 'signatureOfIssuingCarrier',
  SHIPPER_FOR: 'shipperFor',
  ISSUING_CARRIER_FOR: 'issuingCarrierFor',
  CONSIGNEE_FOR: 'consigneeFor',
  DELIVERY_RECEIPT_FOR: 'deliveryReceiptFor',
  SHIPPER_DESC: 'shipperDesc',
  ISSUING_CARRIER_DESC: 'issuingCarrierDesc',
  CONSIGNEE_DESC: 'consigneeDesc',
  DELIVERY_RECEIPT_DESC: 'deliveryReceiptDesc',
  SHIPPER_CHARGE: 'shipperCharge',
  ISSUING_CARRIER_CHARGE: 'issuingCarrierCharge',
  CONSIGNEE_CHARGE: 'consigneeCharge',
  DELIVERY_RECEIPT_CHARGE: 'deliveryReceiptCharge',

};
const SN = {
  AWB_TYPE: 'awbType',
  AWB_NO: 'awbNo',
  SCI: 'sci',
  DESTINATION_COUNTRY: 'destinationCountry',
  BY_FIRST_CARRIER: 'byFirstCarrier',
  SIGNATURE_OF_SHIPPER: 'signatureOfShipper',
  SIGNATURE_OF_ISSUING_CARRIER: 'signatureOfIssuingCarrier',
  SHIPPER_FOR: 'shipperFor',
  ISSUING_CARRIER_FOR: 'issuingCarrierFor',
  CONSIGNEE_FOR: 'consigneeFor',
  DELIVERY_RECEIPT_FOR: 'deliveryReceiptFor',
  SHIPPER_DESC: 'shipperDesc',
  ISSUING_CARRIER_DESC: 'issuingCarrierDesc',
  CONSIGNEE_DESC: 'consigneeDesc',
  DELIVERY_RECEIPT_DESC: 'deliveryReceiptDesc',
  SHIPPER_CHARGE: 'shipperCharge',
  ISSUING_CARRIER_CHARGE: 'issuingCarrierCharge',
  CONSIGNEE_CHARGE: 'consigneeCharge',
  DELIVERY_RECEIPT_CHARGE: 'deliveryReceiptCharge',
};

export class AEMBLPrintForm extends FormManager {
  static FN = FN;
  static SN = SN;
  constructor() {
    super({
      prefix: 'print-oem',
      fields: [
        {name: FN.AWB_TYPE, serverName: SN.AWB_TYPE, label: 'AWB Type', disabled: true},
        {name: FN.AWB_NO, serverName: SN.AWB_NO, label: 'AWB No.', disabled: true},
        {name: FN.SCI, serverName: SN.SCI, label: ''},
        {name: FN.DESTINATION_COUNTRY, serverName: SN.DESTINATION_COUNTRY, label: ''},
        {name: FN.BY_FIRST_CARRIER, serverName: SN.BY_FIRST_CARRIER, label: ''},
        {name: FN.SIGNATURE_OF_SHIPPER, serverName: SN.SIGNATURE_OF_SHIPPER, label: '', type: 'textarea'},
        {name: FN.SIGNATURE_OF_ISSUING_CARRIER, serverName: SN.SIGNATURE_OF_ISSUING_CARRIER, label: '', type: 'textarea'},
        {name: FN.SHIPPER_FOR, serverName: SN.SHIPPER_FOR, label: '', type: 'checkbox'},
        {name: FN.ISSUING_CARRIER_FOR, serverName: SN.ISSUING_CARRIER_FOR, label: '', type: 'checkbox'},
        {name: FN.CONSIGNEE_FOR, serverName: SN.CONSIGNEE_FOR, label: '', type: 'checkbox'},
        {name: FN.DELIVERY_RECEIPT_FOR, serverName: SN.DELIVERY_RECEIPT_FOR, label: '', type: 'checkbox'},
        {name: FN.SHIPPER_DESC, serverName: SN.SHIPPER_DESC, label: '', type: 'checkbox'},
        {name: FN.ISSUING_CARRIER_DESC, serverName: SN.ISSUING_CARRIER_DESC, label: '', type: 'checkbox'},
        {name: FN.CONSIGNEE_DESC, serverName: SN.CONSIGNEE_DESC, label: '', type: 'checkbox'},
        {name: FN.DELIVERY_RECEIPT_DESC, serverName: SN.DELIVERY_RECEIPT_DESC, label: '', type: 'checkbox'},
        {name: FN.SHIPPER_CHARGE, serverName: SN.SHIPPER_CHARGE, label: '', type: 'checkbox'},
        {name: FN.ISSUING_CARRIER_CHARGE, serverName: SN.ISSUING_CARRIER_CHARGE, label: '', type: 'checkbox'},
        {name: FN.CONSIGNEE_CHARGE, serverName: SN.CONSIGNEE_CHARGE, label: '', type: 'checkbox'},
        {name: FN.DELIVERY_RECEIPT_CHARGE, serverName: SN.DELIVERY_RECEIPT_CHARGE, label: '', type: 'checkbox'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    // const [formik, _, fields, errors] = this.renderParams;
    const type = _v(FN.AWB_TYPE);
    const sci = _v(FN.SCI);
    const shipper = _v(FN.SHIPPER_FOR);
    const issuingCarrier = _v(FN.ISSUING_CARRIER_FOR);
    const consignee = _v(FN.CONSIGNEE_FOR);
    const deliveryReceipt = _v(FN.DELIVERY_RECEIPT_FOR);
    React.useEffect(() => {
      if(!sci) {
        _c(FN.SCI, 'X');
      }
      if(!shipper && !issuingCarrier && !consignee && !deliveryReceipt) {
        _c(FN.CONSIGNEE_FOR, ['on']);
      }
    }, [shipper, issuingCarrier, consignee, deliveryReceipt]);
    return (
      <div className={'w-full'}>
        <div>{_r(FN.AWB_TYPE)}</div>
        <div>{_r(FN.AWB_NO)}</div>
        <div className={'flex'}>
          <div style={{width: 118, textAlign: 'right', marginRight: 20}}>For</div>
          <div className={'flex middle w-full'}>
            <div>{_r(FN.SHIPPER_FOR)}</div>
            <div className={'ml-1 mr-4'}>Shipper</div>
            <div>{_r(FN.ISSUING_CARRIER_FOR)}</div>
            <div className={'ml-1 mr-4'}>Issuing Carrier</div>
            <div>{_r(FN.CONSIGNEE_FOR)}</div>
            <div className={'ml-1 mr-4'}>Consignee</div>
            <div>{_r(FN.DELIVERY_RECEIPT_FOR)}</div>
            <div className={'ml-1 mr-4'}>Delivery Receipt</div>
          </div>
        </div>
        <div>SCI</div>
        <div>{_r(FN.SCI)}</div>
        <div>Destination Country</div>
        <div>{_r(FN.DESTINATION_COUNTRY)}</div>
        <div>By First Carrier</div>
        <div>{_r(FN.BY_FIRST_CARRIER)}</div>
        <div>Signature of Shipper</div>
        <div>{_r(FN.SIGNATURE_OF_SHIPPER)}</div>
        <div>Signature of Issuing Carrier</div>
        <div>{_r(FN.SIGNATURE_OF_ISSUING_CARRIER)}</div>
        <div className={'flex'}>
          <div style={{width: 118, textAlign: 'right', marginRight: 20}}>Display Description</div>
          <div className={'flex middle w-full'}>
            <div>{_r(FN.SHIPPER_DESC)}</div>
            <div className={'ml-1 mr-4'}>Shipper</div>
            <div>{_r(FN.ISSUING_CARRIER_DESC)}</div>
            <div className={'ml-1 mr-4'}>Issuing Carrier</div>
            <div>{_r(FN.CONSIGNEE_DESC)}</div>
            <div className={'ml-1 mr-4'}>Consignee</div>
            <div>{_r(FN.DELIVERY_RECEIPT_DESC)}</div>
            <div className={'ml-1 mr-4'}>Delivery Receipt</div>
          </div>
        </div>
        <div className={'flex'}>
          <div style={{width: 118, textAlign: 'right', marginRight: 20}}>Display Charge</div>
          <div className={'flex middle w-full'}>
            <div>{_r(FN.SHIPPER_CHARGE)}</div>
            <div className={'ml-1 mr-4'}>Shipper</div>
            <div>{_r(FN.ISSUING_CARRIER_CHARGE)}</div>
            <div className={'ml-1 mr-4'}>Issuing Carrier</div>
            <div>{_r(FN.CONSIGNEE_CHARGE)}</div>
            <div className={'ml-1 mr-4'}>Consignee</div>
            <div>{_r(FN.DELIVERY_RECEIPT_CHARGE)}</div>
            <div className={'ml-1 mr-4'}>Delivery Receipt</div>
          </div>
        </div>
      </div>
    );
  };
}

const blPrintForm = new AEMBLPrintForm();

export function useAEMBLPrintModal(isMaster, isNewCoship, blForm, customerForm, workDetailsForm) {
  const [data, setData] = React.useState();
  const houseBLFormRef = React.useRef();
  const customerFormRef = React.useRef();
  const workDetailsFormRef = React.useRef();

  const onOpen = () => {
    if (isNewCoship) {
      houseBLFormRef.current = blForm;
      customerFormRef.current = customerForm;
      workDetailsFormRef.current = workDetailsForm;
      setData({...houseBLFormRef.current.getValues(), ...customerFormRef.current.getValues(), ...workDetailsFormRef.current.getValues()});
    }
    const values = blForm.getValues();
    const blData = getBLEntryData(isMaster, values);
    blPrintForm.setValue(FN.AWB_TYPE, isMaster ? 'Master' : 'House');
    blPrintForm.setValue(FN.AWB_NO, blData.blNo);
  };
  const onLoadPrintData = async (blData, blFormData) => {
    const {data} = await apiBL.getBlRatePrintData(blData.blNo);
    return {blRate: data?.[0] ?? {}};
  };
  return usePrintModal(isMaster, 'B/L Print', 1024, blForm, blPrintForm, AEMPrint, onOpen, onLoadPrintData, 700, undefined, undefined, data);
}
