/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import AccountInvoiceDCFormManager, {getAccountEntryData, getAccountFormData} from '../../forms/account/AccountInvoiceDCFormManager';
import AccountTopSearchFormManager from '../../forms/account/AccountTopSearchFormManager';
import AccountInvoiceAddItemFormManager from '../../forms/account/AccountInvoiceAddItemFormManager';
import {DangerButton, DefaultButton} from '../../components/buttons';
import {apiAccount, util} from '../../services/service';
import {apiForwarding} from "../../services/newCoship/service";
import {useUser} from "../../redux/reducers/userReducer";
import {INVOICE} from "../../forms/field-defs/account";
import {useInvoiceDCPreviewModal} from "../../print-layouts/InvoiceDCPrint";
import {teamMain} from "../../forms/helper";

const type = INVOICE.TYPE_DC
const title = `Invoice ${INVOICE.TYPE_DC_LABEL}`;
// let id = new URL(window.location.href).searchParams.get('id');

const accountForm = new AccountInvoiceDCFormManager();
const accountSearchForm = new AccountTopSearchFormManager(type);
const accountInvoiceAddItem = new AccountInvoiceAddItemFormManager(type);

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const AccountInvoiceDC = () => {
  const [refNo, setRefNo] = React.useState();
  const [pId, setPId] = React.useState(0);
  const [mode, setMode] = React.useState('add');
  const [editTime, setEditTime] = React.useState();
  const qs = util.getQS();
  const {id, blNo} = qs;
  useTopbar({label: 'Account'}, {label: title});
  const invoiceDCPrintModal = useInvoiceDCPreviewModal();
  const user = useUser();
  accountSearchForm.mode = mode;
  document.title = `${title} ${mode}`;
  let isDisable = false;
  if (refNo && mode === 'edit') {
    isDisable = true;
  }

  const onSave = async () => {
    // if(!util.isValidPermitForAccount(user, mode)) {
    //   return util.showError('Sorry, you can not edit Account Info.');
    // }
    const values = accountForm.getValues(undefined, true);
    if (!values) {
      return;
    }
    const gridData = accountInvoiceAddItem.getGridData();
    const data = getAccountEntryData(values, gridData);
    const res = await apiAccount.accountSet(data);
    if (res) {
      await apiForwarding.setProfitReCal(data.blId);
      util.showSuccess('Account Entry has been saved successfully.');
      // 추가로 할일 있으면 여기서 하면 됩니다.
      setMode('edit');
      accountSearchForm.mode = 'edit';
      setPId(res.id);
      document.title = `${title} Edit`;
      if(id) {
        onSearch();
      } else {
        util.nav(`/admin/account/list/dc?id=${res.id}`);
      }
    }
  };

  const onPrint = (isPreview) => {
    const branchId = accountForm.getValue('financialOffice');
    const bankInfo = accountForm.getValue('bankInfo');
    apiAccount.branchList().then(res => {
      apiAccount.bankInfo(branchId, bankInfo).then(response => {
        invoiceDCPrintModal.open(accountForm, accountSearchForm, accountInvoiceAddItem, res.data.lists, response.data[0], isPreview);
      });
    });
  }

  accountSearchForm.onEnter = async () => {
    const res = await apiAccount.accountTradeBLData(accountSearchForm.getValues());
    const {data} = res;
    if (Array.isArray(data) && data.length === 1) {
      const {formData} = getAccountFormData(data[0]);
      formData.id = '';
      formData.invoiceNo = '';
      let branchId = formData.branchId;
      accountForm.setValuesFast({...formData, branchId: branchId ?? user.branch_id});
    } else {
      // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
    }
  };

  const onSearch = () => {
    apiAccount.accountTradeBLData({id: id}).then(({data}) => {
      if (Array.isArray(data) && data.length === 1) {
        const {formData, gridData} = getAccountFormData(data[0]);
        const [{bl_no, bl_id, inv_no, jcommon: {filingNo, branchId}}] = data;
        accountSearchForm.setValues({bl_no, bl_id, inv_no, filingNo});
        accountForm.setValuesFast({...formData, dcNo: inv_no, branchId: branchId ?? user.branch_id});
        let gridRowsData = gridData.map((row, index) => row = {...row, id: index});
        setTimeout(() => {
          accountInvoiceAddItem.setGridData(gridRowsData);
          accountInvoiceAddItem.setAccountId(id);
        }, 0);
        setRefNo(data[0].ref_no);
        setEditTime(formData.footer.edit[0]);
      } else {
        // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
      }
    });
  };

  const onSearchWithBlNo = () => {
    apiAccount.accountTradeBLData({blNo: blNo}).then((res) => {
      const {data} = res;
      if (Array.isArray(data) && data.length === 1) {
        const {formData} = getAccountFormData(data[0]);
        formData.id = '';
        formData.invoiceNo = '';
        let branchId = formData.branchId;
        console.log('hello formData', formData);
        accountForm.setValuesFast({...formData, branchId: branchId ?? user.branch_id});
      } else {
        // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
      }
    });
  };

  const onDelete = async () => {
    if(id) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = await apiAccount.deleteAccountData(parseInt(id));
        if(res) {
          const values = accountForm.getValues(undefined, true);
          if (values) {
            const gridData = accountInvoiceAddItem.getGridData();
            const data = getAccountEntryData(values, gridData);
            await apiForwarding.setProfitReCal(data.blId);
          }
          util.showSuccess('Account has been deleted successfully!');
          util.nav('/admin/account/list');
        }
      })
    }
  };

  const renderButtons = () => {
    if(id) {
      return (
        <>
        <DefaultButton label={'Save'} onClick={onSave} noMargin disabled={isDisable} /><div className={'w-8'} />
        {user.isManager && (<><DangerButton label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} /></>)}
        <DefaultButton label={'Preview'} onClick={() => onPrint(true)} noMargin /><div className={'w-8'} />
        <DefaultButton label={'Print'} onClick={() => onPrint(false)} noMargin /><div className={'w-8'} />
        </>
      );
    } else {
      return (
        <>
        <DefaultButton label={'Save'} onClick={onSave} noMargin disabled={isDisable} /><div className={'w-8'} />
        </>
      );
    }
  };

  React.useEffect(() => {
    if(id) {
      setMode('edit');
    }
  }, [id]);

  React.useEffect(() => {
    if (id) {
      onSearch();
    } else if (blNo) {
      onSearchWithBlNo();
    }
  }, [id, blNo]);

  accountInvoiceAddItem.accountForm = accountForm;

  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'} style={{position: 'relative'}}>
          <div className={'flex between w-full mb-12'}>
            <b className={'ml-12'}>{title}</b>
            <div className={'flex right'}>
              {renderButtons()}
            </div>
          </div>
          {accountSearchForm.renderForm()}
          {!!id && accountForm.formik && util.renderFooter(accountForm.getValue('footer'), 'house-footer')}
        </CardBody>
      </Card>
      {accountForm.renderForm()}
      {accountInvoiceAddItem.renderForm()}
      {invoiceDCPrintModal.render()}
      <div className={'flex center'} style={{marginTop: '-20px', paddingBottom: '20px'}}>
        <DefaultButton label={'Save'} onClick={onSave} noMargin disabled={isDisable} /><div className={'w-8'} />
        {id && (<><DefaultButton label={'Preview'} onClick={() => onPrint(true)} noMargin /><div className={'w-8'} /></>)}
        {id && (<DefaultButton label={'Print'} onClick={() => onPrint(false)} noMargin />)}
      </div>
    </>
  );
};

export default AccountInvoiceDC;
