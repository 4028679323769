/**
 * @flow
 */
import React from 'react';
import useAccountProfitGridView from '../../grids/account/useAccountProfitGridView';
import SearchPane from '../../components/SearchPane';
import {DefaultButton, PrimaryButton} from '../../components/buttons';
import AccountProfitSearchFormManager from '../../forms/account/AccountProfitSearchFormManager';
import {api, util} from '../../services/service';
// import useProfitDetailModal from '../../modals/account/useProfitDetailModal';
import {useTopbar} from "../../redux/reducers/topbarReducer";
import type {QueryListParams} from '../../services/API';

const searchForm = new AccountProfitSearchFormManager();

const AccountProfit = () => {
  useTopbar({label: 'Account'}, {label: 'Profit'});
  // const {grid, onSearch, detailModal} = useAccountProfit();
  const {
    grid,
    onSearch,
    onDownload,
    totalMargin,
  } = useAccountProfit();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        {grid.render()}
        <div style={{position: 'absolute', top: -12}}>
          <DefaultButton label={'Export Excel'} onClick={onDownload} />
        </div>
        <div className={'flex'} style={{position: 'absolute', bottom: 0, width: 350}}>
          <div className={'flex-1'}>Total Margin: {util.formatCurrency(totalMargin)}</div>
        </div>
      </div>
      {/*{detailModal.render()}*/}
    </div>
  );
};

function useAccountProfit() {
  const [totalMargin, setTotalMargin] = React.useState(0);
  // const grid = useAccountProfitGridView((action, data) => {
  //   if (action === 'detail') {
  //     detailModal.open(data['filing_no']);
  //   }
  // });
  const onQueryData = (tMargin) => {
    setTotalMargin(tMargin);
  };

  const apiRef = React.useRef();
  const grid = useAccountProfitGridView((params) => {apiRef.current = params.api}, onQueryData);
  // const detailModal = useProfitDetailModal();

  // const onAction = (action, data) => {
  //   if (action === 'edit') {
  //
  //   }
  // }

  const onDownload = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: `profit-list-${util.formatD(Date.now())}.csv`,
        columnKeys: grid.props.columns.filter((c, index) => index > 0).map(c => c.field),
      });
    }
  };

  const onSearch = () => {
    const searchValues = searchForm.getValues();
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      page: 1,
      ...searchValues
    };
    grid.query(listParam);
  };
  searchForm.onSearch = onSearch;
  return {
    grid,
    onSearch,
    onDownload,
    totalMargin,
    // detailModal
  };
}

export default AccountProfit;
