/**
 * @flow
 */
import type {FormField} from '../../components/Form';
import {util} from "../../services/service";
import {packageTypeOptions} from "./bl";
import {currencyOptions, incotermsOptions, accounttypeOptions, paidByOptions} from '../helper';

export const FD: {[key: string]: FormField} = {
  // master
  ID: {name: 'id', serverName: 'id', label: 'ID', disabled: true},
  FILING_NO: {name: 'filingNo', serverName: 'filingNo', label: 'Filing No.', disabled: true},
  MBL_NO: {name: 'mblNo', serverName: 'mblNo', label: 'MB/L No.', disabled: true},
  HBL_NO: {name: 'hblNo', serverName: 'hblNo', label: 'HB/L No.', disabled: true},
  CARRIER_BKG_NO: {name: 'bkgNo', serverName: 'bkgNo', label: 'Carrier Bkg. No.', disabled: true},
  AGENT_REF_NO: {name: 'agentRefNo', serverName: 'agentRefNo', label: 'Agent Ref. No.'},
  SHIPPER: {name: 'shipper', serverName: 'shipper', label: 'Shipper', smallMargin: true},
  CONSIGNEE: {name: 'consignee', serverName: 'consignee', label: 'Consignee', smallMargin: true},
  NOTIFY: {name: 'notify', serverName: 'notify', label: 'Notify', smallMargin: true, disabled: true},
  VESSEL_FIGHT_NO: {name: 'vfNo', serverName: 'vfNo', label: 'Vessel/F. No.', smallMargin: true, disabled: true},
  POR: {name: 'porLabel', serverName: 'porLabel', label: 'POR', disabled: true},
  POL: {name: 'polLabel', serverName: 'polLabel', label: 'POL/ETD'},
  POL_ETD: {name: 'polEtd', serverName: 'polEtd', label: '', type: 'date', mask: util.MASK_DATE, placeholder: 'ETD'},
  POD: {name: 'podLabel', serverName: 'podLabel', label: 'POD/ETA'},
  POD_ETA: {name: 'podEta', serverName: 'podEta', label: '', type: 'date', mask: util.MASK_DATE, placeholder: 'ETA'},
  DEL: {name: 'delLabel', serverName: 'delLabel', label: 'DEL', disabled: true},
  FINAL_DESTINATION: {name: 'finalDestLabel', serverName: 'finalDestLabel', label: 'F.DEST/ETA', disabled: true},
  FINAL_DEST_ETA: {name: 'fDestEta', serverName: 'fDestEta', label: '', type: 'date', mask: util.MASK_DATE, placeholder: 'ETA', disabled: true},
  COMMODITY: {name: 'commodity', serverName: 'commodity', label: 'Commodity'},
  PACKAGE: {name: 'package', serverName: 'package', label: 'Package'},
  PACKAGE_TYPE: {name: 'packageType', serverName: 'packageType', label: '', options: packageTypeOptions, noDefOption: true, disabled: true},
  GROSS_WEIGHT_KG: {name: 'grossWeightKg', serverName: 'grossWeightKg', label: 'G.WGT'},
  GROSS_WEIGHT_LB: {name: 'grossWeightLb', serverName: 'grossWeightLb', label: '', disabled: true},
  CHARGEABLE_WEIGHT_KG: {name: 'chargeableWeightKg', serverName: 'chargeableWeightKg', label: 'Weight(C)'},
  CHARGEABLE_WEIGHT_LB: {name: 'chargeableWeightLb', serverName: 'chargeableWeightLb', label: '', disabled: true},
  VOLUME_WEIGHT_KG: {name: 'volumeWeightCbm', serverName: 'volumeWeightCbm', label: 'Volume', disabled: true},
  VOLUME_WEIGHT_LB: {name: 'volumeWeightCft', serverName: 'volumeWeightCft', label: '', disabled: true},
  CBM: {name: 'cbm', serverName: 'cbm', label: 'CBM', disabled: true},
  OFFER_CBM: {name: 'offerCbm', serverName: 'offerCbm', label: 'Volume'},
  PORT_TYPE: {name: 'portType', serverName: 'portType', label: '', disabled: true},
  KIND: {name: 'kind', serverName: 'kind', label: '', disabled: true},

  //billing
  BILL_TO_ID: {name: 'billToId', serverName: 'billToId', label: ''},
  BILL_TO: {name: 'billTo', serverName: 'billTo', label: 'Bill To', required: true},
  BILL_TO_ADDR: {name: 'billToAddr', serverName: 'billToAddr', label: ''},
  BILL_ADDR: {name: 'bill_address', serverName: 'bill_address', label: ''},
  SHIP_TO: {name: 'shipTo', serverName: 'shipTo', label: 'Ship To'},
  ATTENTION_TO: {name: 'attentionTo', serverName: 'attentionTo', label: 'Attention To'},
  CUSTOMER_REF_NO: {name: 'customerRefNo', serverName: 'customerRefNo', label: 'C. Ref. No.'},
  MEMO: {name: 'memo', serverName: 'memo', label: 'Memo'},
  INVOICE_NO: {name: 'invoiceNo', serverName: 'inv_no', label: 'Invoice No.', disabled: true},
  POSTING_DATE: {name: 'postingDate', serverName: 'postingDate', label: 'Posting Date', type: 'date', mask: util.MASK_DATE, disabled: true},
  INVOICE_DATE: {name: 'invoiceDate', serverName: 'invoiceDate', label: 'Invoice Date', type: 'date', mask: util.MASK_DATE, required: true},
  TERMS: {name: 'terms', serverName: 'terms', label: 'Terms'},
  DUE_DATE: {name: 'dueDate', serverName: 'dueDate', label: 'Due Date', type: 'date', mask: util.MASK_DATE},
  CURRENCY: {name: 'currency', serverName: 'currency', label: 'Currency', options: currencyOptions, noDefOption: true},
  INCOTERMS: {name: 'incoterms', serverName: 'incoterms', label: 'Incoterms', options: incotermsOptions, noDefOption: true},
  TAX_INVOICE_NO: {name: 'taxInvoiceNo', serverName: 'taxInvoiceNo', label: 'Tax Invoice No'},
  FINANCIAL_OFFICE: {name: 'financialOffice', serverName: 'financialOffice', label: 'F. Office', options: [], noDefOption: true, required: true},
  BANK_INFO: {name: 'bankInfo', serverName: 'bankInfo', label: 'Bank Info', options: [], noDefOption: true},

  VENDOR_ID:          {name: 'vendorId', serverName: 'vendorId', label: ''},
  VENDOR:             {name: 'vendor', serverName: 'vendor', label: 'Vendor', required: true},
  VENDOR_INVOICE_NO:  {name: 'vendorInv', serverName: 'vendorInv', label: 'Vendor Inv. No.'},

  AGENT_ID: {name: 'agentId', serverName: 'agentId', label: ''},
  AGENT:    {name: 'agent', serverName: 'agent', label: 'Agent Name'},
  DC_NO:    {name: 'dcNo', serverName: 'dcNo', label: 'D/C No.', disabled: true},
  BL_CURRENCY: {name: 'blCurrency', serverName: 'blCurrency', label: 'B/L Currency', options: currencyOptions, noDefOption: true},
  PROFIT_SHARE: {name: 'profitShare', serverName: 'profitShare', label: 'Profit Share(%)', disabled: true},
  BUYING_RATE: {name: 'buyingRate', serverName: 'buyingRate', label: ''},
  SELLING_RATE: {name: 'sellingRate', serverName: 'sellingRate', label: ''},

  PARTNER_ID:          {name: 'partnerId', serverName: 'partnerId', label: ''},
  PARTNER:             {name: 'partner', serverName: 'partner', label: 'Partner', required: true},
  PARTNER_ADDR:        {name: 'partnerAddr', serverName: 'partnerAddr', label: ''},

  SETTLEMENT_DATE: {name: 'settlementDate', serverName: 'settlementDate', label: 'Set. Date', type: 'date', mask: util.MASK_DATE, required: true},
  SETTLEMENT_INVOICE_NO:  {name: 'settlementInvoiceNo', serverName: 'settlementInvoiceNo', label: 'Set. No.', disabled: true},

  CAD: {name: 'CAD', serverName: 'CAD', label: 'CAD'},
  USD: {name: 'USD', serverName: 'USD', label: 'USD'},
  KRW: {name: 'KRW', serverName: 'KRW', label: 'KRW'},

  BL_ID: {name: 'blId', serverName: 'blId', label: ''},
  PAID_DATE: {name: 'paidDate', serverName: 'paidDate', label: 'Paid Date', type: 'date', mask: util.MASK_DATE},
  PAID_BY: {name: 'paidBy', serverName: 'paidBy', label: 'Paid By', options: paidByOptions, noDefOption: true},
  BALANCE: {name: 'balance', serverName: 'balance', label: 'Balance'},
  CONTAINER_STR: {name: 'containerStr', serverName: 'containerStr', label: 'Container No.', disabled: true},
  FOOTER: {name: 'footer', serverName: 'footer'},
  DC_RATE: {name: 'dcRate2', serverName: 'dcRate'},
  PERIOD: {name: 'period', serverName: 'period'},
};

// console.log(Object.keys(FD).length);
// console.log('export const FN = {' + Object.keys(FD).map(i => `${i}: ''`).join(', ') + '};');
// console.log(Object.entries(FD).map(([key, {name}]) => `'${name}'`).join(', '));
// console.log(Object.entries(FD).map(([key, {serverName}]) => `'${serverName}'`).join(', '));
// export const FN = {ID: '', FILING_NO: '', MBL_NO: '', HBL_NO: '', CARRIER_BKG_NO: '', AGENT_REF_NO: '', SHIPPER: '', CONSIGNEE: '', NOTIFY: '', VESSEL_FIGHT_NO: '', POR: '', POL: '', POL_ETD: '', POD: '', POD_ETA: '', DEL: '', FINAL_DESTINATION: '', FINAL_DEST_ETA: '', COMMODITY: '', PACKAGE: '', PACKAGE_TYPE: '', GROSS_WEIGHT_KG: '', GROSS_WEIGHT_LB: '', CHARGEABLE_WEIGHT_KG: '', CHARGEABLE_WEIGHT_LB: '', VOLUME_WEIGHT_KG: '', VOLUME_WEIGHT_LB: '', BILL_TO_ID: '', BILL_TO: '', SHIP_TO: '', ATTENTION_TO: '', CUSTOMER_REF_NO: '', MEMO: '', INVOICE_NO: '', POSTING_DATE: '', INVOICE_DATE: '', TERMS: '', DUE_DATE: '', CURRENCY: '', INCOTERMS: '', TAX_INVOICE_NO: '', FINANCIAL_OFFICE: '', BANK_INFO: '', VENDOR_ID: '', VENDOR: '', VENDOR_INVOICE_NO: ''};
//export const FN = {ID: '', FILING_NO: '', MBL_NO: '', HBL_NO: '', CARRIER_BKG_NO: '', AGENT_REF_NO: '', SHIPPER: '', CONSIGNEE: '', NOTIFY: '', VESSEL_FIGHT_NO: '', POR: '', POL: '', POL_ETD: '', POD: '', POD_ETA: '', DEL: '', FINAL_DESTINATION: '', FINAL_DEST_ETA: '', COMMODITY: '', PACKAGE: '', PACKAGE_TYPE: '', GROSS_WEIGHT_KG: '', GROSS_WEIGHT_LB: '', CHARGEABLE_WEIGHT_KG: '', CHARGEABLE_WEIGHT_LB: '', VOLUME_WEIGHT_KG: '', VOLUME_WEIGHT_LB: '', BILL_TO_ID: '', BILL_TO: '', SHIP_TO: '', ATTENTION_TO: '', CUSTOMER_REF_NO: '', MEMO: '', INVOICE_NO: '', POSTING_DATE: '', INVOICE_DATE: '', TERMS: '', DUE_DATE: '', CURRENCY: '', INCOTERMS: '', TAX_INVOICE_NO: '', FINANCIAL_OFFICE: '', BANK_INFO: '', ACCOUNT_TYPE: ''};
export const FN = {ID: '', FILING_NO: '', MBL_NO: '', HBL_NO: '', CARRIER_BKG_NO: '', AGENT_REF_NO: '', SHIPPER: '', CONSIGNEE: '', NOTIFY: '', VESSEL_FIGHT_NO: '', POR: '', POL: '', POL_ETD: '', POD: '', POD_ETA: '', DEL: '', FINAL_DESTINATION: '', FINAL_DEST_ETA: '', COMMODITY: '', PACKAGE: '', PACKAGE_TYPE: '', GROSS_WEIGHT_KG: '', GROSS_WEIGHT_LB: '', CHARGEABLE_WEIGHT_KG: '', CHARGEABLE_WEIGHT_LB: '', VOLUME_WEIGHT_KG: '', VOLUME_WEIGHT_LB: '', BILL_TO_ID: '', BILL_TO: '', BILL_TO_ADDR: '', BILL_ADDR: '', SHIP_TO: '', ATTENTION_TO: '', CUSTOMER_REF_NO: '', MEMO: '', INVOICE_NO: '', POSTING_DATE: '', INVOICE_DATE: '', TERMS: '', DUE_DATE: '', CURRENCY: '', INCOTERMS: '', TAX_INVOICE_NO: '', FINANCIAL_OFFICE: '', BANK_INFO: '', VENDOR_ID: '', VENDOR: '', VENDOR_INVOICE_NO: '', AGENT_ID: '', AGENT: '', DC_NO: '', BL_CURRENCY: '', PROFIT_SHARE: '', PARTNER_ID: '', PARTNER: '', PARTNER_ADDR: '', SETTLEMENT_DATE: '', SETTLEMENT_INVOICE_NO: '', CAD: '', USD: '', KRW: '', BUYING_RATE: '', SELLING_RATE: '', BL_ID: '', PAID_DATE: '', PAID_BY: '', BALANCE: '', CONTAINER_STR: '', FOOTER: '', DC_RATE: '', PERIOD: '', CBM: '', OFFER_CBM: '', PORT_TYPE: '', KIND: ''};

export const SN = {...FN};

export type INVOICE_TYPE = 'AR' | 'AP' | 'DC' | 'CM' | 'VD' | 'DC_NOTE';

export const INVOICE = {
  TYPE_AR : 'AR',
  TYPE_AP : 'AP',
  TYPE_DC : 'DC',
  TYPE_CM : 'CM',
  TYPE_VD : 'VD',
  TYPE_DC_NOTE : 'DC_NOTE',
  TYPE_DC_LABEL : 'D/C',
  TYPE_AR_LABEL : 'A/R',
  TYPE_AP_LABEL : 'A/P',
  TYPE_CM_LABEL : 'C/M',
  TYPE_VD_LABEL : 'V/D',
  TYPE_DCNOTE_LABEL : 'D/C NOTE',
};


for (const key in FD) {
  if (FD.hasOwnProperty(key)) {
    FN[key] = FD[key].name;
    SN[key] = SN[key].serverName;
  }
};
