/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import coshipLogo from '../media/coship_logo.png';

const MAX_ADDR_LENGTH = 40;

const CourierOceanReceiptPrint = React.forwardRef((props, ref) => {
  const {printData} = props;
  if (printData?.length === 0) {
    return null;
  }

  const address = printData.consignee_address;
  let address1 = address, address2 = '';
  if (address?.length > MAX_ADDR_LENGTH) {
    address1 = address.substring(0, MAX_ADDR_LENGTH);
    address2 = address.substring(MAX_ADDR_LENGTH, address.length);
  }

  const [consigneeName, setConsigneeName] = React.useState(printData.consignee_name);
  const [deliveryDate, setDeliveryDate] = React.useState(printData.delivery_date);
  const [consigneePhone, setConsigneePhone] = React.useState(printData.consignee_phone);
  const [packages, setPackages] = React.useState(printData.packages);
  const [balance, setBalance] = React.useState(printData.balance);
  const [remark, setRemark] = React.useState(printData.remark);
  const [addressFirstLine, setAddressFirstLine] = React.useState(address1);
  const [addressSecondLine, setAddressSecondLine] = React.useState(address2);

  const renderCustomer = () => {
    return (
      <div>
        <div className={'flex mb-1'} style={{fontWeight: 'bold'}}>
          <div className={'flex-6'} style={{marginLeft: 100, fontSize: 45}}>{'Receipt'}</div>
          <div className={'flex-1'}><img src={coshipLogo} /></div>
        </div>
        {renderBody()}
        <div style={{marginTop: 50, fontSize: 25}}>
          <div className={'flex'}>
            <div className={'flex-1'}>밴쿠버 본사 604-455-1000</div>
            <div className={'flex-1'}>캘거리 지사 403-979-8000</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-1'}>토론토 지사 647-380-1000</div>
            <div className={'flex-1'}>한국 지사 02-2043-5000</div>
          </div>
        </div>
        <div style={{marginTop: 30, fontSize: 25, height: 100}}>

        </div>
      </div>
    );
  };

  const renderCoship = () => {
    return (
      <div style={{marginTop: 30}}>
        <div style={{display: 'flex', justifyContent: 'flex-end', fontSize: 30}}>{'(COSHIP 용)'}</div>
        <div className={'flex mb-1'} style={{fontWeight: 'bold'}}>
          <div className={'flex-6 center'} style={{marginLeft: 100, fontSize: 45}}>{'Receipt'}</div>
          <div className={'flex-1'}>{''}</div>
        </div>
        {renderBody()}
      </div>
    );
  };

  const renderBody = () => {
    const onChange = (e, name) => {
      const value = e.target.value;
      if (name === 'consigneeName') {
        setConsigneeName(value);
      } else if (name === 'deliveryDate') {
        setDeliveryDate(value);
      } else if (name === 'addressFirstLine') {
        setAddressFirstLine(value);
      } else if (name === 'addressSecondLine') {
        setAddressSecondLine(value);
      } else if (name === 'consigneePhone') {
        setConsigneePhone(value);
      } else if (name === 'packages') {
        setPackages(value);
      } else if (name === 'balance') {
        setBalance(value);
      } else if (name === 'remark') {
        setRemark(value);
      }
    };
    return (
      <div style={{marginTop: 50, fontSize: 30, fontWeight: 'bold'}}>
        <div className={'flex'}>
          <div className={'flex flex-1'}>
            <div className={'flex-2'} style={{textAlign: 'right'}}>Name:</div>
            <div className={'flex-5'}><input type={'text'} className={'underline-only'} value={consigneeName} onChange={(e) => onChange(e, 'consigneeName')}/></div>
          </div>
          <div className={'flex flex-1'}>
            <div className={'flex-2'} style={{textAlign: 'right'}}>Date:</div>
            <div className={'flex-5'}><input type={'text'} className={'underline-only'} value={deliveryDate} onChange={(e) => onChange(e, 'deliveryDate')}/></div>
          </div>
        </div>
        <div className={'flex flex-1'} style={{marginTop: 30}}>
          <div className={'flex-1'} style={{textAlign: 'right'}}>Address:</div>
          <div className={'flex-6'}><input type={'text'} className={'underline-only'} style={{width: '96%'}} value={addressFirstLine} onChange={(e) => onChange(e, 'addressFirstLine')}/></div>
        </div>
        <div className={'flex flex-1'} style={{marginTop: 30}}>
          <div className={'flex-1'}>{''}</div>
          <div className={'flex-6'}><input type={'text'} className={'underline-only'} style={{width: '96%'}} value={addressSecondLine} onChange={(e) => onChange(e, 'addressSecondLine')}/></div>
        </div>
        <div className={'flex'} style={{marginTop: 30}}>
          <div className={'flex flex-1'}>
            <div className={'flex-2'} style={{textAlign: 'right'}}>Phone:</div>
            <div className={'flex-5'}><input type={'text'} className={'underline-only'} value={consigneePhone} onChange={(e) => onChange(e, 'consigneePhone')}/></div>
          </div>
          <div className={'flex flex-1'}/>
        </div>
        <div className={'flex'} style={{marginTop: 30}}>
          <div className={'flex flex-1'}>
            <div className={'flex-2'} style={{textAlign: 'right'}}>Pkgs:</div>
            <div className={'flex-5'}><input type={'text'} className={'underline-only'} value={packages} onChange={(e) => onChange(e, 'packages')}/></div>
          </div>
          <div className={'flex flex-1'}>
            <div className={'flex-2'} style={{textAlign: 'right'}}>Balance:</div>
            <div className={'flex-5'}><input type={'text'} className={'underline-only'} value={balance} onChange={(e) => onChange(e, 'balance')}/></div>
          </div>
        </div>
        <div className={'flex'} style={{marginTop: 30}}>
          <div className={'flex flex-1'}>
            <div className={'flex-2'} style={{textAlign: 'right'}}>Remark:</div>
            <div className={'flex-5'}><input type={'text'} className={'underline-only'} value={remark} onChange={(e) => onChange(e, 'remark')}/></div>
          </div>
          <div className={'flex flex-1'}>
            <div className={'flex-2'} style={{textAlign: 'right'}}>Signature:</div>
            <div className={'flex-5'}><input type={'text'} className={'underline-only'} readOnly/></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div ref={ref} style={{color: 'black', textAlign: 'center', padding: 60}}>
      {renderCustomer(printData)}
      <hr style={{borderTop: 'dotted'}}/>
      {renderCoship(printData)}
    </div>
  );
});

export function useCourierOceanReceiptPreviewModal() {
  const [printData, setPrintData] = React.useState();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body-no-margin',
  });
  const modal = useSimpleModal({
    title: 'Ocean Receipt',
    width: 1100,
    buttons: [
      {label: 'Print', color: 'primary', onClick: () => print()},
    ],
    children: <CourierOceanReceiptPrint ref={printRef} printData={printData}/>,
  });
  return {
    ...modal,
    open: (data) => {
      setPrintData(data);
      modal.open();
    },
    print,
  };
}
