/**
 * @flow
 */
import {useDataGridView} from '../../../components/DataGridView';
import {util} from '../../../services/service';
import {apiMoving} from "../../../services/newCoship/service";
import React from "react";

export default function useMovingHouseBLListGridView(onActionCb) {
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      return apiMoving.getHBlList(gridInfo);
    },
    name: 'movingHouseBLList',
    label: 'moving',
    addLabel: '',
    editLabel: 'EDIT HOUSE BL',
    categoryLabel: {label: 'Moving'},
    menuLabel: {label: 'BL List'},
    sortCol: '',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit', 'copy'],
    actionWidth: 80,
    showDateSearch: false,
    showRowSearch: true,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    doNotPerformInitialQuery: true,
    columns: [
      {field: 'branch_name', headerName: 'Branch', flex: 1},
      {field: 'shipper', headerName: 'Shipper', flex: 1},
      {field: 'consignee', headerName: 'Consignee', flex: 1},
      {field: 'bl_no', headerName: 'BL#', cellRendererFramework: blLinkFormatter, flex: 1},
      {field: 'carrier_type', headerName: 'Carrier Type', flex: 1},
      {field: 'cdate', headerName: 'C.Date', valueFormatter: util.dateTimeFormatter, flex: 1},
      {field: 'departure', headerName: 'Departure', flex: 1},
      {field: 'destination', headerName: 'Destination', flex: 1},
    ],
  });
};

export function blLinkFormatter(param) {
  const onClick = (e) => {
    e.preventDefault();
    util.openTab(`/admin/moving/house/customer?id=${param.data.id}`);
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={onClick}>
      {param.value}
    </a>
  );
}
