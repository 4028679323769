/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {usePrintModal} from './usePrintModal';
import OEHPrint from '../../../print-layouts/OEHPrint';
import {apiBL} from '../../../services/service';
import {blPrintTitleOptions} from '../../field-defs/bl';

const FN = {
  BL_NO: 'blNo',
  BL_PRINT_OPT: 'blPrintOpt',
  RELEASE_TYPE: 'releaseType',
  SHOW_VESSEL_POL: 'showVesselPol',
  SHOW_ONBOARD_DATE: 'showOnboardDate',
  SHOW_PKGS: 'showPkgs',
  TITLE: 'title',
};

class OEHBLPrintForm extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'print-oeh',
      fields: [
        {name: FN.BL_NO, serverName: FN.BL_NO, label: 'B/L No.', readonly: true},
        {name: FN.BL_PRINT_OPT, serverName: FN.BL_PRINT_OPT, label: 'B/L Print Opt.'},
        {name: FN.RELEASE_TYPE, serverName: FN.RELEASE_TYPE, label: 'Release Type', options: releaseTypeOptions, noDefOption: true},
        {name: FN.SHOW_VESSEL_POL, serverName: FN.SHOW_VESSEL_POL, label: '', type: 'checkbox'},
        {name: FN.SHOW_ONBOARD_DATE, serverName: FN.SHOW_ONBOARD_DATE, label: '', type: 'checkbox'},
        {name: FN.SHOW_PKGS, serverName: FN.SHOW_PKGS, label: '', type: 'checkbox'},
        {name: FN.TITLE, serverName: FN.TITLE, label: 'Title', options: blPrintTitleOptions, noDefOption: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    // const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    // const [formik, _, fields, errors] = this.renderParams;
    const {renderField: _r} = this;
    return (
      <div className={'w-full mr-12 mb-24'}>
        {_r(FN.BL_NO)}
        {_r(FN.BL_PRINT_OPT)}
        {_r(FN.RELEASE_TYPE)}
        <div className={'flex'}>
          <div className={'ml-120'}>{_r(FN.SHOW_VESSEL_POL)}</div>
          <div>Show Vessel & POL</div>
        </div>
        <div className={'flex'}>
          <div className={'ml-120'}>{_r(FN.SHOW_ONBOARD_DATE)}</div>
          <div>Show Onboard Date</div>
        </div>
        <div className={'flex mb-12'}>
          <div className={'ml-120'}>{_r(FN.SHOW_PKGS)}</div>
          <div>Show PKGS/KGS/CBM for Container</div>
        </div>
        {_r(FN.TITLE)}
      </div>
    );
  };
}

const releaseTypeOptions = [
  {value: 'NO', label: 'None'},
  {value: 'OR', label: 'Original'},
  // {value: 'NN', label: 'Non-Negotiable'},
  {value: 'DR', label: 'Draft'},
  // {value: 'CP', label: 'Copy'},
  {value: 'TR', label: 'Telex release'},
  {value: 'SW', label: 'Seawaybill'},
];

const blPrintForm = new OEHBLPrintForm();

export function useOEHBLPrintModal(isNewCoship, blForm, customerForm, workDetailsForm) {
  const [data, setData] = React.useState();
  const houseBLFormRef = React.useRef();
  const customerFormRef = React.useRef();
  const workDetailsFormRef = React.useRef();
  const onOpen = () => {
    if (isNewCoship) {
      houseBLFormRef.current = blForm;
      customerFormRef.current = customerForm;
      workDetailsFormRef.current = workDetailsForm;
      setData({...houseBLFormRef.current.getValues(), ...customerFormRef.current.getValues(), ...workDetailsFormRef.current.getValues()});
    }
    const blNo = blForm.getValue('hblNo');
    const carrier = blForm.getValue('carrier');
    blPrintForm.setValue(FN.BL_NO, blNo);
    blPrintForm.setValue(FN.BL_PRINT_OPT, 'AS AGENT FOR THE CARRIER ' + (carrier ?? ''));
    blPrintForm.setValue(FN.SHOW_VESSEL_POL, true);
    blPrintForm.setValue(FN.SHOW_PKGS, true);
    blPrintForm.setValue(FN.TITLE, blPrintTitleOptions[0].value);
  };
  const onLoadPrintData = async (blData) => {
    const {data} = await apiBL.getBLPrintData(blData.blNo);
    return {serverData: data?.[0] ?? {}};
  };
  return usePrintModal(false,'B/L PRINT', 1024, blForm, blPrintForm, OEHPrint, onOpen, onLoadPrintData, undefined, undefined, undefined, data);
}
