/**
 * @flow
 */
import {useDataGridView} from '../components/DataGridView';
import {util} from '../services/service';
import {apiForwarding} from '../services/newCoship/service';

export default function useCurrencyListGridView(onActionCb) {

  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery() {
      return apiForwarding.getCurrencyList();
    },
    name: 'currencyList',
    label: 'currency',
    addLabel: '',
    editLabel: 'TODAY RATE',
    categoryLabel: {label: 'Setting'},
    menuLabel: {label: 'Currency Rate'},
    sortCol: 'rate_date',
    sortDesc: true,
    pageRows: 120,
    // actions: ['edit'],
    // actionWidth: 70,
    showDateSearch: false,
    // hideSearchInput: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    columns: [
      { name: 'usd_val', required: true, field: 'usd_val', Label: 'USD', hide: true},
      { name: 'krw_val', required: true, field: 'krw_val', Label: 'KRW', hide: true},
      { name: 'rate_date', required: true, field: 'rate_date', headerName: 'C.Rate Date', valueFormatter: util.dateFormatter, width: 120, sortable: false},
      { name: 'usd_str', required: false, field: 'usd_str', headerName: 'USD', width: 200, sortable: false},
      { name: 'krw_str', required: false, field: 'krw_str', headerName: 'KRW', width: 220, sortable: false},
      { name: 'updated', required: false, field: 'updated', headerName: 'Updated', valueFormatter: util.dateTimeFormatter, width: 150, sortable: false},
      { name: 'user_name', required: false, field: 'user_name', headerName: 'Staff', width: 100, sortable: false},
      { name: 'remark', required: false, field: 'remark', headerName: 'Remark', flex: 1, sortable: false},
    ],
  });
}
