/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {api, util} from '../../services/service';
import {useUser} from '../../redux/reducers/userReducer';
import {useParams} from 'react-router-dom';

const SK_DATE = 'flightDate';
const SK_FILING_NO = 'filingNo';
const SK_MASTER_NO = 'blNo';
const SK_BRANCH_ID = 'branchId';
const SK_PRINT = 'print';
const SK_PARTNER = 'partner';
const SK_SHIPPER = 'shipper';
const SK_CONSIGNEE = 'consignee';
const SK_LITHIUM = 'lithium';

const printOptions = [
  {value: 'A', label: 'ALL'},
  {value: 'Y', label: 'PRINTED'},
  {value: 'N', label: 'UNPRINTED'},
];

class BLSearchFormManager extends FormManager {
  onSearch;
  constructor(isNewCoship) {
    super({
      prefix: 'bl-manifest-search',
      fields: [
        {name: SK_BRANCH_ID, serverName: SK_BRANCH_ID, label: 'Branch', options: [], noDefOption: true, smallLabel: true, smallMargin: true},
        {name: SK_FILING_NO, serverName: SK_FILING_NO, label: 'Filing No.', smallLabel: true, smallMargin: true},
        {name: SK_MASTER_NO, serverName: SK_MASTER_NO, label: 'BL No.', smallLabel: true, smallMargin: true},
        {name: SK_DATE, serverName: SK_DATE, label: 'Flight Date', smallLabel: true, smallMargin: true, type: 'date'},
        {name: SK_PRINT, serverName: SK_PRINT, label: 'Print', smallLabel: true, smallMargin: true, options: printOptions, noDefOption: true, defaultValue: 'N'},
        {name: SK_PARTNER, serverName: SK_PARTNER, label: 'Partner', smallLabel: true, smallMargin: true},
        {name: SK_SHIPPER, serverName: SK_SHIPPER, label: 'Shipper', smallLabel: true, smallMargin: true},
        {name: SK_CONSIGNEE, serverName: SK_CONSIGNEE, label: 'Consignee', smallLabel: true, smallMargin: false},
        {name: SK_LITHIUM, serverName: SK_LITHIUM, label: 'Lithium', type: 'checkbox', smallLabel: true, smallMargin: false},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
    this.isNewCoship = isNewCoship;
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const filingNoField = _f(SK_FILING_NO);
    const masterNoField = _f(SK_MASTER_NO);
    const branchField = _f(SK_BRANCH_ID);
    const user = useUser();
    const params = useParams();
    branchField.options = api.useAreaOptions(true);
    const date = _v(SK_DATE);
    const branchId = _v(SK_BRANCH_ID);
    const printOption = _v(SK_PRINT);
    React.useEffect(() => {
      if (branchId && date && user.id && branchField.options?.length > 0) {
        this.onSearch();
      }
    }, [branchId, date, user.id, params.ts, branchField.options?.length, printOption]);
    React.useEffect(() => {
      if (!date) {
        _c(SK_DATE, util.formatD(new Date()));
      }
    }, [date]);
    React.useEffect(() => {
      if (user.id) {
        _c(SK_BRANCH_ID, user.branch_id);
        _c(SK_PRINT, 'N');
      }
    }, [user.id]);
    React.useEffect(() => {
      this.setFocus(SK_FILING_NO);
    }, []);
    filingNoField.onEnter = () => {this.onSearch()};
    masterNoField.onEnter = () => {this.onSearch()};
    return (
      <>
        {_r(SK_BRANCH_ID)}
        {_r(SK_FILING_NO)}
        {_r(SK_MASTER_NO)}
        {_r(SK_DATE)}
        {_r(SK_PRINT)}
        {_r(SK_PARTNER)}
        {_r(SK_SHIPPER)}
        {_r(SK_CONSIGNEE)}
        {this.isNewCoship && _r(SK_LITHIUM)}
      </>
    );
  };
  onValidate = (values) => {
    // TODO: 밸리데이션이 필요한 경우 여기서...
    return values;
  };
}

export default BLSearchFormManager;
