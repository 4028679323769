/**
 * @flow
 */
import React from 'react';
import WorkOrderAssignFormManager from '../forms/WorkOrderAssignFormManager';
import {CARGO_COURIER} from '../modal-tabs/types';
import type {QueryListParams} from '../services/API';
import {api, apiBL, util} from '../services/service';
import {useSimpleGridModal} from '../components/SimpleModal';
import {isMobileOnly} from "react-device-detect";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {ColDef, GridApi} from 'ag-grid-community';
import {woTypeOptions} from '../forms/work-order/WorkOrderFormManager';
import {kindOptions} from '../forms/field-defs/bl';
import {DefaultButton} from '../components/buttons';
import {useUser} from "../redux/reducers/userReducer";
import {useWorkAssignedPrintModal} from "../print-layouts/WorkAssignedPrint";

const workAssignForm = new WorkOrderAssignFormManager(CARGO_COURIER);

export function useAssignVehicleModal(cargoType, queryInfo: QueryListParams) {
  const user = useUser();
  const [editData, setEditData] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState();
  const [vehicleId, setVehicleId] = React.useState();
  const [workTime, setWorkTime] = React.useState();
  const [city, setCity] = React.useState();
  const [branchId, setBranchId] = React.useState();
  const [assignedData, setAssignedData] = React.useState(); // 날짜/차량/시간 에 이미 데이터가 있는경우 설정됨 (CheckWorkAssign 함수 참조)
  const rowsRef = React.useRef([]);
  const workAssignedModal = useWorkAssignedPrintModal();
  const addModal = useAddItemsModal((rows) => {
    addModal.close();
    const newRows = [...(modal.grid.rows ?? []), ...rows].map((i, index) => ({...i, id: index}));
    modal.setRows(newRows);
    rowsRef.current = newRows;

  });

  const modalRef = React.useRef();
  function onCitySearch() {
    loadWorkAssignList(selectedDate, branchId).catch();
  }
  const areaOptions = api.useAreaOptions(true);
  function onBranchChange(e) {
    loadWorkAssignList(selectedDate, e.target.value).catch();
  }
  function onSave() {
    const values = workAssignForm.getValues(undefined, true);
    if (!values) return;
    let rows;
    if (editData) {
      rows = [];
      modal.grid.apiRef.current.forEachNode(node => rows.push(node.data));
    } else {
      rows = modal.grid.apiRef.current.getSelectedRows();
    }
    if (rows.length <= 0) {
      util.showWarning('Please select row(s) to save.');
      return;
    }
    apiBL.setWorkAssign({...values, assignList: rows.map((i, index) => ({...i, id: index})), workDate: selectedDate.getTime()}).then(res => {
      if (res) {
        util.showSuccess('Work assign has been saved successfully!');
        if (!editData) {
          checkWorkAssign().catch();
        }
      }
    });
  }
  function onDeleteAll() {
    util.showConfirm('Are you sure to delete?', async () => {
      const res = await api.cargoWorkAssignDel(assignedData);
      if (res) {
        await checkWorkAssign();
      }
    });
  }
  function onGoogleMap() {
    let selectedRows;
    if (editData) {
      selectedRows = [];
      modal.grid.apiRef.current.forEachNode(node => selectedRows.push(node.data));
    } else {
      selectedRows = modal.grid.apiRef.current.getSelectedRows();
    }
    if (selectedRows.length <= 0) {
      util.showWarning('Please select one or more rows.');
      return;
    }
    const addresses = selectedRows.map(row => encodeURI(row['address'] ? row['address'] : row['city']));
    const sAddr = `saddr=${addresses[0]}`;
    let dAddr = '';
    if (addresses.length > 1) {
      dAddr = `daddr=${addresses[1]}`;
      if (addresses.length > 2) {
        for (let i = 2; i < addresses.length; ++i) {
          dAddr += `+to:${addresses[i]}`;
        }
      }
    }
    const url = `http://maps.google.com/maps?${sAddr}&${dAddr}`;
    window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
  }
  function onDownload() {
    if (modal.grid.rows?.length > 0) {
      const api: GridApi = modal.grid.apiRef.current;
      const workDate = modal.grid.rows[0].work_date;
      api.exportDataAsCsv({
        fileName: `work-assigned-${workDate}.csv`,
        columnKeys: getColumns().filter((c, index) => ![0, 8, 9].includes(index)).map(c => c.field),
      });
    }
  }
  function onPrint() {
    workAssignedModal.open(modal.grid.rows, util.formatD(selectedDate.getTime()));
  }
  function onDateChange(date: Date) {
    setSelectedDate(date);
    loadWorkAssignList(date, branchId).catch();
  }
  async function loadWorkAssignList(date: Date, branch_id) {
    // const res = await api.cargoWorkAssignList({...queryInfo, fromDate: date.getTime(), cargoType: CARGO_COURIER, rowCount: 0}); // NOTE: 여기서는 로우 카운트를 0으로 설정하여 모든 로우를 쿼리하게 함!!
    // const data = (res.data ?? []).map(row => ({...row, work_date: util.formatD(row['work_date'])}));
    // modal.setRows(data);
    // rowsRef.current = data;
    const fromDate = date.getTime();
    const id = branch_id ?? user.branch_id;
    setBranchId(id);
    const {data = []} = await apiBL.workForAssignList({fromDate, city, branchId: util.toInt(id)});
    const newData = data.map((item, index) => {
      item = {...item, id: index};
      return item;
    });
    modal.setRows(newData);
    rowsRef.current = newData;
  }
  async function checkWorkAssign() {
    // const res = await api.cargoCheckWorkAssign(CARGO_COURIER, selectedDate.getTime(), parseInt(vehicleId), workTime);
    // if (res?.data?.length > 0) {
    //   // 배차 데이터가 존재함
    //   const data = res.data[0];
    //   workAssignForm.setValues(data);
    //   modal.setRows(data['assign_list']);
    //   rowsRef.current = data['assign_list'];
    //   setAssignedData(data);
    // } else {
      // 배차 데이터가 존재하지 않음
      workAssignForm.setValue('id', '');
      workAssignForm.setValue('staffId', '');
      workAssignForm.setValue('staff', '');
      workAssignForm.setValue('remark', '')
      setAssignedData(undefined);
      await loadWorkAssignList(selectedDate);
    // }
  }
  const hasEditData = !!editData;
  const titleDate = hasEditData ? ` (${util.formatD(editData['work_date'])})` : '';
  function getButtons() {
    if (hasEditData) {
      return [
        {label: 'Save', color: 'primary', onClick: onSave},
        {label: 'Google Map', onClick: onGoogleMap},
        {label: 'Export CSV', onClick: onDownload},
        {label: 'Print', onClick: onPrint},
        // {label: 'Close', onClick: () => modal.close()},
      ];
    } else {
      if (assignedData) {
        return [
          {label: 'Save', color: 'primary', onClick: onSave},
          {label: 'Delete All', color: 'danger', onClick: onDeleteAll},
          {label: 'Google Map', onClick: onGoogleMap},
        ];
      } else {
        return [
          {label: 'Save', color: 'primary', onClick: onSave},
          {label: 'Google Map', onClick: onGoogleMap},
        ];
      }
    }
  }
  function onAddMoreItems() {
    addModal.open(editData['work_date']);
  }
  let options = '';
  const modal = useSimpleGridModal({
    title: `ASSIGN VEHICLE${titleDate}`,
    centered: true,
    width: 1280,
    buttons: getButtons(),
    gridProps: {
      columns: getColumns(hasEditData),
      actions: ['delete'], //['delete'],
      actionWidth: 50,
      isActionDisabled: (type, data) => {
        return type === 'delete' && modalRef.current.grid.rows?.length <= 1;
      },
      onAction: (action, data) => {
        if (action === 'delete') {
          const grid = modalRef.current.grid
          const curRows = grid.rows ?? [];
          if (curRows.length > 1) {
            util.showConfirm('Are you sure to delete?', () => {
              const filteredRows = curRows.filter(i => i.id !== data.id);
              grid.setRows(filteredRows);
              rowsRef.current = filteredRows;
            });
          }
        }
      },
      rows: [],
      height: 450,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        rowDragManaged: true,
        animateRows: true,
        onRowClicked(e) {},
        onRowDragEnd(e) {
          const rows = rowsRef.current;
          const id = e.node.data['id'];
          const newIndex = e.node.rowIndex;
          const sourceIndex = rows.findIndex(i => i.id === id);
          const sourceRow = rows[sourceIndex];
          rows.splice(sourceIndex, 1);
          rows.splice(newIndex, 0, sourceRow);
          rowsRef.current = [...rows];
          modal.grid.apiRef.current.setRowData(rowsRef.current);
        },
        onCellValueChanged(e) {
          const {work_ids, work_time} = e.data;
          if(editData.id) {
            api.setAssignTime(editData.id, work_ids, work_time).catch();
          }
        },
      }
    },
    children: (
      <>
        {workAssignForm.renderForm()}
        {!hasEditData && (
          <div style={{position: 'absolute', left: 150, top: 12, right: 300}}>
            <div className={'form date-picker flex middle'}>
              <div className={'flex-1'}>
                <select
                  id={'branchId'}
                  name={'branchId'}
                  onChange={(e) => onBranchChange(e)}
                  className={'not-required-color'}
                >
                  {areaOptions.map(o => {
                    if(o.value === user.branch_id) {
                      options = <option selected value={o.value} key={o.value}>{o.label}</option>;
                    } else {
                      options = <option value={o.value} key={o.value}>{o.label}</option>;
                    }
                    return (
                      options
                    );
                  })}
                </select>
              </div>
              <div className={'flex-1 ml-20'}>
                <DatePicker
                  minDate={new Date()}
                  selected={selectedDate}
                  onChange={onDateChange}
                  dateFormat={'yyyy-MM-dd'}
                  placeholderText={'Date'}
                  withPortal={isMobileOnly}
                />
              </div>
              <div className={'flex flex-1 ml-20'}>
                {/*<span className={'mx-20 flex-1'}>City</span>*/}
                <input
                  id={'city-filter'}
                  // className={'flex-1'}
                  placeholder={'City'}
                  value={city}
                  onChange={({target: {value}}) => setCity(value)}
                  onKeyUp={({key}) => {if (key === 'Enter') onCitySearch()}}
                />
              </div>
            </div>
          </div>
        )}
        {hasEditData && (
          <div style={{position: 'absolute', left: 250, top: 12, right: 300}}>
            <DefaultButton label={'Add...'} onClick={() => onAddMoreItems()} />
          </div>
        )}
        {addModal.render()}
        {workAssignedModal.render()}
      </>
    ),
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      if (editData) {
        const {assign_list} = editData;
        const assignList = [...assign_list];
        assignList.map((item) => {
          item.work_date = util.formatD(item.work_date);
        });
        modal.setRows(assignList);
        rowsRef.current = assignList;
        setSelectedDate(new Date(editData['work_date']));
        workAssignForm.setValues(editData);
      } else {
        const today = moment().startOf('day').toDate();
        setSelectedDate(today);
        loadWorkAssignList(today).catch();
      }
      const cityFilterElement = document.querySelector(`#city-filter`);
      if (cityFilterElement) {
        cityFilterElement.focus();
      }
    }
  }, [modal.isOpen]);
  // React.useEffect(() => {
  //   if (vehicleId && workTime && selectedDate) {
  //     checkWorkAssign().catch()
  //   }
  // }, [vehicleId, workTime, selectedDate]);
  const open = (initialData: Object) => {
    setEditData(initialData);
    modal.open();
  };
  workAssignForm.onVehicleChange = vId => setVehicleId(vId);
  workAssignForm.onWorkTimeChange = wt => setWorkTime(wt);
  workAssignForm.hasEditData = hasEditData;
  modalRef.current = modal;
  return {...modal, open};
}

function workTypeFormatter({value}) {
  return woTypeOptions.find(i => i.value === value)?.label ?? '';
}

function kindFormatter({value}) {
  return kindOptions.find(i => i.value === value)?.label ?? '';
}

function getColumns(hasEditData: boolean) {
  const columns: ColDef[] = [
    {field: 'kind', headerName: 'S.Type', valueFormatter: kindFormatter, width: 100},
    {field: 'work_type', headerName: 'W.Type', valueFormatter: workTypeFormatter, width: 100},
    {field: 'carrier_type', headerName: 'Carrier', width: 100},
    {field: 'customer_name', headerName: 'Customer', width: 100},
    {field: 'address', headerName: 'Address', rowDrag: true, flex: 1},
    {field: 'city', headerName: 'City', width: 100},
    {field: 'pack_count', headerName: 'Packages', rowDrag: true, width: 80, minWidth: 80},
    {field: 'work_time', headerName: 'Time', valueFormatter: util.uppercaseFormatter, width: 80, minWidth: 80, editable: true},
    {field: 'dropzone', headerName: 'D.Zone', width: 100},
    {field: 'phone', headerName: 'Phone', width: 100},
    {field: 'remark', headerName: 'Remark', flex: 1},
    {field: 'cbm_tape', headerName: 'CBM/Tape', width: 100},
    {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, width: 100},
    {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 100},
    {field: 'id', hide: true},
    // {field: 'work_date', headerName: 'Date', width: 100, valueFormatter: util.dateFormatter},
  ];
  const checkColumn = {headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 50, minWidth: 50};
  if (hasEditData) {
    return columns;
  } else {
    return [checkColumn, ...columns];
  }
}

function useAddItemsModal(onSelect) {
  const [selectedDate, setSelectedDate] = React.useState();
  const [city, setCity] = React.useState();
  function onCitySearch() {
    loadWorkAssignList().catch();
  }
  async function loadWorkAssignList() {
    const dateFromTs = selectedDate instanceof Date ? selectedDate.getTime() : selectedDate;
    const {data = []} = await apiBL.workForAssignList({fromDate: dateFromTs, city});
    const newData = data.map((item, index) => {
      item = {...item, id: index};
      return item;
    });
    modal.setRows(newData);
  }
  function handleSelect() {
    const rows = modal.grid.apiRef.current.getSelectedRows();
    onSelect(rows);
  }
  const modal = useSimpleGridModal({
    title: 'SELECT',
    centered: true,
    width: 1280,
    buttons: [
      {label: 'Select', color: 'primary', onClick: () => handleSelect()},
      {label: 'Cancel', onClick: () => modal.close()},
    ],
    gridProps: {
      columns: [
        {headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 50, minWidth: 50},
        {field: 'pack_count', headerName: 'Packages', rowDrag: true, width: 80, minWidth: 80},
        // {field: 'work_date', headerName: 'Date', width: 100, valueFormatter: util.dateFormatter},
        {field: 'work_time', headerName: 'Time', width: 80, minWidth: 80},
        {field: 'kind', headerName: 'S.Type', valueFormatter: kindFormatter, width: 100},
        {field: 'carrier_type', headerName: 'Carrier', width: 100},
        {field: 'dropzone', headerName: 'D.Zone', width: 100},
        {field: 'work_type', headerName: 'W.Type', valueFormatter: workTypeFormatter, width: 100},
        {field: 'cbm_tape', headerName: 'CBM/Tape', width: 100},
        {field: 'customer_name', headerName: 'Customer', width: 100},
        {field: 'phone', headerName: 'Phone', width: 100},
        {field: 'city', headerName: 'City', width: 100},
        {field: 'address', headerName: 'Address', rowDrag: true, flex: 1},
        {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, width: 100},
        {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 100},
        {field: 'remark', headerName: 'Remark', flex: 1},
        {field: 'id', hide: true},
      ],
      rows: [],
      height: 450,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
      }
    },
    children: (
      <>
        <div style={{position: 'absolute', left: 150, top: 12, right: 300}}>
          <div className={'form date-picker flex middle'}>
            <span className={'mx-20'}>City</span>
            <input
              id={'city-filter'}
              className={'flex-1'}
              value={city}
              onChange={({target: {value}}) => setCity(value)}
              onKeyUp={({key}) => {if (key === 'Enter') onCitySearch()}}
            />
          </div>
        </div>
      </>
    ),
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      loadWorkAssignList().catch();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (date, callback) => {
      setSelectedDate(date);
      modal.open();
    },
  };
}
