/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {useSelector} from "react-redux";
import {FD, FN} from "../../field-defs/newCoship";
import {setCBM, setPKG} from "../../../redux/reducers/newCoshipReducer";
import {util} from "../../../services/service";

class MovingHouseBLWorkDetailsFormManager extends FormManager {
  isShowForm;
  constructor() {
    super({
      prefix: `moving-house-work_details-form`,
      fields: [
        FD.REMARK, FD.REMARK_D, FD.CBM, FD.OFFER_CBM, FD.VSL_VOY, FD.POL, FD.TAPE, FD.INSURANCE, FD.CONTAINER_SEQ, FD.ETD, FD.PACKAGE,
        FD.WAREHOUSE_ARRIVAL_DATE, FD.CLEARED_BY_DATE, FD.POD, FD.ETA, FD.FLIGHT_NO, FD.GROSS_WEIGHT_KG, FD.CHARGEABLE_WEIGHT_KG,
        FD.DEPARTURE, FD.DEPARTURE_FLIGHT, FD.DESTINATION, FD.DESTINATION_FLIGHT, FD.FLIGHT_DATE, FD.FLIGHT_TIME, FD.ARRIVAL_DATE, FD.ARRIVAL_TIME,
        FD.VIN_NO, FD.CAR_INFO, FD.COMMODITY, FD.SVC_TERM1, FD.SVC_TERM2,
        {name: 'containerNo', serverName: 'containerNo', label: 'CNTR NO.', smallMargin: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, setValue: _c, getValue: _v, _n} = this;
    const {carrierType, kind} = useSelector(state => state['newCoship']);
    _f(FN.VSL_VOY).disabled = true;
    _f(FN.CONTAINER_SEQ).disabled = true;
    _f('containerNo').disabled = true;
    _f(FN.POL).disabled = true;
    _f(FN.POD).disabled = true;
    _f(FN.ETD).disabled = true;
    _f(FN.ETA).disabled = true;
    _f(FN.FLIGHT_NO).disabled = true;
    _f(FN.DEPARTURE).disabled = true;
    _f(FN.DEPARTURE_FLIGHT).disabled = true;
    _f(FN.FLIGHT_DATE).disabled = true;
    _f(FN.FLIGHT_TIME).disabled = true;
    _f(FN.DESTINATION).disabled = true;
    _f(FN.DESTINATION_FLIGHT).disabled = true;
    _f(FN.ARRIVAL_DATE).disabled = true;
    _f(FN.ARRIVAL_TIME).disabled = true;
    _f(FN.REMARK).label = '';
    _f(FN.COMMODITY).type = 'text';
    _f(FN.COMMODITY).defaultValue = 'USED HOUSEHOLD GOODS AND PERSONAL EFFECTS';
    _f(FN.SVC_TERM1).defaultValue = 'DR';
    _f(FN.SVC_TERM2).defaultValue = 'DR';
    _f(FN.CBM).onChange = (_, value) => setCBM(util.dispatch, value);
    _f(FN.PACKAGE).onChange = (_, value) => setPKG(util.dispatch, value ? util.toInt(value) : '');
    _f(FN.WAREHOUSE_ARRIVAL_DATE).onChange = (_, value) => setClearedBy(value);

    const setClearedBy = (value) => {
      const clearedBy = util.getAddDays(40, value);
      _c(FN.CLEARED_BY_DATE, clearedBy);
    };

    return (
      <div style={{width: 'calc(100% + 8px)'}}>
        {this.isShowForm &&
          <div className={'pl-20 pr-20 pt-12 pb-1'}>
            <div className={'flex w-full'}>
              <div className={'flex-1 mr-4'}>
                <div>REMARK(ORIGIN)</div>
                <div>{_r(FN.REMARK)}</div>
              </div>
              <div className={'flex-1'}>
                <div>REMARK(DESTINATION)</div>
                <div>{_r(FN.REMARK_D)}</div>
              </div>
            </div>
            {carrierType === 'O' && <OCEAN _r={_r} _f={_f} _v={_v} _c={_c} kind={kind}/>}
            {carrierType === 'A' && <AIR _r={_r} kind={kind}/>}
            {carrierType === 'G' && <GROUND _r={_r} kind={kind}/>}
          </div>
        }
      </div>
    );
  };
  onValidate = (values) => {};
}

const OCEAN = (props) => {
  const {_r, _f, _c, _v, kind} = props;
  _f(FN.GROSS_WEIGHT_KG).label = 'Weight';
  React.useEffect(() => {
    _c(FN.COMMODITY, _v(FN.COMMODITY) ?? 'USED HOUSEHOLD GOODS AND PERSONAL EFFECTS');
    _c(FN.SVC_TERM1, _v(FN.SVC_TERM1) ?? 'DR');
    _c(FN.SVC_TERM2, _v(FN.SVC_TERM2) ?? 'DR');
  }, []);
  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.CBM)}</div>
        {kind === 'V' && <div className={'flex-1 mr-2'}>{_r(FN.VIN_NO)}</div>}
        {kind !== 'V' && <div className={'flex-1 mr-2'}>{_r(FN.OFFER_CBM)}</div>}
        <div className={'flex-1 mr-2'}>{_r(FN.VSL_VOY)}</div>
        <div className={'flex-1'}>{_r(FN.POL)}</div>
      </div>
      <div className={'flex w-full'}>
        {kind === 'V' && <div className={'flex-1 mr-2'}>{_r(FN.CAR_INFO)}</div>}
        {kind !== 'V' && <div className={'flex-1 mr-2'}>{_r(FN.TAPE)}</div>}
        <div className={'flex-1 mr-2'}>{_r(FN.INSURANCE)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.CONTAINER_SEQ)}</div>
        <div className={'flex-1'}>{_r(FN.ETD)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.WAREHOUSE_ARRIVAL_DATE)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.CLEARED_BY_DATE)}</div>
        <div className={'flex-1 mr-2'}>{_r('containerNo')}</div>
        <div className={'flex-1'}>{_r(FN.POD)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.PACKAGE)}</div>
        <div className={'flex flex-1 mr-2'}>
          <div className={'flex-2 mr-2'}>{_r(FN.SVC_TERM1)}</div>
          <div className={'flex-1'}>{_r(FN.SVC_TERM2)}</div>
        </div>
        <div className={'flex-1'}>{_r(FN.ETA)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.COMMODITY)}</div>
      </div>
    </>
  );
};

const AIR = (props) => {
  const {_r, kind} = props;
  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.CBM)}</div>
        {kind === 'V' && <div className={'flex-1 mr-2'}>{_r(FN.VIN_NO)}</div>}
        {kind !== 'V' && <div className={'flex-1 mr-2'}>{_r(FN.TAPE)}</div>}
        <div className={'flex-1 mr-2'}>{_r(FN.FLIGHT_NO)}</div>
        <div className={'flex flex-1'}>
          <div className={'flex-3 mr-1'}>{_r(FN.DEPARTURE)}</div>
          <div className={'flex-1'}>{_r(FN.DEPARTURE_FLIGHT)}</div>
        </div>
      </div>
      <div className={'flex w-full'}>
        {kind === 'V' && <div className={'flex-1 mr-2'}>{_r(FN.CAR_INFO)}</div>}
        {kind !== 'V' && <div className={'flex-1 mr-2'}>{_r(FN.OFFER_CBM)}</div>}
        <div className={'flex-1 mr-2'}>{_r(FN.INSURANCE)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.WAREHOUSE_ARRIVAL_DATE)}</div>
        <div className={'flex flex-1'}>
          <div className={'flex-3 mr-1'}>{_r(FN.FLIGHT_DATE)}</div>
          <div className={'flex-1'}>{_r(FN.FLIGHT_TIME)}</div>
        </div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex flex-1 mr-2'}>{_r(FN.GROSS_WEIGHT_KG)} <span style={{marginTop: 15, marginLeft: 5}}>KG</span></div>
        <div className={'flex-1 mr-2'}>{_r(FN.CHARGEABLE_WEIGHT_KG)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.CLEARED_BY_DATE)}</div>
        <div className={'flex flex-1'}>
          <div className={'flex-3 mr-1'}>{_r(FN.DESTINATION)}</div>
          <div className={'flex-1'}>{_r(FN.DESTINATION_FLIGHT)}</div>
        </div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.PACKAGE)}</div>
        <div className={'flex-1 mr-2'}>{''}</div>
        <div className={'flex-1 mr-2'}>{''}</div>
        <div className={'flex flex-1'}>
          <div className={'flex-3 mr-1'}>{_r(FN.ARRIVAL_DATE)}</div>
          <div className={'flex-1'}>{_r(FN.ARRIVAL_TIME)}</div>
        </div>
      </div>
    </>
  );
};

const GROUND = (props) => {
  const {_r, kind} = props;
  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.CBM)}</div>
        {kind === 'V' && <div className={'flex-1 mr-2'}>{_r(FN.VIN_NO)}</div>}
        {kind !== 'V' && <div className={'flex-1 mr-2'}>{_r(FN.OFFER_CBM)}</div>}
        <div className={'flex-1 mr-2'}>{_r(FN.POL)}</div>
        <div className={'flex-1'}>{_r(FN.POD)}</div>
      </div>
      <div className={'flex w-full'}>
        {kind === 'V' && <div className={'flex-1 mr-2'}>{_r(FN.CAR_INFO)}</div>}
        {kind !== 'V' && <div className={'flex-1 mr-2'}>{_r(FN.TAPE)}</div>}
        <div className={'flex-1 mr-2'}>{_r(FN.INSURANCE)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.ETD)}</div>
        <div className={'flex-1'}>{_r(FN.ETA)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.PACKAGE)}</div>
        <div className={'flex-1 mr-2'}>{''}</div>
        <div className={'flex-1'}>{''}</div>
      </div>
    </>
  );
};

export default MovingHouseBLWorkDetailsFormManager;
