/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {apiBL, util} from '../../../services/service';
import {DefaultButton, PrimaryButton} from "../../../components/buttons";
import {apiCourier} from "../../../services/newCoship/service";
import {useSimpleGrid} from "../../../components/SimpleGrid";
import {usePackingListPreviewModal} from "../../../print-layouts/PackingListPrint";
import Icon from "mdi-react/SearchIcon";

class CourierHouseBLItemListFormManager extends FormManager {
  grid;
  data;
  houseBLForm;
  customerForm;
  workDetailsForm;
  constructor() {
    super({
      prefix: `courier-house-item-list-form`,
      fields: [
        {name: 'item', serverName: 'item', smallMargin: true},
        {name: 'qty', serverName: 'qty', smallMargin: true},
        {name: 'unit_price', serverName: 'unit_price', smallMargin: true},
        {name: 'hs_code', serverName: 'hs_code', smallMargin: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  setData = (data = []) => {
    if (data.length > 0) {
      this.data = util.addRowIdToList(data.map((value) =>
        ({...value, total_price: (value.qty && value.unit_price) ? util.toFloat(value.qty * value.unit_price) : 0})));
    } else {
      this.data = [];
    }
  };
  onRender = () => {
    const {renderField: _r, getField: _f, setValue: _c, getValue: _v, _n} = this;
    const qs = util.getQS();
    const {id} = qs;
    const [gridMode, setGridMode] = React.useState('add');
    const [selectedRowId, setSelectedRowId] = React.useState(0);
    const [totalQty, setTotalQty] = React.useState(0);
    const [totalValue, setTotalValue] = React.useState(0.00);

    const packingListPrintModal = usePackingListPreviewModal();

    const onAction = (action, data) => {
      if (action === 'delete') {
        onDelete(data).catch();
      }
    };

    const onCellClicked = (e) => {
      _c('item', e.data.item);
      _c('qty', e.data.qty);
      _c('unit_price', e.data.unit_price);
      _c('hs_code', e.data.hs_code);
      setSelectedRowId(e.data.rowId);
      setGridMode('edit');
    };

    const grid = useItemGrid(onAction, onCellClicked);
    this.grid = grid;

    const onSave = () => {
      const payload = {
        bl_id: util.toInt(id),
        // pack_date: util.getCurrentDate(),
        packing_list: this.grid.rows,
      };
      apiCourier.setPackingList(payload).then((res) => {
        if (res) {
          util.showSuccess('Packing List has been saved successfully!');
        }
      });
    };

    const onPrint = () => {
      const blData = {...this.houseBLForm.getValues(), ...this.customerForm.getValues(), ...this.workDetailsForm.getValues()};
      const data = [{...blData, pack_list: this.grid.rows}];
      packingListPrintModal.open(data);
    };

    const onAdd = () => {
      const data = this.getValues();
      if (!data.item) {
        return util.showWarning('Please enter Title!');
      }
      const rows = this.grid.rows ?? [];
      const totalPrice = (data.qty && data.unit_price) ? util.toFloat(data.qty * data.unit_price) : undefined;
      const selectedRow = {
        item: data.item,
        qty: data.qty ? util.toInt(data.qty) : undefined,
        unit_price: data.unit_price ? util.toFloat(data.unit_price) : undefined,
        total_price: totalPrice,
        hs_code: data.hs_code,
      };
      let newRows;
      if (gridMode === 'add') {
        newRows = util.addRowIdToList([...rows, {...selectedRow}]);
      } else {
        newRows = rows?.map((data) => {
          if (selectedRowId === data.rowId) {
            return selectedRow;
          } else {
            return data;
          }
        });
        newRows = util.addRowIdToList(newRows);
      }
      newRows = getNewItemListNo(newRows);
      this.grid.setRows(newRows);
      this.data = newRows;
      getTotal();
      onClear();
    };

    const onDelete = async (data) => {
      util.showConfirm('Are you sure to delete?', async () => {
        const {rowId} = data;
        const items = this.grid.rows?.filter(x => x.rowId !== rowId);
        const newRows = getNewItemListNo(util.addRowIdToList(items));
        this.grid.setRows(newRows);
        getTotal();
        onClear();
      });
    };

    const onHsCode = async () => {
      const value = _v('item');
      if (value) {
        const res = await apiBL.getHSCode(value);
        _c('hs_code', res.data[0].retval);
      }
    };

    const onClear = () => {
      this.clearValues();
      setSelectedRowId(0);
      setGridMode('add');
      const item_input = document.querySelector(`#${this.prefix}-item`);
      if (item_input) {
        item_input.focus();
      }
    };

    const getNewItemListNo = (items) => {
      return items.map((data, index) => ({...data, c_no: index+1}));
    };

    const getTotal = () => {
      const tQty = this.data?.map((data) => data.qty);
      const tPrice = this.data?.map((data) => data.total_price);
      setTotalQty(util.arraySum(tQty));
      setTotalValue(util.arraySum(tPrice));
    };

    React.useEffect(() => {
      this.grid.setRows(this.data);
      getTotal();
    }, [this.data]);

    return (
      <div className={'w-full ml-3'}>
        {grid.render()}
        <div className={'flex between'}>
          <div className={'flex'}>
            <div className={'mr-2'}><PrimaryButton label={'PRINT'} onClick={onPrint} disabled={!id} noMargin/></div>
            {/*<div><PrimaryButton label={'SAVE'} onClick={onSave} disabled={!id} noMargin/></div>*/}
          </div>
          <div className={'flex'}>
            <div className={'mr-4'}>{`QTY: ${totalQty}`}</div>
            <div>{`Total: ${util.formatCurrency(totalValue)}`}</div>
          </div>
        </div>
        <div className={'flex mt-2'}>
          <div className={'mr-2 flex-1'}>
            <div>Item</div>
            {_r('item')}
          </div>
          <div className={'mr-2'} style={{width: 45}}>
            <div>QTY</div>
            {_r('qty')}
          </div>
          <div className={'mr-2'} style={{width: 70}}>
            <div>Unit Price</div>
            {_r('unit_price')}
          </div>
          <div className={'mr-2'} style={{width: 80}}>
            <div>HS Code</div>
            <div className={'flex'}>
              <div>{_r('hs_code')}</div>
              <div className={'flex-1 mt-2 ml-1'}>
                <a href={'/'} onClick={(e) => {
                  e.preventDefault();
                  onHsCode().catch();
                }}>
                  <Icon size={20} />
                </a>
              </div>
            </div>
          </div>
          <div style={{alignContent: 'flex-end', marginBottom: 5}}>
            <DefaultButton label={gridMode === 'add' ? 'Add' : 'Edit'} onClick={onAdd} style={{height: 32}} noMargin/>
          </div>
        </div>
        {packingListPrintModal.render()}
      </div>
    );
  };
  onValidate = (values) => {};
}

function useItemGrid(onAction, onCellClicked) {
  const qs = util.getQS();
  const {id} = qs;
  const getColumns = () => {
    return [
      {field: 'item', headerName: 'Item', flex: 1},
      {field: 'qty', headerName: 'QTY', minWidth: 50, width: 50},
      {field: 'unit_price', headerName: 'Unit Value', minWidth: 85, width: 85, valueFormatter: util.currencyFormatter},
      {field: 'total_price', headerName: 'Total Value', minWidth: 90, width: 90, valueFormatter: util.currencyFormatter},
      {field: 'hs_code', headerName: 'HS Code', minWidth: 80, width: 80},
    ];
  };

  const actions = id ? ['delete'] : [];

  return useSimpleGrid({
    columns: getColumns(),
    height: 218,
    className: 'pb-8',
    actions,
    actionWidth: 70,
    onAction: onAction,
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
      onCellClicked(e) {
        if(e.colDef.headerName !== 'Actions') {
          onCellClicked(e);
        }
      }
    }
  });
}

export default CourierHouseBLItemListFormManager;
