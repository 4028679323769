/**
 * @flow
 */
import React from 'react';
import {useReactToPrint} from "react-to-print";
import {useSimpleModal} from "../components/SimpleModal";
import {util} from '../services/service';

class OEMShipInst extends React.Component {
  totalCommodityCNT = 0;
  totalWeight = 0;
  totalCBM = 0;

  render() {
    const {printData} = this.props;
    if (!printData) {
      return null;
    }
    const {blFormData, markData, containersData: containers} = printData;
    const {
      shipper, consignee, shipperAddr, consigneeAddr, notifyAddr, bkgNo,
      carrier, vslVoy, etd, eta, polLabel, podLabel, delLabel, package: packageCount, grossWeightKg, cbm
    } = blFormData;
    const {jmark: {remark, descOfGoods}} = markData;
    return (
      <div style={{color: 'black', fontSize: 16, marginTop: '2cm'}}>
        <div style={{textAlign: 'center'}}>
          <div className={'underLine'}><h2>HD SHIPPING LTD. B/L INSTRUCTION</h2></div>
        </div>
        <table className={'print-table'} style={{width: '100%', fontSize: 16, marginTop: 40}}>
          <tbody>
          <tr>
            <td width={'50%'} height={'180px'}>
              <div className={'label2'}>Shipper</div>
              <div className={'value6'}>{shipperAddr}</div>
            </td>
            <td width={'50%'} height={'180px'}>
              <div className={'label2'}>Consignee</div>
              <div className={'value6'}>{consigneeAddr}</div>
            </td>
          </tr>
          <tr>
            <td width={'50%'}>
              <div className={'label2'}>Notify party</div>
              <div className={'value6'}>{notifyAddr}</div>
            </td>
            <td width={'50%'}>
              <div className={'label2'}>Booking No.</div>
              <div className={'value6'}>{bkgNo}</div>
            </td>
          </tr>
          <tr>
            <td width={'50%'}>
              <div className={'flex'}>
                <div className={'flex-2'}>
                  <div className={'label2'}>Port of loading</div>
                  <div className={'value6'}>{polLabel}</div>
                </div>
                <div className={'flex-1'} style={{borderLeft: 'solid', borderWidth: 1, paddingLeft: 10}}>
                  <div className={'label2'}>ETD</div>
                  <div className={'value6'}>{etd}</div>
                </div>
              </div>
            </td>
            <td width={'50%'}>
              <div className={'flex'}>
                <div className={'flex-2'}>
                  <div className={'label2'}>Port of discharge</div>
                  <div className={'value6'}>{podLabel}</div>
                </div>
                <div className={'flex-1'} style={{borderLeft: 'solid', borderWidth: 1, paddingLeft: 10}}>
                  <div className={'label2'}>ETA</div>
                  <div className={'value6'}>{eta}</div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td width={'50%'} className={'no-border-b'}>
              <div className={'flex'}>
                <div className={'flex-2'}>
                  <div className={'label2'}>Vessel name & Voy No.</div>
                  <div className={'value6'}>{vslVoy}</div>
                </div>
                <div className={'flex-1'} style={{borderLeft: 'solid', borderWidth: 1, paddingLeft: 10}}>
                  <div className={'label2'}>Line</div>
                  <div className={'value6'}>{carrier}</div>
                </div>
              </div>
            </td>
            <td width={'50%'} className={'no-border-b'}>
              <div className={'label2'}>Place of delivery</div>
              <div className={'value6'}>{delLabel}</div>
            </td>
          </tr>
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%', fontSize: 16}}>
          <tbody>
          <tr>
            <td width={'20%'}>
              <div className={'label2'} style={{textAlign: 'center'}}>Mrks and Numbers</div>
            </td>
            <td width={'10%'}>
              <div className={'label2'} style={{textAlign: 'center'}}>No. of</div>
              <div className={'label2'} style={{textAlign: 'center'}}>Packages</div>
            </td>
            <td width={'40%'}>
              <div className={'label2'} style={{textAlign: 'center'}}>Description of Goods</div>
            </td>
            <td width={'15%'}>
              <div className={'label2'} style={{textAlign: 'center'}}>Gross Weight</div>
              <div className={'label2'} style={{textAlign: 'center'}}>(KGS)</div>
            </td>
            <td width={'15%'}>
              <div className={'label2'} style={{textAlign: 'center'}}>Measurement</div>
              <div className={'label2'} style={{textAlign: 'center'}}>(CBM)</div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={'label2'}>CNTR / SEAL NO.</div>
            </td>
            <td className={'center'}>
              <div className={'value'}>{packageCount}</div>
            </td>
            <td className={'center'}>
              <div className={'label2'}>{descOfGoods}</div>
            </td>
            <td className={'center'}>
              <div className={'value'}>{this.totalWeight.toFixed(2)}</div>
            </td>
            <td className={'center'}>
              <div className={'value'}>{this.totalCBM.toFixed(2)}</div>
            </td>
          </tr>
          {this.renderContainers(containers)}
          {this.renderEmptyItems(this.totalCommodityCNT)}
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%', height: '150px', fontSize: 16}}>
          <tbody>
          <tr>
            <td width={'20%'} className={'no-border-r'}>
              <div className={'label2'} style={{marginTop: 40}}>Remarks :</div>
            </td>
            <td width={'80%'} className={'no-border-l'}>
              <div className={'value2'} style={{marginTop: 40, lineHeight: 1.5}}>{remark}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderLine(label, value, labelWidth = 120) {
    return (
      <div className={'flex w-full pt-1'}>
        <div style={{width: labelWidth, minWidth: labelWidth}}><b>{label}</b></div>
        <div className={'mx-8'}>:</div>
        <div style={{whiteSpace: 'pre-line'}}>{value}</div>
      </div>
    );
  }

  renderContainers(containers) {
    this.totalCommodityCNT = 0;
    this.totalWeight = 0;
    this.totalCBM = 0;
    return containers?.map((container, index) => {
      const numberOfcomm = ((container.commodity?.match(/\n/g) || []).length ?? 0) + 1;
      this.totalCommodityCNT += numberOfcomm;
      this.totalWeight += util.toFloat(container.weight_kg ? container.weight_kg.toFixed(2) : container.weight_kg);
      this.totalCBM += util.toFloat(container.cbm?.toFixed(2));
      return (
        <tr key={index}>
          <td className={'center'}>
            <div className={'value'}>{container.container_no} / {'\n'}{container.seal_no}</div>
          </td>
          <td className={'center'}>
            <div className={'value'}>{container.pack_count}</div>
          </td>
          <td>
            <div className={'value6'}>{container.commodity}</div>
          </td>
          <td className={'center'}>
            <div className={'value'}>{container.weight_kg?.toFixed(2)}</div>
          </td>
          <td className={'center'}>
            <div className={'value'}>{container.cbm?.toFixed(2)}</div>
          </td>
        </tr>
      )
    });
  }

  renderEmptyItems(totalCommodityCNT) {
    const empty = [];
    let length = 38 - totalCommodityCNT;
    for (let i = 0; i < length; i++) {
      empty.push(
        <tr key={i}>
          <td colSpan={5} className={'no-border-t no-border-b'} height={'10px'} style={{paddingTop: '18px'}}>{''}</td>
        </tr>
      );
    }
    return empty;
  }
}

export function useOEMShipInstPreviewModal() {
  const [printData, setPrintData] = React.useState();
  const blDataRef = React.useRef();
  const markRef = React.useRef();
  const containersRef = React.useRef();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <OEMShipInst ref={printRef} printData={printData} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        blFormData: blDataRef.current,
        markData: markRef.current,
        containersData: containersRef.current,
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 0);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (blData, markData, containers) => {
      blDataRef.current = blData;
      markRef.current = markData[0]??{};
      containersRef.current = containers??[];
      modal.open();
    },
    print,
  };
}

export default OEMShipInst;
