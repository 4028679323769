/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {api, apiAccount, util} from '../../services/service';
import {DangerButton, DefaultButton, PrimaryButton} from "../../components/buttons";
import {dcTypeOptions, FD, FN, unitOptions} from "../field-defs/newCoship";
import {apiMoving, apiForwarding, apiCourier} from "../../services/newCoship/service";
import {useSimpleGrid} from "../../components/SimpleGrid";
import {AccountTypeSearchInput, TradePartnerSearchInput} from "../../components/SearchInput";
import {setHouseAccountList, setProfitArray} from "../../redux/reducers/newCoshipReducer";
import {useUser} from "../../redux/reducers/userReducer";
import {currencyOptions} from "../helper";
import {useSelector} from "react-redux";
import moment from 'moment';

class HouseBLAccountItemFormManager extends FormManager {
  isShowForm;
  blId;
  qid;
  grid;
  arAPList;
  totalAmount = 0;
  arInvoiceeNos = [];
  apInvoiceeNos = [];
  dcInvoiceeNos = [];
  invoiceARPrintModal;
  invoiceAPPrintModal;
  invoiceDCPrintModal;
  blData;
  billToData;
  constructor(accType: 'AR' | 'AP' | 'DC', kind: 'M' | 'F' | 'C' | 'V' | 'U' | 'P' | 'E') {
    super({
      prefix: `house-work_orders-form`,
      fields: [
        // eslint-disable-next-line max-len
        FD.INV_ID, FD.JOB_ID, FD.ATYPE_ID, FD.DESCRIPTION, FD.PARTNER_ID, {...FD.PARTNER_NAME, required: true}, FD.DC_TYPE, FD.UNIT, FD.RATE, FD.VOLUME,
        FD.REMARK, FD.INV_NO, FD.BRANCH_ID, FD.PRESET, {...FD.INV_DATE, required: true}, FD.BANK_INFO, FD.CURRENCY, FD.CAD_RATE, FD.VENDOR_INV,
        FD.MEMO, FD.BILL_ADDRESS, FD.PARTNER_ADDRESS, FD.DUE_DATE, FD.RATE_DATE, FD.RATE_TYPE,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.accType = accType;
    this.kind = kind;
    this.currencyData = {"USD": {"BUY": 1, "SELL": 1}, "KRW": {"BUY": 1, "SELL": 1}, "rateDate": ""};
    this.isMaster = false;
  };

  setMaster = (val) => {
    this.isMaster = val;
  }

  setKind = (val) => {
    this.kind = val;
  }

  setData = (data) => {
    if (this.accType === 'AR') {
      this.arInvoiceeNos = data?.map(data => ({label: data, value: data})) ?? [];
      this.arInvoiceeNos = [{label: 'New', value: 'New'}, ...this.arInvoiceeNos];
    } else if (this.accType === 'AP') {
      this.apInvoiceeNos = data?.map(data => ({label: data, value: data})) ?? [];
      this.apInvoiceeNos = [{label: 'New', value: 'New'}, ...this.apInvoiceeNos];
    } else {
      this.dcInvoiceeNos = data?.map(data => ({label: data, value: data})) ?? [];
      this.dcInvoiceeNos = [{label: 'New', value: 'New'}, ...this.dcInvoiceeNos];
    }
  };

  onRender = () => {
    const {renderField: _r, getField: _f, setValue: _c, getValue: _v, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const qs = util.getQS();
    const {id} = qs;
    const user = useUser();
    const [gridMode, setGridMode] = React.useState('add');
    const [currency, setCurrency] = React.useState('');
    const [branchId, setBranchId] = React.useState();
    const [preset, setPreset] = React.useState([]);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [totalDebit, setTotalDebit] = React.useState(0);
    const [totalCredit, setTotalCredit] = React.useState(0);
    const [balance, setBalance] = React.useState(0);
    const [selectedRowId, setSelectedRowId] = React.useState(0);
    const [selectedInvNo, setSelectedInvNo] = React.useState('New');
    const [selectedPresetNo, setSelectedPresetNo] = React.useState('');
    const [mainCurrency, setMainCurrency] = React.useState(currencyOptions[0].value);
    const [rateType, setRateType] = React.useState("");
    const [showLoading, setShowLoading] = React.useState<boolean>(false);
    const [usdRate, setUsdRate] = React.useState(1);
    const [krwRate, setKrwRate] = React.useState(1);
    const [rateDate, setRateDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [isLinked, setIsLinked] = React.useState<boolean>(false);
    const calCurRates = React.useRef({'CAD': 1, 'USD': 1, 'KRW': 1});
    const rateChange = React.useRef<boolean>(false);
    const {filingNo, kind} = useSelector(state => state['newCoship']);
    const isForwarding = this.kind === 'F';
    const isMoving = this.kind === 'M';
    const branchOptions = api.useAreaOptions(true);
    const bankInfo = apiAccount.useBankList();
    bankInfo.shift();
    _f(FN.BRANCH_ID).options = branchOptions;
    _f(FN.REMARK).type = 'text';
    _f(FN.CURRENCY).label = 'Currency';
    _f(FN.BANK_INFO).options = bankInfo;
    _f(FN.MEMO).type = 'text';
    _f(FN.PRESET).options = preset;
    _f(FN.PRESET).onChange = (_, value) => onChangePreset(value);
    _f(FN.INV_NO).noDefOption = true;
    _f(FN.INV_NO).options = this.accType === 'AR' ? this.arInvoiceeNos : (this.accType === 'AP' ? this.apInvoiceeNos : this.dcInvoiceeNos);
    _f(FN.INV_NO).onChange = (_, value) => onChangeInvNo(value);
    _f(FN.CURRENCY).onChange = (_, value) => onChangeCurrency(value);
    _f(FN.RATE_TYPE).onChange = (_, value) => onChangeRType(value);
    _f(FN.RATE_DATE).onChange = (_, value) => onChangeRDate(value);

    const onChangeCurrency = (value) => {
      if (value) {
        setMainCurrency(value);
        rateChange.current = true;
      }
    }

    const onChangeRType = (value) => {
      setRateType(value);
      rateChange.current = true;
      if (!value) {
        calCurRates.current = {CAD: 1, USD: 1, KRW: 1};
      }
    }

    const onChangeRDate = (value) => {
      if (value) {
        setRateDate(value);
        rateChange.current = true;
      }
    }

    const makeCalCurRate = (usd, krw, currency) => {
      let cad_rate = 1;
      let usd_rate = usd;
      let krw_rate = krw;
      if (currency === "USD") {
        usd_rate = 1;
        cad_rate = util.round(1 / usd, 4);
        krw_rate = util.round(krw / cad_rate, 2);
      } else if (currency === "KRW") {
        krw_rate = 1;
        // cad_rate = util.round(krw, 0);
        cad_rate = krw;
        usd_rate = util.round(usd * krw, 2);
      }
      calCurRates.current = {CAD: cad_rate, USD: parseFloat(usd_rate), KRW: parseFloat(krw_rate)};
      onCalculate();
    }

    const onChangePreset = (value) => {
      onReset();
      if (value) {
        // const api = isForwarding ? apiForwarding : apiMoving;
        const api = isForwarding ? apiForwarding : isMoving ? apiMoving : apiCourier;
        api.getPresetItems({id: util.toInt(value)}).then((res) => {
          if (res) {
            _c(FN.BRANCH_ID, res.data.branch_id);
            _c(FN.CURRENCY, res.data.main_currency);
            _c(FN.PRESET, value);
            // if (this.kind === 'F' && this.accType === 'AP') {
            if (this.accType === 'AP') {
              _c(FN.PARTNER_ID, res.data.bill_to_id);
              _c(FN.PARTNER_NAME, res.data.bill_to_name);
            }
            this.items = res.data.items;
            grid.setRows(res.data.items);
            onCalculate();
          }
        });
      }
      setSelectedPresetNo(value);
      setSelectedInvNo('New');
      setBillTo();
    };

    const onChangeInvNo = (value) => {
      onReset();
      if (value === 'New') {
        setSelectedInvNo('New');
      } else {
        setSelectedInvNo(value);
        onSearch(value);
      }
    };

    const _tp = (name, vertical = true) => {
      _f(`${name}_name`).smallMargin = true;
      const isActive = 'Y';
      return (
        <TradePartnerSearchInput
          idField={_n(`${name}_id`)} displayField={_n(`${name}_name`)}
          formik={formik} fields={fields} errors={errors} vertical={vertical} isActive={isActive}
        />
      );
    };

    const _at = (name) => <AccountTypeSearchInput
      idField={_n(`atype_id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} vertical
      onOK={(data) => {
        const {id, value, currency} = data;
        this.setValue('atype_id', id);
        this.setValue('description', value);
        setCurrency(currency);
      }}
    />;

    const onAction = (action, data) => {
      if (action === 'delete') {
        onDelete(data).catch();
      }
    };

    const onCellClicked = (e) => {
      _c(FN.ATYPE_ID, e.data.atype_id);
      _c(FN.DESCRIPTION, e.data.description);
      this.accType === 'DC' && _c(FN.DC_TYPE, e.data.dc_type);
      _c(FN.UNIT, e.data.unit);
      _c(FN.RATE, e.data.rate);
      _c(FN.VOLUME, e.data.volume);
      _c(FN.REMARK, e.data.remark);
      setSelectedRowId(e.data.rowId);
      setCurrency(e.data.currency);
      setGridMode('edit');
    };

    const grid = useAccountGrid(onAction, onCellClicked, this.accType);
    this.grid = grid;

    const onSearch = (value) => {
      const api = isForwarding ? apiForwarding : isMoving ? apiMoving : apiCourier;
      api.getAccountData({inv_no: value}).then((res) => {
        if (res) {
          const data = res.data;
          this.setValues(data);
          _c(FN.INV_NO, value);
          _c(FN.CURRENCY, data.main_currency);
          _c(FN.RATE_TYPE, data.cal_cur_data?.rate_type);
          const usd = data.cal_cur_data?.USD ?? {BUY: 1, SELL: 1};
          const krw = data.cal_cur_data?.KRW ?? {BUY: 1, SELL: 1};
          this.currencyData = {"USD": usd, "KRW": krw, "rateDate": data.cal_cur_data?.rate_date};
          if (usd.BUY === 1 && krw.BUY === 1) {
            setRateDate("");
            _c(FN.RATE_DATE, "");
          } else {
            setRateDate(data.cal_cur_data?.rate_date);
            _c(FN.RATE_DATE, data.cal_cur_data?.rate_date);
          }
          // setCurrencyRates(_v(FN.RATE_TYPE));

           _c(FN.BILL_ADDRESS, data.bill_address);
           _c(FN.PARTNER_ADDRESS, data.partner_address);

          setBalance(data.balance);
          setTotalAmount(data.amount);
          setIsLinked(data.is_linked);
          this.items = data.items;
          grid.setRows(data.items);
          // onCalculate();
          setMainCurrency(data.main_currency);
          setRateType(data.cal_cur_data?.rate_type);
          onClear();
          setCurrencyRates(data.cal_cur_data?.rate_type, data.main_currency, data.cal_cur_data);

        }
      });
    };

    const onSearchQuotationItems = () => {
      // const api = isForwarding ? apiForwarding : apiMoving;
      const api = isForwarding ? apiForwarding : isMoving ? apiMoving : apiCourier;
      api.getQuotationItems({id: util.toInt(this.qid)}).then((res) => {
        if (res) {
          const rows = res.data?.map((data, index) => ({...data, row_index: index}));
          grid.setRows(rows);
          this.items = rows;
          getTotalAmount(rows);
        }
      });
    };

    const onSave = async () => {
      setShowLoading(true);
      const values = this.getValues();
      if (!values.partner_name || !values.inv_date) {
        setShowLoading(false);
        return util.showWarning('Please enter required field!');
      }
      const rows = this.grid.rows ?? [];
      // let currencyRates;
      // if (values.rate_currency1 && values.rate_value1 && !(values.rate_currency2 && values.rate_value2)) {
      //   currencyRates = [{currency: values.rate_currency1, cur_rate: util.toFloat(values.rate_value1)}];
      // } else if (!(values.rate_currency1 && values.rate_value1) && values.rate_currency2 && values.rate_value2) {
      //   currencyRates = [{currency: values.rate_currency2, cur_rate: util.toFloat(values.rate_value2)}];
      // } else if (values.rate_currency1 && values.rate_value1 && values.rate_currency2 && values.rate_value2) {
      //   currencyRates = [
      //     {currency: values.rate_currency1, cur_rate: util.toFloat(values.rate_value1)},
      //     {currency: values.rate_currency2, cur_rate: util.toFloat(values.rate_value2)},
      //   ]
      // } else {
      //   currencyRates = [];
      // }
      // const cal_cur_data = {
      //   "CAD": calCurRates.current.CAD,
      //   "USD": calCurRates.current.USD,
      //   "KRW": calCurRates.current.KRW,
      //   "rate_date": rateDate,
      //   "rate_type": rateType,
      // };
      const cal_cur_data = {
        // "CAD": calCurRates.current.CAD,
        // "USD": calCurRates.current.USD,
        // "KRW": calCurRates.current.KRW,
        "USD": this.currencyData.USD,
        "KRW": this.currencyData.KRW,
        "rate_date": rateDate,
        "rate_type": rateType,
      };
      const items = rows?.map((data) => {
        return (
          {
            job_id: data.job_id ?? undefined,
            atype_id: data.atype_id ?? undefined,
            dc_type: (this.accType === 'DC' ? data.dc_type : undefined) ?? undefined,
            currency: data.currency ?? undefined,
            unit: data.unit ?? undefined,
            rate: data.rate ?? undefined,
            volume: data.volume ?? undefined,
            cur_rate: data.cur_rate ?? undefined,
            price: this.accType === 'DC' ? (data.price ?? undefined) : (data.amount ?? undefined),
            remark: data.remark ?? undefined,
          }
        );
      });
      const payload = {
        inv_id: values.inv_id ?? undefined,
        acc_type: this.accType,
        bl_id: util.toInt(this.blId),
        partner_id: values.partner_id ?? undefined,
        partner_name: values.partner_name ?? undefined,
        branch_id: branchId,
        inv_date: values.inv_date,
        due_date: values.due_date,
        bank_info: this.accType === 'DC' ? undefined : values.bank_info,
        memo: values.memo,
        main_currency: values.currency,
        cal_cur_data,
        // currency_rates: isForwarding ? currencyRates : undefined,
        // cad_rate: (!isForwarding && values.cad_rate) ? util.toFloat(values.cad_rate) : undefined,
        vendor_inv: values.vendor_inv ?? undefined,
        items,
      };
      // const api = isForwarding ? apiForwarding : apiMoving;
      const api = isForwarding ? apiForwarding : isMoving ? apiMoving : apiCourier;
      await api.setAccount(payload).then((res) => {
        if (res) {
          if (this.accType === 'AR') {
            this.arInvoiceeNos = payload.inv_id ? this.arInvoiceeNos : [...this.arInvoiceeNos, {label: res.data.inv_no, value: res.data.inv_no}];
          } else if (this.accType === 'AP') {
            this.apInvoiceeNos = payload.inv_id ? this.apInvoiceeNos : [...this.apInvoiceeNos, {label: res.data.inv_no, value: res.data.inv_no}];
          } else {
            this.dcInvoiceeNos = payload.inv_id ? this.dcInvoiceeNos : [...this.dcInvoiceeNos, {label: res.data.inv_no, value: res.data.inv_no}];
          }
          _f(FN.INV_NO).options = this.accType === 'AR' ? this.arInvoiceeNos : (this.accType === 'AP' ? this.apInvoiceeNos : this.dcInvoiceeNos);
          _c(FN.INV_NO, res.data.inv_no);
          _c(FN.PRESET, 'New');
          setSelectedInvNo(res.data.inv_no);
          setSelectedPresetNo('New');
          setHouseAccountList(util.dispatch, res.data.ar_ap_list);
          setProfitArray(util.dispatch, res.data.profit_array);
          setShowLoading(false);
          return util.showSuccess('Account has been saved successfully!');
        }
      });
      setShowLoading(false);
      onClear();
    };

    const onPreset = () => {
      const payload = {
        acc_type: this.accType,
        kind: this.kind,   // kind 추가
        branch_id: branchId,
        bound: undefined,
        carrier_type: undefined,
      };
      // const api = isForwarding ? apiForwarding : apiMoving;
      const api = isForwarding ? apiForwarding : isMoving ? apiMoving : apiCourier;
      api.getPresetList(payload).then((res) => {
        if (res) {
          const data = res.data.map(x => ({label: x.subject, value: x.id}));
          setPreset(data);
        }
      });
    };

    const getCurrencyRate = () => {
      if (rateDate === "") {
        return;
      }
      if (_v(FN.RATE_DATE) === undefined) {
        _c(FN.RATE_DATE, rateDate);
      }
      if(this.currencyData.rateDate === rateDate) {
        return;
      }
      const payload = {
        rate_date: rateDate,
      };
      apiForwarding.getTodayCurrencyRate(payload).then((res) => {
        if (res) {
          let usd = res.data?.USD ?? {"BUY": 1, "SELL": 1};
          let krw = res.data?.KRW ?? {"BUY": 1, "SELL": 1};
          this.currencyData = {"USD": usd, "KRW": krw, "rateDate": rateDate};
          setCurrencyRates(rateType, mainCurrency, this.currencyData);
        }
      });
    };

    const setCurrencyRates = (rType, currency, curData) => {
      let usd = 1;
      let krw = 1;
      if (rType === "BUY") {
        usd = curData.USD.BUY;
        krw = curData.KRW.BUY;
      } else if (rType === "SELL") {
        usd = curData.USD.SELL;
        krw = curData.KRW.SELL;
      }
      if (usdRate !== usd && krwRate !== krw) {
        setUsdRate(usd);
        setKrwRate(krw);
      }
      makeCalCurRate(usd, krw, currency);
    };

    const onAdd = () => {
      const values = this.getValues();
      if (!values.atype_id) {
        return util.showWarning('Please selet account type!');
      }
      onCalculate('onAdd');
      onClear();
    };

    const onCalculate = (mode) => {
      const values = this.getValues();
      const cad_rate = calCurRates.current.CAD;
      const usd_rate = calCurRates.current.USD;
      const krw_rate = calCurRates.current.KRW;
      const rows = this.grid.rows ?? [];
      let newRows;
      if (mode === 'onAdd') {
        const selectedRow = {
          atype_id: values.atype_id,
          description: values.description,
          inv_id: values.inv_id,
          job_id: values.job_id,
          unit: values.unit,
          rowId: values.rowId,
          rate: values.rate ? util.toFloat(values.rate) : 1,
          volume: values.volume ? util.toFloat(values.volume) : 1,
          currency,
          remark: values.remark,
          dc_type: this.accType === 'DC' ? values.dc_type : undefined,
        };
        // const calculatedData = getCalculatedRow({data: selectedRow, mainCurrency, rateCurrency1, rateValue1, rateCurrency2, rateValue2});
        const calculatedData = getCalculatedRow({data: selectedRow, cad_rate, usd_rate, krw_rate});
        if (gridMode === 'add') {
          newRows = [...rows, calculatedData];
        } else {
          newRows = rows?.map((data) => {
            if (selectedRowId === data.rowId) {
              return calculatedData;
            } else {
              return data;
            }
          });
          // setTotalDebit(util.arraySum(newRows.map((data) => data.debit)));
          // setTotalCredit(util.arraySum(newRows.map((data) => data.credit)));
        }
      } else {
        // newRows = rows?.map((data) => getCalculatedRow({data, mainCurrency, rateCurrency1, rateValue1, rateCurrency2, rateValue2}));
        newRows = rows?.map((data) => getCalculatedRow({data, cad_rate, usd_rate, krw_rate}));
      }
      if (this.accType === 'DC') {
        setTotalDebit(util.arraySum(newRows.map((data) => data.debit)));
        setTotalCredit(util.arraySum(newRows.map((data) => data.credit)));
      }
      newRows = util.addRowIdToList(newRows);
      this.items = newRows;
      grid.setRows(newRows);
      getTotalAmount(newRows);
    };

    const getCalculatedRow = (dataInfo) => {
      const {data, cad_rate, usd_rate, krw_rate} = dataInfo;
      const price = data.rate && data.volume ? data.rate * data.volume : 0;
      let cur_rate = 1;
      if (mainCurrency !== data.currency) {
        if (data.currency === "CAD") {
          cur_rate = rateChange.current ? cad_rate : (data?.cur_rate ?? cad_rate);
        } else if (data.currency === "USD") {
          cur_rate = rateChange.current ? usd_rate : (data?.cur_rate ?? usd_rate);
        } else if (data.currency === "KRW") {
          cur_rate = rateChange.current ? krw_rate : (data?.cur_rate ?? krw_rate);
        }
      }
      let amount = (mainCurrency !== "KRW" && cur_rate > 10) ? (price / cur_rate) : (price * cur_rate);
      if (mainCurrency === "KRW") {
        amount = util.round(amount, 0);
      }
      if (this.accType === 'DC') {
        return {
          ...data,
          cur_rate,
          price: amount,
          debit: data.dc_type === 'DEBIT' ? amount : 0,
          credit: data.dc_type === 'CREDIT' ? amount : 0,
        };
      } else {
        return {...data, cur_rate, amount};
      }

      // if (data.currency === "CAD") {
      //   const cur_rate = rateChange.current ? cad_rate : (data?.cur_rate ?? cad_rate);
      //   amount = (mainCurrency !== "KRW" && cur_rate > 10) ? (price / cur_rate) : (price * cur_rate);
      //   if (this.accType === 'DC') {
      //     return {
      //       ...data,
      //       cur_rate,
      //       price: amount,
      //       debit: data.dc_type === 'DEBIT' ? amount : 0,
      //       credit: data.dc_type === 'CREDIT' ? amount : 0,
      //     };
      //   } else {
      //     return {...data, cur_rate, amount};
      //   }
      // } else if (data.currency === "USD") {
      //   const cur_rate = rateChange.current ? usd_rate : (data?.cur_rate ?? usd_rate);
      //   amount = (mainCurrency !== "KRW" && cur_rate > 10) ? (price / cur_rate) : (price * cur_rate);
      //   if (this.accType === 'DC') {
      //     return {
      //       ...data,
      //       cur_rate,
      //       price: amount,
      //       debit: data.dc_type === 'DEBIT' ? amount : 0,
      //       credit: data.dc_type === 'CREDIT' ? amount : 0,
      //     };
      //   } else {
      //     return {...data, cur_rate, amount};
      //   }
      // } else if (data.currency === "KRW") {
      //   const cur_rate = rateChange.current ? krw_rate : (data?.cur_rate ?? krw_rate);
      //   amount = cur_rate > 10 ? (price / cur_rate) : (price * cur_rate);
      //   if (this.accType === 'DC') {
      //     return {
      //       ...data,
      //       cur_rate,
      //       price: amount,
      //       debit: data.dc_type === 'DEBIT' ? amount : 0,
      //       credit: data.dc_type === 'CREDIT' ? amount : 0,
      //     };
      //   } else {
      //     return {...data, cur_rate, amount};
      //   }
      // } else {
      //   if (this.accType === 'DC') {
      //     return {
      //       ...data,
      //       cur_rate: 1,
      //       price: amount,
      //       debit: data.dc_type === 'DEBIT' ? amount : 0,
      //       credit: data.dc_type === 'CREDIT' ? amount : 0,
      //     };
      //   } else {
      //     return {...data, cur_rate: 1, amount};
      //   }
      // }
    };

    const getCalculatedRow_old = (dataInfo) => {
      const {data, mainCurrency, rateCurrency1, rateValue1, rateCurrency2, rateValue2} = dataInfo;
      const price = data.rate && data.volume ? data.rate * data.volume : 0;
      let amount = price;
      if (isForwarding) {
        if (data.currency === mainCurrency) {
          if (this.accType === 'DC') {
            return {
              ...data,
              cur_rate: 1,
              price: amount,
              debit: data.dc_type === 'DEBIT' ? amount : 0,
              credit: data.dc_type === 'CREDIT' ? amount : 0,
            };
          } else {
            return {...data, cur_rate: 1, amount};
          }
        } else if (data.currency === rateCurrency1) {
          const curRate = rateValue1 ? rateValue1 : 1;
          if (rateCurrency1 === 'KRW') {
            amount = price / curRate;
          } else {
            amount = price * curRate;
          }
          if (this.accType === 'DC') {
            return {
              ...data,
              cur_rate: rateValue1,
              price: amount,
              debit: data.dc_type === 'DEBIT' ? amount : 0,
              credit: data.dc_type === 'CREDIT' ? amount : 0,
            };
          } else {
            return {...data, cur_rate: curRate, amount};
          }
        } else if (data.currency === rateCurrency2) {
          const curRate = rateValue2 ? rateValue2 : 1;
          if (rateCurrency2 === 'KRW') {
            amount = price / curRate;
          } else {
            amount = price * curRate;
          }
          if (this.accType === 'DC') {
            return {
              ...data,
              cur_rate: rateValue2,
              price: amount,
              debit: data.dc_type === 'DEBIT' ? amount : 0,
              credit: data.dc_type === 'CREDIT' ? amount : 0,
            };
          } else {
            return {...data, cur_rate: curRate, amount};
          }
        } else {
          if (this.accType === 'DC') {
            return {
              ...data,
              cur_rate: 1,
              price: amount,
              debit: data.dc_type === 'DEBIT' ? amount : 0,
              credit: data.dc_type === 'CREDIT' ? amount : 0,
            };
          } else {
            return {...data, cur_rate: 1, amount};
          }
        }
      } else {
        return {...data, cur_rate: 1, amount};
      }
    };

    const onDeAllocate = async () => {
      if (selectedInvNo === 'New') {
        return util.showWarning('Please select Invoice No.');
      }
      setShowLoading(true);
      util.showConfirm('Are you sure to deallocate this AP?', async () => {
        const res = await apiForwarding.setAllocateAP({inv_no: selectedInvNo, is_del: true});
        if(res) {
          if(res.data?.success) {
            setIsLinked(false);
            util.showSuccess('AP has been deallocated successfully!');
          } else {
            util.showWarning('There is some error !!!');
          }
        }
      });
      setShowLoading(false);
    };

    const onAllocate = async () => {
      if (selectedInvNo === 'New') {
        return util.showWarning('Please select Invoice No.');
      }
      if(totalAmount === 0) {
        return util.showWarning('Total amount is 0. Can not allocate this AP.');
      }
      setShowLoading(true);
      util.showConfirm('Are you sure to allocate this AP amount to HBLs?', async () => {
        const res = await apiForwarding.setAllocateAP({inv_no: selectedInvNo});
        if(res) {
          setIsLinked(true);
          util.showSuccess('AP has been allocated successfully!');
        }
      });
      setShowLoading(false);
    };

    const onDelete = async (data) => {
      util.showConfirm('Are you sure to delete?', async () => {
        const {rowId} = data;
        const items = this.grid.rows?.filter(x => x.rowId !== rowId);
        this.grid.setRows(items);
        onClear();
      });
    };

    const onPrint = () => {
      if (selectedInvNo === 'New') {
        return util.showWarning('Please select Invoice No.');
      }
      const accountData = {...this.getValues(), ...this.blData, partnerAddr: _v(FN.PARTNER_ADDRESS), filingNo, totalAmount, totalDebit, totalCredit, balance};
      const bankData = _v(FN.BANK_INFO);
      if (this.accType === 'AR') {
        // onSave(false).then(() => {
          apiAccount.branchList().then(res => {
            apiAccount.bankInfo(branchId, bankData).then(response => {
              this.invoiceARPrintModal.open(accountData, this.grid.rows, res?.data?.lists, response?.data?.[0]);
            });
          });
        // });
      } else if (this.accType === 'AP') {
        this.invoiceAPPrintModal.open(accountData, this.grid.rows);
      } else if (this.accType === 'DC') {
        apiAccount.branchList().then(res => {
          apiAccount.bankInfo(branchId, bankData).then(response => {
            this.invoiceDCPrintModal.open(accountData, this.grid.rows, res?.data?.lists, response?.data?.[0]);
          });
        });
      }
    };

    const onReset = () => {
      onClear();
      this.clearValues();
      this.items = [];
      grid.setRows([]);
      setTotalAmount(0);
      setTotalCredit(0);
      setTotalDebit(0);
      setMainCurrency(currencyOptions[0].value);
      _c(FN.CURRENCY, currencyOptions[0].value);
      _c(FN.RATE_TYPE, "");
      _c(FN.RATE_DATE, moment().format('YYYY-MM-DD'));
      setRateType("");
      setRateDate(moment().format('YYYY-MM-DD'));
      rateChange.current = false;
    };

    const onClear = () => {
      _c(FN.ATYPE_ID, '');
      _c(FN.DESCRIPTION, '');
      _c(FN.DC_TYPE, dcTypeOptions[0].value);
      _c(FN.UNIT, unitOptions[0].value);
      _c(FN.RATE, '');
      _c(FN.VOLUME, '');
      _c(FN.REMARK, '');
      setGridMode('add');
    };

    const getTotalAmount = (rows) => {
      if (this.accType === 'DC') {
        this.totalAmount = util.arraySum(rows?.map((data) => {
          if (data.dc_type === 'DEBIT') {
            return data.debit;
          } else {
            return data.credit * -1;
          }
        }));
      } else {
        this.totalAmount = util.arraySum(rows?.map((data) => data.amount));
      }
      setTotalAmount(this.totalAmount);
    };

    const setBillTo = () => {
      if (selectedInvNo === 'New' && this.accType === 'AR' && this.billToData?.billToId) {
          _c(FN.PARTNER_ID, this.billToData.billToId);
          _c(FN.PARTNER_NAME, this.billToData.billToName);
      }
    };

    React.useEffect(() => {
      _c(FN.CURRENCY, currencyOptions[0].value);
      _c(FN.DC_TYPE, dcTypeOptions[0].value);
      _c(FN.UNIT, unitOptions[0].value);
    }, []);

    React.useEffect(() => {
      if (!id) {
        this.totalAmount = 0;
        setTotalAmount(0);
        grid.setRows([]);
      }
    }, [id]);

    React.useEffect(() => {
      if (!_v(FN.BRANCH_ID)) {
        _c(FN.BRANCH_ID, user.branch_id);
        setBranchId(user.branch_id);
      } else {
        this.isShowForm && this.accType !== 'DC' && onPreset();
      }
    }, [user.branch_id, _v(FN.BRANCH_ID), this.isShowForm]);

    React.useEffect(() => {
      if (this.qid) {
        onSearchQuotationItems();
      }
    }, [this.qid]);

    React.useEffect(() => {
      setBillTo();
    }, [this.billToData?.billToId, selectedInvNo, selectedPresetNo]);

    React.useEffect(() => {
      getCurrencyRate();
    }, [rateDate]);

    React.useEffect(() => {
      setCurrencyRates(rateType, mainCurrency, this.currencyData);
    }, [rateType, mainCurrency]);

    React.useEffect(() => {
      console.log("usdRate, krwRate.... change......... ");
      // if (_v(FN.RATE_DATE)) {
      //   setCurrencyRates(rateType);
      // }
    }, [usdRate, krwRate]);

    return (
      <div style={{width: 'calc(100% + 8px)'}}>
        {this.isShowForm &&
          <div className={'pl-8 pr-20 pt-20 pb-1'}>
            <div className={'flex w-full'}>
              <div className={'flex-1 ml-2 mr-4'}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                  <label>
                    {this.accType === 'AR' ? `Account Receivable [${selectedInvNo}] ` :
                        (this.accType === 'AP' ? `Account Payable [${selectedInvNo}] ` : `DC [${selectedInvNo}] `)}
                    {`Currency Rate: USD: ${usdRate}, KRW: ${krwRate}`}
                  </label>
                  <div className={'flex'}>
                    {this.isMaster && isLinked && <div className={'mr-2'}><DangerButton label={'DEALLOCATE'} onClick={onDeAllocate} disabled={!id || showLoading} noMargin/></div>}
                    {this.isMaster && !isLinked && <div className={'mr-2'}><PrimaryButton label={'ALLOCATE'} onClick={onAllocate} disabled={!id || showLoading} noMargin/></div>}
                    {!this.isMaster && <div className={'mr-2'}><PrimaryButton label={'PRINT'} onClick={onPrint} disabled={!id} noMargin/></div>}
                    <div><PrimaryButton label={'SAVE'} onClick={onSave} noMargin disabled={!id || showLoading}/></div>
                  </div>
                </div>
                <div>
                  <div>
                    <div className={'flex mt-1'}>
                      <div className={'flex-1'}>{_r(FN.INV_NO)}</div>
                      <div className={'flex-1'}>{_tp('partner')}</div>
                      <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
                    </div>
                    {this.accType === 'DC' ?
                      <div className={'flex mt-1'}>
                        <div className={'flex-1'}>{_r(FN.INV_DATE)}</div>
                        <div className={'flex-1'}>{''}</div>
                        <div className={'flex-1'}>{''}</div>
                      </div> : ((this.accType === 'AR') ?
                        <div className={'flex mt-1'}>
                          <div className={'flex-1'}>{_r(FN.PRESET)}</div>
                          <div className={'flex-1'}>{_r(FN.INV_DATE)}</div>
                          <div className={'flex-1'}>{_r(FN.DUE_DATE)}</div>
                        </div>
                        :
                        <div className={'flex mt-1'}>
                          <div className={'flex-1'}>{_r(FN.PRESET)}</div>
                          <div className={'flex-1'}>{_r(FN.INV_DATE)}</div>
                          <div className={'flex-1'}>{_r(FN.VENDOR_INV)}</div>
                        </div>)
                    }
                    <div className={'flex mt-1'}>
                      <div className={'flex-1'}>{_r(FN.RATE_DATE)}</div>
                      <div className={'flex-1'}>{_r(FN.RATE_TYPE)}</div>
                      <div className={'flex-1'}>{_r(FN.CURRENCY)}</div>
                    </div>
                    <div className={'flex mt-1'}>
                      {this.accType === 'DC' && <div className={'flex-2'}>{_r(FN.MEMO)}</div>}
                      {this.accType !== 'DC' &&
                        <>
                          <div className={'flex-2'}>{_r(FN.MEMO)}</div>
                          <div className={'flex-1'}>{_r(FN.BANK_INFO)}</div>
                        </>
                      }
                    </div>
                  </div>
                  <div className={'mt-2'} style={{textAlign: 'right', fontWeight: 'bold'}}>Total: {util.formatCurrency(totalAmount, mainCurrency, 2)}</div>
                  {grid.render()}
                  <div className={'flex mt-1'}>
                    <div className={'mr-2 flex-1'}>{_at(FN.DESCRIPTION)}</div>
                    {this.accType === 'DC' && <div className={'mr-2 flex-1'}>{_r(FN.DC_TYPE)}</div>}
                    <div className={'mr-2 flex-1'}>{_r(FN.UNIT)}</div>
                    <div className={'mr-2 flex-1'}>{_r(FN.RATE)}</div>
                    <div className={'mr-2 flex-1'}>{_r(FN.VOLUME)}</div>
                    <div className={'mr-2 flex-1'}>{_r(FN.REMARK)}</div>
                    <DefaultButton label={gridMode === 'add' ? 'Add' : 'Edit'} onClick={onAdd} style={{height: 32}} disabled={!id} noMargin/>
                  </div>
                </div>
                {this.kind === 'F' &&
                  <div className={'flex mt-3 mb-3 center'}>
                    <div className={'mr-2'}><PrimaryButton label={'PRINT'} onClick={onPrint} disabled={!id} noMargin/></div>
                    <div><PrimaryButton label={'SAVE'} onClick={onSave} disabled={!id} noMargin/></div>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    );
  };
  onValidate = (values) => {};
}

function useAccountGrid(onAction, onCellClicked, accType) {
  const getColumns = () => {
    if (accType === 'DC') {
      return [
        {field: 'inv_id', hide: true},
        {field: 'job_id', hide: true},
        {field: 'atype_id', hide: true},
        {field: 'description', headerName: 'Account Type', rowDrag: true, flex: 1},
        {field: 'dc_type', headerName: 'D/C', flex: 1},
        {field: 'currency', headerName: 'Currency', flex: 1},
        {field: 'unit', headerName: 'Unit', flex: 1},
        {field: 'rate', headerName: 'Rate', valueFormatter: util.currencyFormatterEx, flex: 1},
        {field: 'volume', headerName: 'Volume', valueFormatter: util.currencyFormatterEx, flex: 1},
        {field: 'cur_rate', headerName: 'CUR. Rate', valueFormatter: (param) => util.currencyFormatterEx2(param, 12), flex: 1},
        {field: 'price', headerName: 'Revenue/Cost', valueFormatter: util.currencyFormatterEx, flex: 1},
        {field: 'debit', headerName: 'Debit', valueFormatter: (param) => util.currencyFormatterEx2(param, 2), flex: 1},
        {field: 'credit', headerName: 'Credit', valueFormatter: (param) => util.currencyFormatterEx2(param, 2), flex: 1},
        {field: 'remark', headerName: 'Remark', flex: 1},
      ];
    } else {
      return [
        {field: 'inv_id', hide: true},
        {field: 'job_id', hide: true},
        {field: 'atype_id', hide: true},
        {field: 'description', headerName: 'Account Type', rowDrag: true, flex: 1},
        {field: 'currency', headerName: 'Currency', flex: 1},
        {field: 'unit', headerName: 'Unit', flex: 1},
        {field: 'rate', headerName: 'Rate', valueFormatter: util.currencyFormatterEx, flex: 1},
        {field: 'volume', headerName: 'Volume', valueFormatter: util.currencyFormatterEx, flex: 1},
        {field: 'cur_rate', headerName: 'CUR. Rate', valueFormatter: (param) => util.currencyFormatterEx2(param, 12), flex: 1},
        {field: 'amount', headerName: 'Amount', valueFormatter: (param) => util.currencyFormatterEx2(param, 2), flex: 1},
        {field: 'remark', headerName: 'Remark', flex: 1},
      ];
    }
  };

  const actions = ['delete'];

  const grid = useSimpleGrid({
    columns: getColumns(),
    height: 250,
    className: 'pb-20',
    actions,
    actionWidth: 70,
    onAction: onAction,
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
      onCellClicked(e) {
        if(e.colDef.headerName !== 'Actions') {
          onCellClicked(e);
        }
      },
      onRowDragEnd(e) {
        const rows = grid.rows;
        const id = e.node.data['rowId'];
        const newIndex = e.node.rowIndex;
        const sourceIndex = rows.findIndex(i => i.rowId === id);
        const sourceRow = rows[sourceIndex];
        rows.splice(sourceIndex, 1);
        rows.splice(newIndex, 0, sourceRow);
        grid.setRows(rows);
      },
    }
  });
  return grid;
}

export default HouseBLAccountItemFormManager;
