/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {renderField} from '../../components/Form';
import {ColDef} from 'ag-grid-community';
import type {FormField} from '../../components/Form';
import {currencyOptions} from '../../forms/helper';
import {useUser} from "../../redux/reducers/userReducer";

const AccountTypeList = () => {
  return (
    <DataGridView
      name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
      addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
      actions={ACTIONS} actionWidth={ACTION_WIDTH}
      showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
      columns={COLUMNS}
      onAction={async (action, data) => onAction(action, data)}
      onQuery={async (gridInfo) => onQuery(gridInfo)}
      renderForm={(formik, fields, errors) => renderForm(formik, fields, errors)}
      useExtendedColDef
      doNotShowErrorMessage
    />
  );
};

const NAME = 'accountTypeList';
const LABEL = 'Account Type';
const ADD_LABEL = 'ADD ACCOUNT TYPE';
const EDIT_LABEL = 'EDIT ACCOUNT TYPE';
const CATEGORY_LABEL = 'Account';
const MENU_LABEL = 'Type';
const SORT_COL = 'atype';
const IS_DESC = false;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 540;
const SHOW_DATE_SEARCH = false;
const ACTIONS = ['edit', 'delete'];
const ACTION_WIDTH = 80;
const COLUMNS: (ColDef | FormField)[] = [
  { name: 'id', required: false,
    field: 'id', hide: true},
  { name: 'accountType', required: true,
    field: 'atype', headerName: 'Type Name', flex: 1},
  { name: 'currency', required: true, options: currencyOptions, noDefOption: true,
    field: 'currency', headerName: 'Currency', flex: 1, hide: true},
  { name: 'price', required: true,
    field: 'price', headerName: 'Price', valueFormatter: (p) => util.currencyFormatterEx(p, 'currency'), flex: 1},
  { name: 'rewardRate', required: false,
    field: 'reward_rate', headerName: 'Reward Rate', flex: 1},
  { name: 'isActive', type: 'checkbox', required: false,
    field: 'isactive', headerName: 'Activated', valueFormatter: util.yesNoFormatter, flex: 1},
  { name: 'remark', required: false,
    field: 'remark', headerName: 'Remark', flex: 2},
  { name: 'footer', required: false,
    field: 'footer', hide: true},
];

async function onAction(action, data) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
  let error;
  switch (action) {
    case 'add':
      error = onValidate(data);
      if(error) {
        return util.showWarning(error);
      } else {
        return api.accountTypeAdd(data);
      }
    case 'edit':
      error = onValidate(data);
      if(error) {
        return util.showWarning(error);
      } else {
        return api.accountTypeEdit(data);
      }
    case 'delete':
      return api.accountTypeDel(data);
    // case 'detail':
    //   return;
    // case 'addOpen':
    //   return;
    // case 'editOpen':
    //   return;
  }
}

function onValidate(data) {
  if(isNaN(data.rewardRate)) {
    return 'Please enter a valid reward rate data!';
  }
  if(data.rewardRate >= 1 || data.rewardRate < 0) {
    return 'Please enter a valid data between 0 and 1';
  }
  return;
}

async function onQuery(gridInfo) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  return api.accountTypeList(gridInfo);
}

function renderForm(formik, fields, errors) {
  const render = (name) => renderField(formik, name, fields, errors);
  const user = useUser();
  React.useEffect(() => {
    if (user.id > 0) {
      const currency = formik.values['currency'];
      const price = formik.values['price'];
      const rewardRate = formik.values['rewardRate'];
      const isActive = formik.values['isActive'];
      if (!currency) {
        formik.setFieldValue('currency', user.currency);
      }
      if (!price) {
        formik.setFieldValue('price', 0);
      }
      if (!rewardRate) {
        formik.setFieldValue('rewardRate', 0);
      }
      if (isActive === undefined) {
        formik.setFieldValue('isActive', true);
      }
    }
  }, [user.id]);
  return (
    <>
      {render('accountType')}
      {render('currency')}
      {render('price')}
      {render('rewardRate')}
      {render('remark')}
      {render('isActive')}
    </>
  );
}

export default AccountTypeList;
