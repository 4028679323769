/**
 * @flow
 */
import React from 'react';
import {apiSettings, util} from "../../services/service";
import {useSimpleGridModal} from "../../components/SimpleModal";
import {RowClickedEvent} from "ag-grid-community";


export default function useEmailModal() {
  const [blId, setBlId] = React.useState();
  const [partnerId, setPartnerId] = React.useState();
  const [customerEmail, setCustomerEmail] = React.useState();
  const [sendEmailModal, setSendEmailModal] = React.useState();

  async function onSearch() {
    if (blId) {
      const data = {blId, partnerId};
      const res = await apiSettings.getLogEmail(data);
      modal.setRows(res.data);
    }
  }

  async function onNew() {
    const dataInfo = {partnerId, mode: 'new', toEmail: customerEmail}
    sendEmailModal.open('send', dataInfo);
  }

  function getButtons() {
    return [
      {label: 'New', color: 'primary', onClick: onNew},
    ];
  }

  function getTitle() {
    return `EMAIL HISTORY`;
  }

  const modal = useSimpleGridModal({
    title: getTitle(),
    centered: true,
    width: 920,
    buttons: getButtons(),
    gridProps: {
      columns: [
        {field: 'cdate', headerName: 'Send Date', valueFormatter: util.dateFormatter, width: 120},
        {field: 'customer', headerName: 'Customer', width: 100},
        {field: 'receiver', headerName: 'Receiver', width: 100},
        {field: 'staff', headerName: 'Staff', width: 120},
        {field: 'sender', headerName: 'Sender', width: 120},
        {field: 'subject', headerName: 'Subject', flex: 1},
      ],
      actions: ['edit'],
      actionWidth: 70,
      onAction: (action, data) => {
        if (action === 'edit') {
          const picName = data.sender.includes('<') ? data.sender?.split('<')[0] : data.staff;
          const picEmail = data.sender.includes('<') ? data.sender?.split('<')[1].replace('>', '') : data.sender;
          const dataInfo = {
            title: data.subject,
            pic_name: picName,
            toEmail: data.receiver,
            pic_email: picEmail,
            message: data.email_msg,
            partnerId,
            mode: 'edit',
          };
          sendEmailModal.open('re-send', dataInfo);
        }
      },
      rows: [],
      height: 250,
      className: 'mb-20',
      agGridProps: {
        onRowClicked(e: RowClickedEvent) {
          const picName = e.data.sender.includes('<') ? e.data.sender?.split('<')[0] : e.data.staff;
          const picEmail = e.data.sender.includes('<') ? e.data.sender?.split('<')[1].replace('>', '') : e.data.sender;
          const dataInfo = {
            title: e.data.subject,
            pic_name: picName,
            toEmail: e.data.receiver,
            pic_email: picEmail,
            message: e.data.email_msg,
            partnerId,
            mode: 'edit',
          };
          sendEmailModal.open('re-send', dataInfo);
        }
      }
    },
  });

  React.useEffect(() => {
    if (modal.isOpen === true) {
      onSearch().catch();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    onSearch,
    open: (blId, partnerId, customerEmail, sendEmailModal) => {
      setBlId(blId);
      setPartnerId(partnerId);
      setCustomerEmail(customerEmail);
      setSendEmailModal(sendEmailModal);
      modal.open();
    },
  };
}
