/**
 * @flow
 */
import React from "react";
import FormManager from "../../../lib/FormManager";
import {api, util} from "../../../services/service";
import {oceanAirOptionsForBLList} from "../../helper";
import {kindOptions} from "../../field-defs/bl";
import {useUser} from "../../../redux/reducers/userReducer";
import Icon from "mdi-react/SearchIcon";
import {blStatusOptions} from "../../field-defs/newCoship";

export const SEARCH = 'qryText';
export const MOVING_FROM_DATE = 'fromDate';
export const MOVING_TO_DATE = 'toDate';
export const MOVING_KIND = 'kind';
export const MOVING_BRANCH = 'branchId';
export const MOVING_CARRIER_TYPE = 'carrierType';
export const MOVING_DEPARTURE = 'departure';
export const MOVING_BL_STATIUS= 'bl_status';
export const MOVING_DESTINATION = 'destination';
export const MOVING_FLAG = 'flag';

export default class MovingBLListSearchFormManager extends FormManager {
  onSearch: () => void;
  partnerData;
  clickedIndex;
  constructor() {
    super({
      prefix: 'moving-bl-list-search',
      fields: [
        {name: SEARCH, serverName: SEARCH, label: 'Search', smallMargin: true},
        {name: MOVING_FROM_DATE, serverName: MOVING_FROM_DATE, label: 'Date', smallLabel: true, smallMargin: true, type: 'date'},
        {name: MOVING_TO_DATE, serverName: MOVING_TO_DATE, label: '', smallLabel: true, smallMargin: true, type: 'date'},
        {name: MOVING_KIND, serverName: MOVING_KIND, label: 'Service', options: [{value: '', label: 'ALL'}, ...kindOptions], noDefOption: true, smallMargin: true},
        {name: MOVING_BRANCH, serverName: MOVING_BRANCH, label: 'Branch', noDefOption: true, smallMargin: true},
        {name: MOVING_CARRIER_TYPE, serverName: MOVING_CARRIER_TYPE, label: 'Carrier Type', options: [{value: '', label: 'ALL'}, ...oceanAirOptionsForBLList], noDefOption: true, smallMargin: true},
        {name: MOVING_DEPARTURE, serverName: MOVING_DEPARTURE, label: 'Departure', smallMargin: true},
        {name: MOVING_BL_STATIUS, serverName: MOVING_BL_STATIUS, label: 'B/L Status', options: [{value: '', label: 'ALL'}, ...blStatusOptions], noDefOption: true, smallMargin: true},
        {name: MOVING_DESTINATION, serverName: MOVING_DESTINATION, label: 'Destination', smallMargin: true},
        {name: MOVING_FLAG, serverName: MOVING_FLAG, type: 'checkbox', smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [isFirstLoading, setIsFirstLoading] = React.useState(true);
    const areaOptions = api.useAreaOptions(true);
    const user = useUser();
    _f(MOVING_BRANCH).options = [{value: '', label: 'ALL'}, ...areaOptions];
    _f(SEARCH).onEnter = this.onSearch;

    const fromDate = _v(MOVING_FROM_DATE);
    const toDate = _v(MOVING_TO_DATE);
    const kind = _v(MOVING_KIND);
    const branchId = _v(MOVING_BRANCH);
    const carrierType = _v(MOVING_CARRIER_TYPE);

    const onClickSearch = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.onSearch();
    };

    const _sh = () => {
      return (
        <div className={`flex middle`}>
          {_r(SEARCH)}
          <div className={'ml-8 mt-16'}>
            <a href={'/#search'} onClick={onClickSearch}>
              <Icon size={20} />
            </a>
          </div>
        </div>
      );
    };

    React.useEffect(() => {
      _c(MOVING_FROM_DATE, util.toDefaultFirstTS(util.getCurrentDate()));
      _c(MOVING_TO_DATE, util.toDefaultEndTS(util.getCurrentDate()));
      // _c(MOVING_KIND, 'M');
      setIsFirstLoading(false);
      const search = document.querySelector(`#${this.prefix}-qryText`);
      if (search) {
        search.focus();
      }
    }, []);

    // React.useEffect(() => {
    //   if (!branchId) {
    //     _c(MOVING_BRANCH, user.branch_id);
    //   }
    // }, [user.branch_id]);

    // React.useEffect(() => {
    //   if (!(kind === undefined && branchId === undefined && carrierType === undefined)) {
    //     this.onSearch();
    //   }
    // }, [kind, branchId, carrierType, this.clickedIndex]);

    React.useEffect(() => {
      if (!isFirstLoading) {
        this.onSearch();
      }
    }, [kind, branchId, carrierType, this.clickedIndex, isFirstLoading]);

    return (
      <>
        {_sh()}
        {_r(MOVING_FROM_DATE)}
        <div>~</div>
        {_r(MOVING_TO_DATE)}
        {_r(MOVING_KIND)}
        {_r(MOVING_BRANCH)}
        {_r(MOVING_CARRIER_TYPE)}
        {_r(MOVING_BL_STATIUS)}
        {_r(MOVING_DEPARTURE)}
        {_r(MOVING_DESTINATION)}
        <div className={'flex mt-1'}>
          <span className={'mr-2'}>Flag</span>{_r(MOVING_FLAG)}
        </div>
      </>
    );
  };
}
