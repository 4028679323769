/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {api, util} from '../../services/service';
import {DefaultButton} from "../../components/buttons";
import {FD, FN, workTypeOptions} from "../field-defs/newCoship";
import {apiMoving, apiForwarding, apiCourier} from "../../services/newCoship/service";
import {useSimpleGrid} from "../../components/SimpleGrid";
import {useUser} from "../../redux/reducers/userReducer";
import {useSelector} from "react-redux";

const kindOptions = [
  {value: 'M', label: 'Moving'},
  {value: 'C', label: 'Courier'},
  {value: 'V', label: 'Vehicle'},
];

class HouseBLWorkOrdersFormManager extends FormManager {
  isShowForm;
  blId;
  grid;
  constructor(kind: 'M' | 'F' | 'C' | 'V' | 'U' | 'P' | 'E') {
    super({
      prefix: `house-work_orders-form`,
      fields: [
        FD.WO_ID, FD.BRANCH_ID, FD.WORK_TYPE, FD.WORK_DATE, FD.WORK_TIME, FD.WORK_CBM, FD.WORK_PKG, FD.WORK_LARGE, FD.WORK_MEDIUM, FD.WORK_SMALL,
        FD.WORK_AIR_CAP, FD.WORK_TAPE_COLOR, FD.WORK_TAPE_QTY, FD.WORK_REMARK, FD.KIND,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.kind = kind;
  };
  setData = (data) => {
    this.grid.setRows(data);
  };
  onRender = () => {
    const {renderField: _r, getField: _f, setValue: _c, getValue: _v} = this;
    const [gridMode, setGridMode] = React.useState('add');
    const {cbm, pkg} = useSelector(state => state['newCoship']);
    const isBoxDelivery = _v(FN.WORK_TYPE) === 'BD';
    const qs = util.getQS();
    const {id} = qs;
    const user = useUser();
    const branchOptions = api.useAreaOptions(true);
    const newApi = this.kind === 'M' ? apiMoving : (this.kind === 'F' ? apiForwarding : apiCourier);
    _f(FN.WORK_TYPE).options = workTypeOptions;
    _f(FN.WORK_TAPE_COLOR).options = newApi.useTapeOptions();
    _f(FN.BRANCH_ID).options = branchOptions;
    _f(FN.BRANCH_ID).label = '';
    _f(FN.KIND).label = '';
    _f(FN.KIND).options = kindOptions;

    const onAction = (action, data) => {
      if (action === 'delete') {
        onDelete(data).catch();
      } else if (action === 'edit') {
        const {wo_id, bl_no} = data;
        util.openTab(`/admin/schedule/workorder/set?id=${wo_id}&blNo=${bl_no}`);
      }
    };

    const onCellClicked = (e) => {
      _c(FN.WO_ID, e.data.wo_id);
      _c(FN.WORK_TYPE, e.data.work_type);
      _c(FN.WORK_DATE, e.data.work_date);
      _c(FN.WORK_TIME, e.data.work_time);
      _c(FN.WORK_CBM, e.data.cbm);
      _c(FN.WORK_PKG, e.data.package);
      _c(FN.WORK_LARGE, e.data.box_large);
      _c(FN.WORK_MEDIUM, e.data.box_medium);
      _c(FN.WORK_SMALL, e.data.box_small);
      _c(FN.WORK_AIR_CAP, e.data.air_cap);
      _c(FN.WORK_TAPE_COLOR, e.data.tape_color);
      _c(FN.WORK_TAPE_QTY, e.data.tape_qty);
      _c(FN.WORK_REMARK, e.data.remark);
      this.kind !== 'C' && _c(FN.KIND, e.data.kind);
      setGridMode('edit');
    };

    const grid = useWorkOrderGrid(onAction, onCellClicked, this.kind);
    this.grid = grid;

    const onSave = async () => {
      const values = this.getValues();
      if (!values.work_date) {
        return util.showWarning('Please selet work date!');
      }
      const payload = {
        bl_id: util.toInt(this.blId),
        work_order: {
          wo_id: values.wo_id ?? undefined,
          branch_id: values.branchId ? util.toInt(values.branchId) : undefined,
          kind: values.kind ?? undefined,
          work_type: values.work_type ?? undefined,
          work_date: values.work_date ?? undefined,
          work_time: values.work_time ?? undefined,
          cbm: values.cbm ?? undefined,
          package: values.package ? util.toInt(values.package) : undefined,
          box_large: values.box_large ? util.toInt(values.box_large) : undefined,
          box_medium: values.box_medium ? util.toInt(values.box_medium) : undefined,
          box_small: values.box_small ? util.toInt(values.box_small) : undefined,
          air_cap: values.air_cap ? util.toInt(values.air_cap) : undefined,
          tape_color: values.tape_color ?? undefined,
          tape_qty: values.tape_qty ? util.toInt(values.tape_qty) : undefined,
          package_type: 'PACKAGE(S)',
          remark: values.remark ?? undefined,
        },
      };
      await newApi.setWorkOrder(payload).then((res) => {
        if (res) {
          grid.setRows(res.data.work_order);
        }
      });
      onClear();
    };

    const onDelete = async (data) => {
      util.showConfirm('Are you sure to delete?', async () => {
        const woId = data.wo_id;
        if (woId) {
          await newApi.delWorkOrder({wo_id: woId}).then((res) => {
            if (res) {
              const rows = this.grid.rows ?? [];
              const newRows = rows.filter(x => x.wo_id !== woId);
              grid.setRows(newRows);
            }
          });
        }
        onClear();
      });
    };

    const onClear = () => {
      this.clearValues();
      setGridMode('add');
      _c(FN.WORK_TYPE, workTypeOptions[0].value);
      _c(FN.KIND, this.kind === 'C' ? this.kind : kindOptions[0].value);
      _c(FN.WORK_CBM, cbm);
      _c(FN.WORK_PKG, pkg);
    };

    React.useEffect(() => {
      _c(FN.WORK_TYPE, workTypeOptions[0].value);
      _c(FN.KIND, this.kind === 'C' ? this.kind : kindOptions[0].value);
    }, []);

    React.useEffect(() => {
      _c(FN.WORK_CBM, isBoxDelivery ? '' : cbm);
    }, [cbm]);

    React.useEffect(() => {
      _c(FN.WORK_PKG, isBoxDelivery ? '' : pkg);
    }, [pkg]);

    React.useEffect(() => {
      let branchId = _v(FN.BRANCH_ID);
      branchId = branchId ? util.toInt(branchId) : branchId;
      if(!branchId) {
        _c(FN.BRANCH_ID, user.branch_id);
      }
    }, [user.branch_id, _v(FN.BRANCH_ID)]);

    return (
      <div style={{width: 'calc(100% + 8px)'}}>
        {this.isShowForm &&
          <div className={'pl-8 pr-20 pt-20 pb-1'}>
            <div className={'flex w-full'}>
              <div className={'flex-1 ml-2 mr-4'}>
                <label>WORK ORDERS</label>
                <div>
                  {grid.render()}
                  <div className={'flex mt-1'}>
                    {!isBoxDelivery && <PickupDelivery _r={_r} kind={this.kind}/>}
                    {isBoxDelivery && <BoxDelivery _r={_r} kind={this.kind}/>}
                    <div>
                      <DefaultButton className={'mr-2'} label={'Clear'} onClick={onClear} noMargin/>
                      <DefaultButton label={gridMode === 'add' ? 'Add' : 'Edit'} onClick={onSave} disabled={!id} noMargin/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  };
  onValidate = (values) => {};
}

const PickupDelivery = ({_r, kind}) => {
  return (
    <>
      <div className={'mr-2'} style={{width: 130}}>{_r(FN.BRANCH_ID)}</div>
      {kind !== 'C' && <div className={'mr-2'} style={{width: 130}}>{_r(FN.KIND)}</div>}
      <div className={'mr-2'} style={{width: 130}}>{_r(FN.WORK_TYPE)}</div>
      <div className={'mr-2'} style={{width: 100}}>{_r(FN.WORK_DATE)}</div>
      <div className={'mr-2'} style={{width: 100}}>{_r(FN.WORK_TIME)}</div>
      <div className={'mr-2 flex'} style={{width: 100}}><span className={'mr-1'} style={{marginTop: 5}}>CBM</span>{_r(FN.WORK_CBM)}</div>
      <div className={'mr-2 flex'} style={{width: 100}}><span className={'mr-1'} style={{marginTop: 5}}>PKG</span>{_r(FN.WORK_PKG)}</div>
      <div className={'mr-2 flex-1'}>{_r(FN.WORK_REMARK)}</div>
    </>
  );
};

const BoxDelivery = ({_r, kind}) => {
  return (
    <>
      <div className={'mr-2'} style={{width: 130}}>{_r(FN.BRANCH_ID)}</div>
      {kind !== 'C' && <div className={'mr-2'} style={{width: 130}}>{_r(FN.KIND)}</div>}
      <div className={'mr-2'} style={{width: 130}}>{_r(FN.WORK_TYPE)}</div>
      <div className={'mr-2'} style={{width: 100}}>{_r(FN.WORK_DATE)}</div>
      <div className={'mr-2'} style={{width: 100}}>{_r(FN.WORK_TIME)}</div>
      <div className={'mr-2 flex'} style={{width: 70}}><span className={'mr-1'} style={{marginTop: 5}}>L</span>{_r(FN.WORK_LARGE)}</div>
      <div className={'mr-2 flex'} style={{width: 70}}><span className={'mr-1'} style={{marginTop: 5}}>M</span>{_r(FN.WORK_MEDIUM)}</div>
      <div className={'mr-2 flex'} style={{width: 70}}><span className={'mr-1'} style={{marginTop: 5}}>S</span>{_r(FN.WORK_SMALL)}</div>
      <div className={'mr-2 flex'} style={{width: 100}}><span className={'mr-1'} style={{marginTop: 5}}>A.CAP</span>{_r(FN.WORK_AIR_CAP)}</div>
      <div className={'mr-2 flex'} style={{width: 110}}><span className={'mr-1'} style={{marginTop: 5}}>Tape</span>{_r(FN.WORK_TAPE_COLOR)}</div>
      <div className={'mr-2'} style={{width: 50}}>{_r(FN.WORK_TAPE_QTY)}</div>
      <div className={'mr-2 flex-1'}>{_r(FN.WORK_REMARK)}</div>
    </>
  );
};

function useWorkOrderGrid(onAction, onCellClicked, kind) {
  const getColumns = () => {
    if (kind === 'C') {
      return [
        {field: 'wo_id', hide: true},
        {field: 'bl_no', hide: true},
        {field: 'branch_name', headerName: 'Branch', width: 100},
        {field: 'work_type', headerName: 'Work Type', width: 100},
        {field: 'work_date', headerName: 'Work Date', width: 100},
        {field: 'work_time', headerName: 'Work Time', width: 100},
        {field: 'cbm', headerName: 'Cbm', width: 70},
        {field: 'package', headerName: 'Pkg', width: 70},
        {field: 'box_large', headerName: 'Large', hide: true, width: 70},
        {field: 'box_medium', headerName: 'Medium', hide: true, width: 70},
        {field: 'box_small', headerName: 'Small', hide: true, width: 70},
        {field: 'air_cap', headerName: 'Air Cap', hide: true, width: 70},
        {field: 'tape_color', headerName: 'Tape', hide: true, width: 100},
        {field: 'tape_qty', headerName: 'Qty', hide: true, width: 70},
        {field: 'supplies', headerName: 'Supplies', flex: 1},
        {field: 'remark', headerName: 'Remark', flex: 1},
      ];
    } else {
      return [
        {field: 'wo_id', hide: true},
        {field: 'bl_no', hide: true},
        {field: 'branch_name', headerName: 'Branch', width: 100},
        {field: 'kind', headerName: 'Service', width: 100},
        {field: 'work_type', headerName: 'Work Type', width: 100},
        {field: 'work_date', headerName: 'Work Date', width: 100},
        {field: 'work_time', headerName: 'Work Time', width: 100},
        {field: 'cbm', headerName: 'Cbm', width: 70},
        {field: 'package', headerName: 'Pkg', width: 70},
        {field: 'box_large', headerName: 'Large', hide: true, width: 70},
        {field: 'box_medium', headerName: 'Medium', hide: true, width: 70},
        {field: 'box_small', headerName: 'Small', hide: true, width: 70},
        {field: 'air_cap', headerName: 'Air Cap', hide: true, width: 70},
        {field: 'tape_color', headerName: 'Tape', hide: true, width: 100},
        {field: 'tape_qty', headerName: 'Qty', hide: true, width: 70},
        {field: 'supplies', headerName: 'Supplies', flex: 1},
        {field: 'remark', headerName: 'Remark', flex: 1},
      ];
    }
  };

  return useSimpleGrid({
    columns: getColumns(),
    height: 182,
    className: 'pb-20',
    actions: ['edit', 'delete'],
    actionWidth: 80,
    onAction: onAction,
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
      onCellClicked(e) {
        if(e.colDef.headerName !== 'Actions') {
          onCellClicked(e);
        }
      }
    }
  });
}

export default HouseBLWorkOrdersFormManager;
