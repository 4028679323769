/**
 * @flow
 */
import React from "react";
import type {FormField} from '../components/Form';
import {ColDef} from 'ag-grid-community';
import {util} from '../services/service';
import {renderField} from '../components/Form';
import {CARGO_COURIER, CARGO_ECOMMERCE, CARGO_FORWARDING, CARGO_MOVING} from '../modal-tabs/types';

export interface SearchInfo {
  title: string;
  action: string;
  nameOfId: string;
}

export interface SearchData {
  [key: string]: SearchInfo;
}

export interface FormInfo {
  tab: string;
  label: string;
  fields: FormField[];
  render: (formik: any, fields: any, errors: any) => React$Node;
  formik: any;
  searchData: SearchData;
  columns: ColDef[];
  nameMap: Object;
  getField: (name: string) => FormField;
  clearFieldValues: () => void;
  setFieldValues: (data: any) => void;
  setFieldValue: (name: string, value: any) => void;
  getFieldValues: (validate?: boolean) => Object;
  getFieldValue: (name: string) => any;
}

export const currencyOptions = [
  {value: 'CAD', label: 'CAD'},
  {value: 'KRW', label: 'KRW'},
  {value: 'USD', label: 'USD'},
];

export const rateTypeOptions = [
  {value: 'BUY', label: 'BUY(보낼때)'},
  {value: 'SELL', label: 'SELL(받을때)'},
];

export const paymentOptions = [
  {value: 'COLLECT', label: 'COLLECT'},
  {value: 'CHEQUE', label: 'CHEQUE'},
  {value: 'E-TRANSFER/D.D', label: 'E-TRANSFER/D.D'},
  {value: 'INTERAC', label: 'INTERAC'},
  {value: 'CREDIT-VISA', label: 'CREDIT - VISA'},
  {value: 'CREDIT-MASTER', label: 'CREDIT - MASTER'},
  {value: 'CREDIT-OTHER', label: 'CREDIT - OTHER'},
];

export const incotermsOptions = [
  {value: '', label: ''},
  {value: 'CFR', label: 'CFR'},
  {value: 'CIF', label: 'CIF'},
  {value: 'CIP', label: 'CIP'},
  {value: 'CPT', label: 'CPT'},
  {value: 'DAP', label: 'DAP'},
  {value: 'DAT', label: 'DAT'},
  {value: 'DDP', label: 'DDP'},
  {value: 'EXW', label: 'EXW'},
  {value: 'FAS', label: 'FAS'},
  {value: 'FCA', label: 'FCA'},
  {value: 'FOB', label: 'FOB'},
];

export const accounttypeOptions = [
  {value: 'AR', label: 'AR'},
  {value: 'AP', label: 'AP'},
  {value: 'DC', label: 'DC'},
];

// 내부적으로만 사용되므로 소문자를 유지함!
export const movingTypeOptions = [
  {value: 'Moving', label: 'Moving'},
  {value: 'Forwarding', label: 'Forwarding'},
  {value: 'Courier', label: 'Courier'},
  {value: 'eCommerce', label: 'eCommerce'},
];

export const trackingTypeOptions = [
  {value: 'COSHIP', label: 'COSHIP'},
  {value: 'UPS', label: 'UPS'},
  {value: 'CANADA POST', label: 'CANADA POST'},
  {value: 'KOREA POST', label: 'KOREA POST'},
];

export const insuranceOptions = [
  {value: 'DTI', label: 'DOOR TO INSIDE'},
  {value: 'DTO', label: 'DOOR TO OUTSIDE'},
  {value: 'DTP', label: 'DOOR TO PORT'},
];

export const yesNoOptions = [
  {value: 'N', label: 'NO'},
  {value: 'Y', label: 'YES'},
];

export const paidOptions = [
  {value: 'ALL', label: 'ALL'},
  {value: 'UNPAID', label: 'UNPAID'},
  {value: 'EXPENSE', label: 'EXPENSE'},
  {value: 'PAID', label: 'PAID'},
];

export const oceanAirOptions = [
  {value: 'O', label: 'OCEAN'},
  {value: 'A', label: 'AIR'},
];

export const oceanAirOptionsForBLList = [
  {value: 'O', label: 'OCEAN'},
  {value: 'A', label: 'AIR'},
  {value: 'G', label: 'GROUND'},
];

function getWorkOptions(prefix) {
  return [
    {value: `${prefix}P`, label: 'PICKUP'},
    {value: `${prefix}D`, label: 'DELIVERY'},
    {value: `${prefix}R`, label: 'REQUEST PROPS.'},
  ];
}

export const movingWorkOptions = getWorkOptions('M');
export const courierWorkOptions = getWorkOptions('C');
export const forwardingWorkOptions = getWorkOptions('F');
export const eCommerceWorkOptions = getWorkOptions('E');

export const portTypeOptions = [
  {value: 'O', label: 'OCEAN'},
  {value: 'A', label: 'AIR'},
];

export const courierOptions = [
  {value: 'M', label: 'MAPLEBOX'},
  {value: 'A', label: 'AIR COURIER'},
  {value: 'U', label: 'UPS'},
  {value: 'C', label: 'CANADA POST'},
];

export const pickupTimeOptions = [
  {value: 'AM', label: 'AM'},
  {value: 'PM', label: 'PM'},
];

export const labelTypeOptions = [
  {value: 'LETTER', label: 'LETTER'},
  {value: 'LABEL1', label: '4 X 6'},
  {value: 'LABEL2', label: '우체국'},
  {value: 'LABEL3', label: 'CJ 대한통운'},
];

export const dropTypeOptions = [
  {value: 'Z', label: 'N/A'},
  {value: 'D', label: 'DROP OFF'},
  {value: 'P', label: 'REQUEST PICKUP'},
];

export const eCommerceOptions = [
  {value: 'B', label: 'BUY & DELIVERY'},
  {value: 'D', label: 'DELIVERY ONLY'},
];

export const cargoTypeOptions = [
  {value: CARGO_MOVING, label: CARGO_MOVING},
  {value: CARGO_COURIER, label: CARGO_COURIER},
  {value: CARGO_ECOMMERCE, label: CARGO_ECOMMERCE},
  {value: CARGO_FORWARDING, label: CARGO_FORWARDING},
];

export const workTimeOptions = [
  {value: 'ALL DAY', label: 'ALL DAY'},
  {value: 'AM', label: 'AM'},
  {value: 'PM', label: 'PM'},
];

export const partnerTypeOptions = [
  {value: 'BR', label: 'Branch'},
  {value: 'CS', label: 'Customer'},
  {value: 'VD', label: 'Vendor'},
  {value: 'DZ', label: 'Drop Zone'},
  {value: 'BC', label: 'Business Customer'},
  {value: 'OC', label: 'Ocean Carrier'},
  {value: 'AC', label: 'Air Carrier'},
  {value: 'FR', label: 'Forwarder'},
  {value: 'OP', label: 'Overseas Partner'},
  {value: 'CB', label: 'Customs Broker'},
  {value: 'TK', label: 'Trucker'},
  {value: 'RC', label: 'Rail Company'},
  {value: 'WH', label: 'Warehouse'},
  {value: 'BK', label: 'Bank'},
  {value: 'OT', label: 'Others'},
];

export const tradeTypeOptions = [
  {value: 'E', label: 'EXPORT'},
  {value: 'I', label: 'IMPORT'},
];

export const houseMasterOptions = [
  {value: 'H', label: 'HOUSE'},
  {value: 'M', label: 'MASTER'},
];

export const paidByOptions = [
  {value: 'EFT/ACH', label: 'EFT/ACH'},
  {value: 'E-TRANSFER', label: 'E-TRANSFER'},
  {value: 'CHEQUE', label: 'CHEQUE'},
  {value: 'WIRE TRANSFER', label: 'WIRE TRANSFER'},
  {value: 'COLLECT', label: 'COLLECT'},
  {value: 'CREDIT CARD', label: 'CREDIT CARD'},
];

export const teamMainOptions = [
  {value: 'N', label: 'ALL'},
  {value: 'M', label: 'MOVING'},
  {value: 'C', label: 'COURIER'},
  {value: 'F', label: 'FORWARDING'},
  {value: 'A', label: 'ACCOUNT'},
  {value: 'S', label: 'SALES'},
];

export const statusOptions = [
  {value: 0, label: '미확인'},
  {value: 1, label: '접수확인'},
  {value: 2, label: '픽업접수'},
  {value: 3, label: '입고확인'},
  {value: 4, label: '입금요청'},
  {value: 5, label: '수금완료'},
  {value: 6, label: '발송완료'},
  {value: 7, label: '접수취소'},
];

export const teamMain = {
  ALL: 'N',
  MOVING: 'M',
  COURIER: 'C',
  FORWARDING: 'F',
  ACCOUNT: 'A',
  SALES: 'S',
};

export const labelType = {
  LETTER: 'LETTER',
  LABEL1: 'LABEL1',
  LABEL2: 'LABEL2',
  LABEL3: 'LABEL3',
};

export const schedulePurposeOptions = [
  {value: 'DAYOFF', label: 'DAY OFF'},
  {value: 'SICK', label: 'CALL SICK'},
  {value: 'SHIFT', label: 'SHIFT'},
  {value: 'HALFDAYOFF', label: 'HALF DAY OFF'},
];

export const accTypeOptions = [
  {value: 'AR', label: 'AR'},
  {value: 'AP', label: 'AP'},
];

export const searchKeyOptions = [
  {value: 'BLNO', label: 'B/L No.'},
  {value: 'FINO', label: 'Filing No.'},
  {value: 'EMAIL', label: 'Email'},
  {value: 'PHONE', label: 'Phone'},
  // {value: 'WTYPE', label: 'W/O Type'},
  // {value: 'PRICE', label: 'Total Price'},
];

export function standaloneLabel(label, style) {
  return <div className={'form__form-group-label standalone'} style={style}>{label}</div>;
}

export function clearFieldValues(form: FormInfo) {
  for(const field of form.fields) {
    form.formik.handleChange({target: {name: field.name, value: ''}});
  }
}

export function setFieldValues(form: FormInfo, data: any) {
  for (const [resName, reqName] of form.nameMap) {
    const field = form.fields.find(i => i.name === `${form.tab}-${reqName}`);
    if (field === undefined) {
      process.env.NODE_ENV !== 'production' && console.log(`[FormInfo] setFieldValues ${form.label} field(sn: ${resName}, fn: ${reqName}) not found!`);
    } else {
      if (field.type === 'date') {
        form.formik.handleChange({target: {name: `${form.tab}-${reqName}`, value: util.formatDate(data[resName], 'YYYY-MM-DD')}});
      } else {
        form.formik.handleChange({target: {name: `${form.tab}-${reqName}`, value: data[resName]}});
      }
    }
  }
}

export function setFieldValue(form: FormInfo, name: string, value: any) {
  form.formik.handleChange({target: {name: `${form.tab}-${name}`, value}});
}

export function getFieldValues(form: FormInfo) {
  const values = {};
  for (const field of form.fields) {
    const name = field.name.replace(`${form.tab}-`, '');
    values[name] = form.formik.values[field.name];
  }
  return values;
}

export function getFieldValue(form: FormInfo, name: string) {
  return form.formik.values[`${form.tab}-${name}`];
}

export function getRenderFns(formik, fields, errors, prefix) {
  const _r = (name) => renderField(formik, `${prefix}-${name}`, fields, errors);
  const _f = (name) => fields.find(i => i.name === `${prefix}-${name}`);
  const _c = (name, value) => formik.handleChange({target: {name: `${prefix}-${name}`, value}});
  const _v = (name) => formik.values[`${prefix}-${name}`];
  const _n = (name) => `${prefix}-${name}`;
  return {_r, _f, _c, _v, _n};
}
