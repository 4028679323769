/**
 * @flow
 */
import React from 'react';
import {PrimaryButton} from "../../../components/buttons";
import useSettingAccountTypeListGridView from "../../../grids/newCoship/useSettingAccountTypelListGridView";
import {useSettingAccountTypeListModal} from "../../../modals/newCoship/useSettingAccountTypeListModal";
import {apiForwarding} from "../../../services/newCoship/service";
import SettingSearchFormManager from "../../../forms/newCoship/SettingSearchFormManager";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import {useTopbar} from "../../../redux/reducers/topbarReducer";

const searchForm = new SettingSearchFormManager('F');

const ForwardingSettingAccountTypeList = () => {
  useTopbar({label: 'Forwarding'}, {label: 'Preset(AR/AP)'});
  const [qryText, setQueryText] = React.useState(undefined);

  const onSearch = () => {
    const values = searchForm.getValues();
    const payload = {
      kind: values.kind ? values.kind : undefined,
      search_word: qryText,
    };
    apiForwarding.getARAPItemList(payload).then((res) => {
      if (res) {
        grid.setRows(res.data);
      }
    });
  };

  const {grid, accountTypeListModal} = useForwardingSettingAccountType(onSearch);

  const onAdd = () => {
    accountTypeListModal.open({id: 0});
  };

  const onEnter = async () => {
    await onSearch();
  };

  React.useEffect(() => {
    searchForm.onSearch = onSearch;
  }, []);

  return (
    <div>
      <div style={{display: 'flex'}}>
        <PrimaryButton label={'ADD'} onClick={onAdd}/>
        {searchForm.renderForm()}
        <div className={'inbox__emails-control-search flex ml-8'}>
          <input
            id={`account-type-search`}
            placeholder={'Search...'}
            value={qryText ?? ''}
            onChange={({target: {value}}) => setQueryText(value)}
            onKeyUp={({target: {value}, key}) => {
              if (key === 'Enter') {
                onEnter(value).catch();
              }
            }}
            style={{width: 200}}
          />
          <a href={'/#search'} className="inbox__emails-control-search-icon" onClick={(e) => {
            e.preventDefault();
            onEnter(document.querySelector('#account-type-search').value).catch();
          }}>
            <MagnifyIcon/>
          </a>
        </div>
      </div>
      <div style={{marginTop: -5}}>
        {grid.render()}
      </div>
      {accountTypeListModal.render()}
    </div>
  );
};

function useForwardingSettingAccountType(onSearch) {
  const onAction = (action, data) => {
    if (action === 'edit') {
      accountTypeListModal.open(data);
    }
  };
  const grid = useSettingAccountTypeListGridView(onAction);
  const accountTypeListModal = useSettingAccountTypeListModal(onSearch, 'F');
  return {grid, accountTypeListModal};
}

export default ForwardingSettingAccountTypeList;
