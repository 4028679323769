/**
 * @flow
 */
import React from 'react';
import DashboardRequestFormManager from '../../forms/dashboard/DashboardRequestFormManager';
import {useSimpleEditModal} from '../../components/SimpleModal';
import {apiDashboard, util} from '../../services/service';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import {useSimpleGrid} from '../../components/SimpleGrid';

const DashboardUpcoming = () => {
  useTopbar({label: 'Dashboard'}, {label: 'Upcoming'});
  // const requestModal = useRequestEditModal(onSave);
  // const requestModalRef = React.useRef();
  // requestModalRef.current = requestModal;
  // const requestGrid = useRequestGrid(requestModalRef);
  const flagGrid = useFlagGrid();
  const oemGrid = useMasterGrid();
  const aemGrid = useMasterGrid();
  const oimGrid = useMasterGrid();
  const aimGrid = useMasterGrid();
  const vendorGrid = useVendorGrid();
  function onMore(type) {
    switch (type) {
      case 'flag':
        util.nav('/admin/bl/list?hm=H');
        break;
      case 'request':
        util.nav('/admin/dashboard/request');
        break;
      case 'oem':
        util.nav('/admin/bl/list?type=O&bound=E&hm=M');
        break;
      case 'aem':
        util.nav('/admin/bl/list?type=A&bound=E&hm=M');
        break;
      case 'oim':
        util.nav('/admin/bl/list?type=O&bound=I&hm=M');
        break;
      case 'aim':
        util.nav('/admin/bl/list?type=A&bound=I&hm=M');
        break;
      default:
        break;
    }
  }
  // async function onSave () {
  //   const {id, response_status: responseStatus, remark} = requestForm.getValues();
  //   const res = await apiDashboard.requestSave({id, responseStatus, remark});
  //   if (res) {
  //     util.showSuccess('Request has been saved successfully!');
  //     requestModal.close();
  //     const {data} = await apiDashboard.getRequestSummary();
  //     requestGrid.setRows(data);
  //   }
  // }
  function renderTitle(title, type) {
    return (
      <div className={'flex between middle px-1'}>
        <p>{title}</p>
        {/*<DefaultButton label={'More...'} onClick={() => onMore(type)} noMargin />*/}
        {type !== 'vendor' && <a href={'more'} onClick={(e) => {e.preventDefault();onMore(type)}}>View more...</a>}
      </div>
    );
  }
  React.useEffect(() => {
    apiDashboard.getBLSummary().then(({data}) => {
      const {flag = [], oem = [], aem = [], oim = [], aim = [], vendor = []} = data?.[0] ?? {};
      flagGrid.setRows(flag);
      oemGrid.setRows(oem);
      aemGrid.setRows(aem);
      oimGrid.setRows(oim);
      aimGrid.setRows(aim);
      vendorGrid.setRows(vendor);
    });
    // apiDashboard.getRequestSummary().then(({data}) => {
    //   requestGrid.setRows(data);
    // });
  }, []);
  return (
    <div className={'flex-column'}>
      <div className={'flex'}>
        <div className={'flex-1 mr-16'}>
          {renderTitle('Flag List', 'flag')}
          {flagGrid.render()}
        </div>
        <div className={'flex-1'}>
          {renderTitle('Vendor List', 'vendor')}
          {vendorGrid.render()}
        </div>
      </div>
      <div className={'flex'}>
        <div className={'flex-1 mr-16'}>
          {renderTitle('Ocean Export', 'oem')}
          {oemGrid.render()}
        </div>
        <div className={'flex-1'}>
          {renderTitle('Air Export', 'aem')}
          {aemGrid.render()}
        </div>
      </div>
      <div className={'flex'}>
        <div className={'flex-1 mr-16'}>
          {renderTitle('Ocean Import', 'oim')}
          {oimGrid.render()}
        </div>
        <div className={'flex-1'}>
          {renderTitle('Air Import', 'aim')}
          {aimGrid.render()}
        </div>
      </div>
      {/*{requestModal.render()}*/}
    </div>
  );
};

// const requestForm = new DashboardRequestFormManager();

// const useRequestEditModal = (onSave) => {
//   return useSimpleEditModal({
//     title: 'REQUEST FORM',
//     centered: true,
//     width: 480,
//     form: requestForm,
//     onSave,
//   });
// };

const GRID_HEIGHT = 190;
const useFlagGrid = () => {
  return useSimpleGrid({
    columns: [
      {field: 'kind', headerName: 'Service', width: 80, minWidth: 80},
      {field: 'carrier_type', headerName: 'Type', width: 80, minWidth: 80},
      {field: 'bound', headerName: 'Bound', width: 80, minWidth: 80},
      {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: blHouseLinkRenderer, width: 80, minWidth: 80},
      {field: 'customer', headerName: 'Customer', width: 80, minWidth: 80},
      {field: 'etd', headerName: 'ETD', valueFormatter: util.dateFormatter, width: 80, minWidth: 80},
      {field: 'eta', headerName: 'ETA', valueFormatter: util.dateFormatter, width: 80, minWidth: 80},
      {field: 'status', headerName: 'Status', flex: 1, minWidth: 80},
    ],
    height: GRID_HEIGHT,
    className: 'mt-1 mb-2',
  });
};

// const useRequestGrid = (requestModalRef) => {
//   return useSimpleGrid({
//     columns: [
//       {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, width: 100},
//       {field: 'customer_name', headerName: 'Name', flex: 1},
//       {field: 'service_type', headerName: 'Type', flex: 1},
//       {field: 'phone', headerName: 'Phone', flex: 1},
//       {field: 'from_country', headerName: 'From', width: 100},
//       {field: 'to_country', headerName: 'To', width: 100},
//     ],
//     height: GRID_HEIGHT,
//     className: 'mt-1 mb-2',
//     actions: ['edit'],
//     actionWidth: 50,
//     onAction: (action, data) => {
//       if (action === 'edit') {
//         requestModalRef.current.open('edit', data);
//       }
//     },
//   });
// };

const useMasterGrid = () => {
  return useSimpleGrid({
    columns: [
      {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: blMasterLinkRenderer, flex: 1},
      {field: 'pol', headerName: 'POL', width: 100},
      {field: 'pod', headerName: 'POD', width: 100},
      {field: 'etd', headerName: 'ETD', valueFormatter: util.dateFormatter, width: 120},
      {field: 'eta', headerName: 'ETA', valueFormatter: util.dateFormatter, width: 120},
    ],
    height: GRID_HEIGHT,
    className: 'mt-1 mb-2',
  });
};

const useVendorGrid = () => {
  return useSimpleGrid({
    columns: [
      {field: 'udate', headerName: 'Date', valueFormatter: util.dateFormatter, width: 120},
      {field: 'eng_name', headerName: 'Name (Eng)', cellRendererFramework: blVendorLinkRenderer, flex: 1},
      {field: 'local_name', headerName: 'Name (Kor)', flex: 1},
      {field: 'bl_count', headerName: 'B/L Count', flex: 1},
    ],
    height: GRID_HEIGHT,
    className: 'mt-1 mb-2',
  });
};

function blMasterLinkRenderer(param) {
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        // util.nav(`/admin/bl/master/${param.data['bl_id']}`);
        // util.openTab(`/admin/bl/master/${param.data['bl_id']}`);
        util.openTab(`/admin/${param.data['kind_str']}/master/mbl?id=${param.data['bl_id']}`);
      }}>
        {param.value}
      </a>
    </div>
  );
}

function blHouseLinkRenderer(param) {
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        // util.nav(`/admin/bl/house/${param.data['bl_id']}`);
        // util.openTab(`/admin/bl/house/${param.data['bl_id']}`);
        util.openTab(`/admin/${param.data['kind_str']}/house/customer?id=${param.data['bl_id']}`);
      }}>
        {param.value}
      </a>
    </div>
  );
}

function blVendorLinkRenderer(param) {
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        // util.nav(`/admin/bl/list?partner_id=${param.data['partner_id']}&partner_name=${param.data['eng_name']}`);
        util.openTab(`/admin/bl/list?partner_id=${param.data['partner_id']}&partner_name=${param.data['eng_name']}`);
      }}>
        {param.value}
      </a>
    </div>
  );
}

export default DashboardUpcoming;
