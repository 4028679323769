/**
 * @flow
 */
import FormManager from '../../lib/FormManager';
import React from "react";
import {apiForwarding} from "../../services/newCoship/service";

class MasterCurrencyModalFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'currency',
      fields: [
        { name: 'usd_buy', serverName: 'usd_buy', field: 'usd_buy', headerName: 'USD(BUY_보낼때)', required: true, flex: 1},
        { name: 'usd_sell', serverName: 'usd_sell', field: 'usd_sell', headerName: 'USD(SELL_받을때)', required: true, flex: 1},
        { name: 'krw_buy', serverName: 'krw_buy', field: 'krw_buy', headerName: 'KRW(BUY_보낼때)', required: true, flex: 1},
        { name: 'krw_sell', serverName: 'krw_sell', field: 'krw_sell', headerName: 'KRW(SELL_받을때)', required: true, flex: 1},
        { name: 'remark', serverName: 'remark', field: 'remark', headerName: 'Remark', required: false, flex: 1},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.todayDate = "";
  }

  setTodayDate = (today) => {
    this.todayDate = today;
  }

  fetchTodayRate = async (_c) => {
      const res = await apiForwarding.getTodayCurrencyRate({'rate_date': this.todayDate});
      if(res) {
        _c('usd_buy', res.data?.USD?.BUY);
        _c('usd_sell', res.data?.USD?.SELL);
        _c('krw_buy', res.data?.KRW?.BUY);
        _c('krw_sell', res.data?.KRW?.SELL);
        _c('remark', res.data?.remark);
      }
  }

  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    React.useEffect(() => {
      this.fetchTodayRate(_c).catch();
    }, []);
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('usd_buy')}</div>
          <div className={'flex-1 mr-2'}>{_r('usd_sell')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('krw_buy')}</div>
          <div className={'flex-1 mr-2'}>{_r('krw_sell')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1'}>{_r('remark')}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => values;
}

export default MasterCurrencyModalFormManager;
