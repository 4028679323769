/**
 * @flow
 */
import {useDataGridView} from '../../../components/DataGridView';
import {util} from '../../../services/service';
import {apiForwarding} from "../../../services/newCoship/service";
import React from "react";

export default function useForwardingQuoteGridView(onActionCb) {
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      return apiForwarding.getQuotationList(gridInfo);
    },
    name: 'forwardingQuote',
    label: 'forwarding',
    addLabel: '',
    editLabel: 'EDIT QUOTE',
    categoryLabel: {label: 'Forwarding'},
    menuLabel: {label: 'Quote'},
    sortCol: '',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit', 'blMake'],
    actionWidth: 80,
    showDateSearch: false,
    showRowSearch: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    columns: [
      {field: 'qt_no', headerName: 'No.', flex: 1},
      {field: 'qt_date', headerName: 'Date', flex: 1},
      {field: 'bl_no', headerName: 'BL#', cellRendererFramework: blLinkFormatter, flex: 1},
      {field: 'partner_name', headerName: 'Customer', flex: 1},
      {field: 'valid_until', headerName: 'Valid Until', flex: 1},
      {field: 'currency', headerName: 'Currency', flex: 1},
      {field: 'amount', headerName: 'Total Amt.', flex: 1},
    ],
  });
};

export function blLinkFormatter(param) {
  const onClick = (e) => {
    e.preventDefault();
    util.nav(`/admin/forwarding/house/customer?id=${param.data.id}`);
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={onClick}>
      {param.value}
    </a>
  );
}
