/**
 * @flow
 */
import React from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import {useUser} from '../../../redux/reducers/userReducer';
import {useNoti} from '../../../redux/reducers/notiReducer';
import {util} from '../../../services/service';
import {cargoTypeOptions} from '../../../forms/helper';
import MagnifyIcon from "mdi-react/MagnifyIcon";

const TopbarProfile = () => {
  const [collapse, setCollapse] = React.useState<boolean>(false);
  const user = useUser();
  // const noti = useNoti();
  const [cargoType, setCargoType] = React.useState();
  const onSelectNew = ({target: {value}}) => {
    if (value === 'Moving') {
      window.open('/admin/bl/house?kind=M', '_blank');
    } else if (value === 'Courier') {
      window.open('/admin/bl/house?kind=C', '_blank');
    }
    setCargoType('');
  };
  const onSearch = (e, mode) => {
    let searchValue = '';
    if (mode === 'enter') {
      const {target: {value}, key} = e;
      if (key === 'Enter') {
        searchValue = value;
      }
    } else {
      e.preventDefault();
      const el = document.querySelector('#header-search');
      const {value} = el || undefined;
      searchValue = value;
    }
    searchValue && util.openTab(`/admin/partner/list?search=${searchValue}`);

  };
  return (
    <>
      <div className={'form mr-12'}>
        <div className={'form__form-group flex middle'} style={{marginBottom: 0}}>
          <select value={cargoType} onChange={onSelectNew}>
            <option value={''}>New HB/L ...</option>
            <option value={'Courier'}>Courier</option>
            <option value={'Moving'}>Moving</option>
          </select>
        </div>
      </div>
      <div className={'form mr-12'}>
        <div className={'form__form-group flex middle'} style={{marginBottom: 0}}>
          <input id={'header-search'} onKeyUp={(e) => onSearch(e, 'enter')}/>
          <a href={'/#search'} onClick={(e) => onSearch(e, 'click')} className={'inbox-search-icon'}><MagnifyIcon/></a>
        </div>
      </div>
      {/*<button className="topbar__btn topbar__btn--mail topbar__btn--new" type="button" onClick={() => util.history.push('/admin/dashboard/message')}>*/}
      {/*  <NotificationsIcon />*/}
      {/*  {noti.count > 0 && (*/}
      {/*    <div className="topbar__btn-new-label">*/}
      {/*      <div />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</button>*/}
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={() => setCollapse(!collapse)}>
          {/*<img className="topbar__avatar-img" src={Ava} alt="avatar" />*/}
          <p className="topbar__avatar-name" style={{marginTop: 'auto', marginBottom: 'auto'}}>{user.uname}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={() => setCollapse(!collapse)} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/*<TopbarMenuLink title="Page one" icon="list" path="/pages/one" />*/}
            {/*<TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" />*/}
            {/*<div className="topbar__menu-divider" />*/}
            <TopbarMenuLink title="Log Out" icon="exit" path="/admin/login" />
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default TopbarProfile;
