/**
 * @flow
 */
import React from 'react';
import FormManager from "../../../lib/FormManager";
import {api, apiAccount, util} from '../../../services/service';
import {AccountTypeSearchInput, TradePartnerSearchInput} from '../../../components/SearchInput';
import {useSimpleGrid} from '../../../components/SimpleGrid';
import {DefaultButton} from '../../../components/buttons';
import {apiForwarding} from "../../../services/newCoship/service";
import {ColDef} from "ag-grid-community";
import {FD, FN, priorityOptions, unitOptions} from "../../field-defs/newCoship";
import {currencyOptions} from "../../helper";
import {useUser} from "../../../redux/reducers/userReducer";

class ForwardingQuoteFormManager extends FormManager {
  id;
  data;
  items;
  grid;
  totalAmount = 0;
  constructor() {
    super({
      prefix: `forwarding-quote-add-item-form`,
      fields: [
        FD.PARTNER_ID, {...FD.PARTNER_NAME, required: true}, FD.PRESET, FD.BANK_INFO,
        FD.CURRENCY, FD.RATE_CURRENCY1, FD.RATE_VALUE1, FD.RATE_CURRENCY2, FD.RATE_VALUE2, FD.UNIT, FD.RATE, FD.VOLUME, FD.REMARK, FD.MEMO,
        {name: 'branch_id', serverName: 'branch_id', label: 'Branch', noDefOption: true, smallMargin: true},
        {name: 'qt_date', serverName: 'qt_date', label: 'Date', type: 'date', mask: util.MASK_DATE, required: true, smallMargin: true},
        {name: 'valid_until', serverName: 'valid_until', label: 'Valid Until', type: 'date', mask: util.MASK_DATE, required: true, smallMargin: true},
        {name: 'term', serverName: 'term', label: 'Term', smallMargin: true},
        {name: 'pol', serverName: 'pol', label: 'P.O.L', smallMargin: true},
        {name: 'pod', serverName: 'pod', label: 'P.O.D', smallMargin: true},
        {name: 'volume_cbm', serverName: 'volume_cbm', label: 'Volume', smallMargin: true},
        {name: 'destination', serverName: 'destination', label: 'Destination', smallMargin: true},
        {name: 'priority', serverName: 'priority', label: 'Priority', options: priorityOptions, defaultValue: priorityOptions[1].value, noDefOption: true, smallMargin: true},
        {name: 'atype_id', serverName: 'atype_id', label: '', smallMargin: true},
        {name: 'description', serverName: 'description', label: 'Account Type', smallMargin: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  setData(data) {
    this.id = data.id;
    this.data = data;
  };

  getItems() {
    return this.items;
  };

  onRender = () => {
    const {_r, _f, _v, _n, _c, formik, fields, errors} = this.getRenderProps();
    const [gridMode, setGridMode] = React.useState('add');
    const [isScrollToBottom, setIsScrollToBottom] = React.useState(false);
    const [selectedRowId, setSelectedRowId] = React.useState(0);
    const [currency, setCurrency] = React.useState('');
    const [branchId, setBranchId] = React.useState();
    const [preset, setPreset] = React.useState([]);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const user = useUser();
    const bankInfo = apiAccount.useBranchBankName();
    const priority = _v('priority');
    bankInfo.shift();
    _f('branch_id').options = api.useAreaOptions(true);
    _f(FN.CURRENCY).options = currencyOptions;
    _f(FN.CURRENCY).label = 'Main Currency';
    _f(FN.PARTNER_NAME).label = 'Customer';
    _f(FN.BANK_INFO).options = bankInfo;
    _f(FN.REMARK).type = 'text';
    _f(FN.MEMO).type = 'text';
    _f(FN.PRESET).options = preset;
    _f(FN.PRESET).onChange = (_, value) => onChangePreset(value);
    _f(FN.PARTNER_NAME).onChange = (_, value) => onChangePartner(value);

    const onChangePreset = (value) => {
      if (value) {
        apiForwarding.getPresetItems({id: util.toInt(value)}).then((res) => {
          if (res) {
            this.clearValues();
            _c('branch_id', res.data.branch_id);
            _c(FN.CURRENCY, res.data.main_currency);
            _c(FN.PRESET, value);
            onSeearchCalc(res.data.items);
          }
        });
      } else {
        onReset();
      }
    };

    const onChangePartner = (value) => {
      _c(FN.PARTNER_ID, 0);
      _c(FN.PARTNER_NAME, value);
    };

    const onSeearchCalc = (items) => {
      let newRows = util.addRowIdToList(items);
      newRows = newRows?.map((data) => {
        const amount = data.rate && data.volume && data.cur_rate ? data.rate * data.volume * data.cur_rate : 0;
        return {...data, amount};
      });
      this.items = newRows;
      grid.setRows(newRows);
      getTotalAmount(newRows);
    };

    const onAction = (action, data) => {
      if (action === 'delete') {
        onDelete(data);
      }
    };

    const onCellClicked = (e) => {
      _c(FN.ATYPE_ID, e.data.atype_id);
      _c(FN.DESCRIPTION, e.data.description);
      _c(FN.UNIT, e.data.unit);
      _c(FN.RATE, e.data.rate);
      _c(FN.VOLUME, e.data.volume);
      _c(FN.REMARK, e.data.remark);
      setGridMode('edit');
      setSelectedRowId(e.data.rowId);
      setCurrency(e.data.currency);
    };

    const grid = useItemGrid(onAction, onCellClicked, isScrollToBottom);
    this.grid = grid;

    const _at = (name) => <AccountTypeSearchInput
      idField={_n(`atype_id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} vertical={true}
      onOK={(data) => {
        const {id, value, currency} = data;
        this.setValue('atype_id', id);
        this.setValue('description', value);
        setCurrency(currency);
      }}
    />;

    const _tp = (name) => {
      return (
        <TradePartnerSearchInput
          idField={_n(`${name}_id`)} displayField={_n(`${name}_name`)}
          formik={formik} fields={fields} errors={errors} vertical={true}
        />
      );
    };

    const onSearch = () => {
      if (this.id) {
        apiForwarding.getQuotationItems({id: this.id}).then((res) => {
          if (res) {
            onSeearchCalc(res.data);
          }
        });
      }
    };

    const onPreset = () => {
      const payload = {
        acc_type: 'QT',
        branch_id: branchId,
        bound: undefined,
        carrier_type: undefined,
      };
      apiForwarding.getPresetList(payload).then((res) => {
        if (res) {
          const data = res.data.map(x => ({label: x.subject, value: x.id}));
          setPreset(data);
        }
      });
    };

    const onCalculate = (mode) => {
      const values = this.getValues();
      const mainCurrency = values.currency;
      const rateCurrency1 = values.rate_currency1;
      const rateCurrency2 = values.rate_currency2;
      const rateValue1 = values.rate_value1 ? util.toFloat(values.rate_value1) : 0;
      const rateValue2 = values.rate_value2 ? util.toFloat(values.rate_value2) : 0;
      const rows = this.grid.rows ?? [];
      let newRows;
      if (mode === 'onAdd') {
        const selectedRow = {
          atype_id: values.atype_id,
          description: values.description,
          inv_id: values.inv_id,
          job_id: values.job_id,
          unit: values.unit,
          rowId: values.rowId,
          rate: values.rate ? util.toFloat(values.rate) : 1,
          volume: values.volume ? util.toFloat(values.volume) : 1,
          currency,
          remark: values.remark,
        };
        const calculatedData = getCalculatedRow({data: selectedRow, mainCurrency, rateCurrency1, rateValue1, rateCurrency2, rateValue2});
        if (gridMode === 'add') {
          newRows = [...rows, calculatedData];
        } else {
          newRows = rows?.map((data) => {
            if (selectedRowId === data.rowId) {
              return calculatedData;
            } else {
              return data;
            }
          });
        }
      } else {
        newRows = rows?.map((data) => getCalculatedRow({data, mainCurrency, rateCurrency1, rateValue1, rateCurrency2, rateValue2}));
      }
      newRows = util.addRowIdToList(newRows);
      this.items = newRows;
      grid.setRows(newRows);
      getTotalAmount(newRows);
    };

    const getCalculatedRow = (dataInfo) => {
      const {data, mainCurrency, rateCurrency1, rateValue1, rateCurrency2, rateValue2} = dataInfo;
      const price = data.rate && data.volume ? data.rate * data.volume : 0;
      let amount = price;
      if (data.currency === mainCurrency) {
        return {...data, cur_rate: 1, amount};
      } else if (data.currency === rateCurrency1) {
        const curRate = rateValue1 ? rateValue1 : 1;
        if (rateCurrency1 === 'KRW') {
          amount = price / curRate;
        } else {
          amount = price * curRate;
        }
        return {...data, cur_rate: curRate, amount};
      } else if (data.currency === rateCurrency2) {
        const curRate = rateValue2 ? rateValue2 : 1;
        if (rateCurrency2 === 'KRW') {
          amount = price / curRate;
        } else {
          amount = price * curRate;
        }
        return {...data, cur_rate: curRate, amount};
      } else {
        return {...data, cur_rate: '', amount: 0};
      }
    };

    const onAdd = () => {
      const values = this.getValues();
      if (!values.atype_id) {
        return util.showWarning('Please selet account type!');
      }
      onCalculate('onAdd');
      onClear();
    };

    const onDelete = (data) => {
      util.showConfirm('Are you sure to delete?', async () => {
        const rows = [];
        if (grid.apiRef && grid.apiRef.current) {
          grid.apiRef.current.forEachNode(node => rows.push(node.data));
        }
        const newRows = rows.filter(x => x.rowId !== data.rowId);
        grid.setRows(newRows);
        this.items = newRows;
        onClear();
      });
    };

    const onCancelEdit = () => {
      setGridMode('add');
      setSelectedRowId(0);
      this.clearValues();
    };

    const onReset = () => {
      this.clearValues();
      this.items = [];
      grid.setRows([]);
      _c('branch_id', user.branch_id);
      setBranchId(user.branch_id);
    };

    const onClear = () => {
      _c('atype_id', undefined);
      _c('description', undefined);
      _c(FN.UNIT, unitOptions[0].value);
      _c(FN.RATE, undefined);
      _c(FN.VOLUME, undefined);
      _c(FN.REMARK, undefined);
      setGridMode('add');
      setSelectedRowId(0);
    };

    const getTotalAmount = (rows) => {
      const total = util.arraySum(rows?.map((data) => data.amount));
      setTotalAmount(total);
      this.totalAmount = total;
    };

    _f(FN.RATE_VALUE1).onEnter = onCalculate;
    _f(FN.RATE_VALUE2).onEnter = onCalculate;

    React.useEffect(() => {
      if (this.id) {
        this.setValues(this.data);
        if (this.data?.currency_rates?.length === 1) {
          this.setValue('rate_currency1', this.data.currency_rates[0].currency);
          this.setValue('rate_value1', this.data.currency_rates[0].cur_rate);
        }
        if (this.data?.currency_rates?.length === 2) {
          this.setValue('rate_currency1', this.data.currency_rates[0].currency);
          this.setValue('rate_value1', this.data.currency_rates[0].cur_rate);
          this.setValue('rate_currency2', this.data.currency_rates[1].currency);
          this.setValue('rate_value2', this.data.currency_rates[1].cur_rate);
        }
      }
      this.totalAmount = 0;
      setTotalAmount(0);
      _c(FN.UNIT, unitOptions[0].value);
      onSearch();
    }, []);

    React.useEffect(() => {
      if (!this.id) {
        if (!branchId) {
          _c('branch_id', user.branch_id);
          setBranchId(user.branch_id);
        } else {
          onPreset();
        }
      }
    }, [branchId]);

    React.useEffect(() => {
      if (!this.id) {
        if (!priority) {
          _c('priority', priorityOptions[1].value);
        }
      }
    }, [priority]);

    React.useEffect(() => {
      if (!this.id) {
        if (!currency) {
          _c(FN.CURRENCY, currencyOptions[0].value);
        }
      }
    }, [currency]);

    return (
      <div className={'w-full'}>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex flex-1 w-full mr-2'}>
            <div className={'flex-1'}>{_r(FN.PRESET)}</div>
            <div className={'flex-1'}>{_tp('partner')}</div>
            <div className={'flex-1'}>{_r('branch_id')}</div>
          </div>
        </div>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex flex-1 w-full mr-2'}>
            <div className={'flex-1'}>{_r('qt_date')}</div>
            <div className={'flex-1'}>{_r('valid_until')}</div>
            <div className={'flex-1'}>{_r('term')}</div>
          </div>
        </div>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex flex-1 w-full mr-2'}>
            <div className={'flex-1'}>{_r('pol')}</div>
            <div className={'flex-1'}>{_r('pod')}</div>
            <div className={'flex-1'}>{_r('volume_cbm')}</div>
          </div>
        </div>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex flex-1 w-full mr-2'}>
            <div className={'flex-1'}>{_r('destination')}</div>
            <div className={'flex-1'}>{_r(FN.BANK_INFO)}</div>
            <div className={'flex-1'}>{_r('priority')}</div>
          </div>
        </div>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex-1'}>{_r(FN.CURRENCY)}</div>
          <div className={'flex flex-1'}>
            <div className={'flex-1 mr-2'}>{_r(FN.RATE_CURRENCY1)}</div>
            <div className={'flex-1'}>{_r(FN.RATE_VALUE1)}</div>
          </div>
          <div className={'flex flex-1'}>
            <div className={'flex-1 mr-2'}>{_r(FN.RATE_CURRENCY2)}</div>
            <div className={'flex-1'}>{_r(FN.RATE_VALUE2)}</div>
          </div>
        </div>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex flex-1 w-full'}>
            <div className={'flex-1'}>{_r(FN.MEMO)}</div>
          </div>
        </div>
        <div className={'mt-2'} style={{textAlign: 'right', fontWeight: 'bold'}}>Total: {util.formatCurrency(totalAmount)}</div>
        {grid.render()}
        <div className={'flex w-full'}>
          <div className={'flex-2'}>{_at('description')}</div>
          <div className={'flex-1'}>{_r(FN.UNIT)}</div>
          <div className={'flex-1'}>{_r(FN.RATE)}</div>
          <div className={'flex-1'}>{_r(FN.VOLUME)}</div>
          <div className={'flex-1'}>{_r(FN.REMARK)}</div>
          {gridMode === 'add' ?
            <div className={'ml-20'}><DefaultButton label={'Add'} onClick={onAdd} noMargin /></div> :
            (
              <div className={'ml-20'}>
                <DefaultButton label={'Edit'} onClick={onAdd} />
                <DefaultButton label={'Cancel'} onClick={onCancelEdit} />
              </div>
            )
          }
        </div>
      </div>
    );
  };

  onValidate = (values) => {return values};
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

function useItemGrid(onAction, onCellClicked, isScrollToBottom) {
  function getColumns(): ColDef[] {
    return [
      {field: 'description', headerName: 'Account Type', rowDrag: true, flex: 2},
      {field: 'currency', headerName: 'Currency', flex: 1},
      {field: 'unit', headerName: 'Unit', flex: 1},
      {field: 'rate', headerName: 'Rate', valueFormatter: util.currencyFormatterEx, flex: 1},
      {field: 'volume', headerName: 'Volume', valueFormatter: util.currencyFormatterEx, flex: 1},
      {field: 'cur_rate', headerName: 'CUR.Rate', valueFormatter: util.currencyFormatterEx, flex: 1},
      {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatterEx, flex: 1},
      {field: 'remark', headerName: 'Remark', flex: 1},
    ];
  }
  const grid = useSimpleGrid({
    columns: getColumns(),
    height: 300,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    onAction: onAction,
    isScrollToBottom,
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
      onCellClicked(e) {
        if(e.colDef.headerName !== 'Actions') {
          onCellClicked(e);
        }
      },
      onRowDragEnd(e) {
        const rows = grid.rows;
        const id = e.node.data['rowId'];
        const newIndex = e.node.rowIndex;
        const sourceIndex = rows.findIndex(i => i.rowId === id);
        const sourceRow = rows[sourceIndex];
        rows.splice(sourceIndex, 1);
        rows.splice(newIndex, 0, sourceRow);
        grid.setRows(rows);
      },
    },
  });
  return grid;
}

export default ForwardingQuoteFormManager;
