/**
 * @flow
 */
import {useDataGridView} from '../../../components/DataGridView';
import {util} from '../../../services/service';
import {apiForwarding} from "../../../services/newCoship/service";
import React from "react";

export default function useForwardingMasterBLListGridView(onActionCb) {
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      return apiForwarding.getMBlList(gridInfo);
    },
    name: 'forwardingMasterBLList',
    label: 'forwarding',
    addLabel: '',
    editLabel: 'EDIT MASTER BL',
    categoryLabel: {label: 'Forwarding'},
    menuLabel: {label: 'BL List'},
    sortCol: '',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit'],
    actionWidth: 70,
    showDateSearch: false,
    showRowSearch: true,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    doNotPerformInitialQuery: true,
    columns: [
      {field: 'shipper', headerName: 'Shipper', flex: 1},
      {field: 'consignee', headerName: 'Consignee', flex: 1},
      {field: 'bl_no', headerName: 'BL#', cellRendererFramework: blLinkFormatter, flex: 1},
      {field: 'carrier_type', headerName: 'Carrier', minWidth: 60, width: 60},
      {field: 'cdate', headerName: 'C.Date', valueFormatter: util.dateTimeFormatter, flex: 1},
      {field: 'pol', headerName: 'POL', flex: 1},
      {field: 'pod', headerName: 'POD', flex: 1},
      {field: 'etd', headerName: 'ETD', flex: 1},
      {field: 'eta', headerName: 'ETA', flex: 1},
      {field: 'bl_status', headerName: 'Status', flex: 1},
    ],
  });
};

export function blLinkFormatter(param) {
  const onClick = (e) => {
    e.preventDefault();
    util.openTab(`/admin/forwarding/master/mbl?id=${param.data.id}`);
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={onClick}>
      {param.value}
    </a>
  );
}
