/**
 * @flow
 */
import React from 'react';
import {useUser} from "../../redux/reducers/userReducer";
import {apiAccount, util} from "../../services/service";
import {useSimpleGridModal} from "../../components/SimpleModal";
import {RowClickedEvent} from "ag-grid-community";
import AccountPaymentFormManager from "../../forms/account/AccountPaymentFormManager";
import {teamMain} from "../../forms/helper";

const accountPaymentForm = new AccountPaymentFormManager();

export default function useAccountPaymentModal(onSearch = () => {}) {
  const [accountId, setAccountId] = React.useState();
  const [invoiceNo, setInvoiceNo] = React.useState();
  const user = useUser();
  const [mode, setMode] = React.useState();
  const blNoRef = React.useRef();
  const readOnlyRef = React.useRef();

  async function onQuery(data) {
    const res = await apiAccount.payHistoryGet(data);
    accountPaymentForm.setData(res.data);
    modal.setRows(res.data);
  }

  async function onSave() {
    // if(!util.isValidPermitForAccount(user, 'edit')) {
    //   return util.showError('Sorry, you can not edit Account Info.');
    // }
    // populate newly added data to grid view
    let {fullPay, jobId, payDate: pay_date = util.getCurrentDate(), currency = 'CAD', rate, payAmount: pay_amount, payType: pay_type = 'CHEQUE', remark, bankInfo, autoEmail} = accountPaymentForm.getValues();
    let isFullPay = false;
    if (fullPay !== undefined && fullPay[0] === 'on') isFullPay = true;
    let isAutoEmail = false;
    if ((autoEmail !== undefined && autoEmail[0] === 'on') || autoEmail) {
      isAutoEmail = true;
    }

    if(!rate || isNaN(rate)) {
      util.showWarning('Please enter a valid Rate data!');
      return;
    }
    if(!pay_amount || isNaN(pay_amount)) {
      util.showWarning('Please enter a valid Amount data!');
      return;
    }
    if(!bankInfo) {
      util.showWarning('Please select Bank Info!');
      return;
    }
    // save data to db
    const params = {isFullPay, accountId: accountId, jobId, payDate: util.toTS(pay_date), currency, rate, payAmount: pay_amount, payType: pay_type, remark, bankInfo, autoEmail: isAutoEmail};
    let res = await apiAccount.payHistorySave(params);
    if (res) {
      // await onQuery({accountId});
      onSearch();
      accountPaymentForm.clearValues();
      modal.close();
    }
  }

  async function onDelete(data) {
    const res = await apiAccount.payHistoryDel(accountId, data.job_id);
    if (res) {
      exitEditMode();
      await onQuery({accountId});
    }
  }

  function getButtons() {
    if (readOnlyRef.current === true) { // 읽기 전용 모드인경우 버튼을 숨김
      return [];
    }
    if (mode === 'edit') {
      return [
        {label: 'Edit', color: 'primary', onClick: onSave},
        {label: 'Clear', onClick: () => exitEditMode()}
      ];
    } else {
      return [
        {label: 'Add', color: 'primary', onClick: onSave},
      ];
    }
  }

  function enterEditMode(data) {
    setMode('edit');
    const {job_id, currency, rate, pay_amount, pay_date, pay_type, remark, bank_info} = data;
    const autoEmail = true;
    accountPaymentForm.setValues({job_id, currency, rate, pay_amount, pay_date, pay_type, remark, bank_info, autoEmail});
  }

  function exitEditMode() {
    setMode('add');
    accountPaymentForm.clearValues();
  }

  function getTitle() {
    if (invoiceNo) {
      return `PAYMENT HISTORY - Invoice No. [ ${invoiceNo} ]`;
    } else if (blNoRef.current) {
      return `PAYMENT HISTORY - B/L No. [ ${blNoRef.current} ]`;
    }
  }

  const modal = useSimpleGridModal({
    title: getTitle(),
    centered: true,
    width: 1100,
    buttons: getButtons(),
    gridProps: {
      columns: [
        {field: 'invoice_id', hide: true},
        {field: 'pay_date', headerName: 'Pay Date', valueFormatter: util.dateFormatter, width: 120},
        {field: 'currency', headerName: 'Currency', width: 100},
        {field: 'rate', headerName: 'Rate', valueFormatter: util.twoDecimalPlaceFormatter, width: 100},
        {field: 'pay_amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, width: 120},
        {field: 'pay_type', headerName: 'Type', width: 120},
        {field: 'remark', headerName: 'Remark', flex: 1},
        {field: 'bank_info', headerName: 'Bank Info.', flex: 1},
        {field: 'created_by', headerName: 'Created by', flex: 1},
        {field: 'edited_by', headerName: 'Edited by', flex: 1},
        {field: 'cdate', headerName: 'Created', valueFormatter: util.dateFormatter, flex: 1},
        {field: 'udate', headerName: 'Updated', valueFormatter: util.dateFormatter, flex: 1},
      ],
      actions: readOnlyRef.current === true ? [] : ['edit', 'delete'], // 읽기 전용 모드인 경우 액션 컬럼 숨김
      actionWidth: 80,
      onAction: (action, data) => {
        if (action === 'edit') {
          enterEditMode(data);
        } else if (action === 'delete') {
          if(!util.isValidPermitForAccount(user, 'edit')) {
            return util.showError('Sorry, you can not edit Account Info.');
          }
          util.showConfirm('Are you sure to delete?', () => onDelete(data));
        }
      },
      rows: [],
      height: 250,
      className: 'mb-20',
      agGridProps: {
        onRowClicked(e: RowClickedEvent) {
          if (readOnlyRef.current !== true) { // 읽기 전용 모드인 경우 클릭이벤트 무시
            enterEditMode(e.data);
          }
        }
      }
    },
    children: readOnlyRef.current === true ? null : accountPaymentForm.renderForm(), // 읽기 전용 모드인 경우 폼 런더링하지 않음
  });

  React.useEffect(() => {
    if (modal.isOpen === true) {
      if (accountId) {
        onQuery({accountId}).catch();
      } else if (blNoRef.current) {
        onQuery({blNo: blNoRef.current}).catch(); // B/L No 로 검색
      }
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (accountId, invNo, blNo = undefined, readOnly = false) => {
      setInvoiceNo(invNo);
      setAccountId(accountId);
      blNoRef.current = blNo;
      readOnlyRef.current = readOnly;
      modal.open();
    },
  };
}
