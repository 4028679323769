/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {api, apiBL, util} from '../../../services/service';
import {FD, FN} from '../../field-defs/newCoship';
import {setBound, setBranch, setCarrierType, setKind, setBlStatus} from "../../../redux/reducers/newCoshipReducer";
import {useUser} from "../../../redux/reducers/userReducer";
import {useSelector} from "react-redux";

class MovingHouseBLFormManager extends FormManager {
  constructor() {
    super({
      prefix: `moving-house-form`,
      fields: [
        FD.ID, FD.HBL_NO, FD.HAWB_NO, FD.BRANCH_ID, FD.TRADE_TYPE, FD.STAFF, FD.MBL_NO, FD.MAWB_NO, FD.PORT_TYPE,
        FD.KIND, FD.DEPARTURE_ORIGIN, FD.DESTINATION_FINAL, FD.BL_STATUS,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const carrierType = _v(FN.PORT_TYPE);
    const user = useUser();
    const {filingNo} = useSelector(state => state['newCoship']);
    const branchOptions = api.useAreaOptions(true);
    _f(FN.BRANCH_ID).options = branchOptions;
    _f(FN.KIND).onChange = (_, value) => onChangeKind(value);
    _f(FN.TRADE_TYPE).onChange = (_, value) => onChangeBound(value);
    _f(FN.BL_STATUS).onChange = (_, value) => onChangeBlStatus(value);
    _f(FN.MBL_NO).label = <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => onLinkMBL()}>MB/L</div>;

    const onChangeKind = (value) => {
      setKind(util.dispatch, value);
    };

    const onChangeBound = (value) => {
      _c(FN.TRADE_TYPE, value);
      setBound(util.dispatch, value);
    };

    const onChangeBlStatus = (value) => {
      _c(FN.BL_STATUS, value);
    };

    const onLinkMBL = () => {
      if (!_v(FN.MBL_NO)) {
        return;
      }
      if (filingNo) {
        apiBL.vViewMBLId(filingNo).then((res) => {
          if (res) {
            util.openTab(`/admin/moving/master/mbl?id=${res?.data[0]?.blId}`);
          }
        });
      }
    };

    React.useEffect(() => {
      if (carrierType) {
        setCarrierType(util.dispatch, carrierType);
      } else {
        _c(FN.CARRIER_TYPE, 'O');
        setCarrierType(util.dispatch, 'O');
      }
    }, [carrierType]);

    React.useEffect(() => {
      let branchId = _v(FN.BRANCH_ID);
      branchId = branchId ? util.toInt(branchId) : branchId;
      if (!branchId) {
        _c(FN.BRANCH_ID, user.branch_id);
      } else {
        _c(FN.BRANCH_ID, branchId);
      }
      const branch = branchOptions.find(x => x.value === branchId)?.label;
      setBranch(util.dispatch, branch);
    }, [_v(FN.BRANCH_ID), branchOptions]);
    // }, [user.branch_id, _v(FN.BRANCH_ID), branchOptions]);
    // React.useEffect(() => {
    //   if (!_v(FN.BRANCH_ID)) {
    //     _c(FN.BRANCH_ID, user.branch_id);
    //   }
    //   const branch = branchOptions.find(x => x.value === util.toInt(_v(FN.BRANCH_ID)))?.label;
    //   setBranch(util.dispatch, branch);
    // }, [user.branch_id, _v(FN.BRANCH_ID), branchOptions]);

    React.useEffect(() => {
      if (_v(FN.BL_STATUS)) {
        setBlStatus(util.dispatch, _v(FN.BL_STATUS));
      } else {
        _c(FN.BL_STATUS, 'CREATED');
        setBlStatus(util.dispatch, 'CREATED');
      }
    }, [_v(FN.BL_STATUS)]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'flex w-full mt-2'}>
          {carrierType !== 'A' && <div className={'flex-1 mr-2'}>{_r(FN.HBL_NO)}</div>}
          {carrierType === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.HAWB_NO)}</div>}
          <div className={'flex-1 mr-2'}>{_r(FN.BRANCH_ID)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.TRADE_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.STAFF)}</div>
          <div className={'flex-1'}>{_r(FN.BL_STATUS)}</div>
        </div>
        <div className={'flex w-full'}>
          {carrierType !== 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>}
          {carrierType === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MAWB_NO)}</div>}
          <div className={'flex-1 mr-2'}>{_r(FN.PORT_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.DEPARTURE_ORIGIN)}</div>
          <div className={'flex-1'}>{_r(FN.DESTINATION_FINAL)}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export const getBranchName = (branchId, form) => {
  let branchOptions;
  try {
    branchOptions = form.getField('financialOffice').options;
  } catch (error) {
    branchOptions = [];
  }
  return branchOptions.find(i => i.value === parseInt(branchId))?.label;
}

export default MovingHouseBLFormManager;
