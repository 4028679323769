/**
 * @flow
 */
import React from 'react';
import CalendarView from '../../components/CalendarView';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import {apiBL, util} from '../../services/service';
import {useUser} from '../../redux/reducers/userReducer';
import {useParams} from 'react-router-dom';
import moment from 'moment';

const BLSchedule = () => {
  const [events, setEvents] = React.useState([]);
  const branchRef = React.useRef();
  const kindRef = React.useRef();
  const viewRef = React.useRef();
  const fromRef = React.useRef();
  const toRef = React.useRef();
  const dateRef = React.useRef();
  const setViewRef = React.useRef();
  useTopbar({label: 'Schedule'}, {label: 'Schedule'});
  const user = useUser();
  const params = useParams();
  // const kind = kindRef.current ?? ((user.team_main === 'N' || user.team_main === 'A' || user.team_main === 'S' || user.team_main === 'F') ? '' : user.team_main);
  async function loadSchedule(from, to, view) {
    const branchId = branchRef.current ?? user.branch_id;
    if (!branchId) {
      setEvents([]);
      return;
    }
    from = from ?? fromRef.current;
    to = to ?? toRef.current;
    if (!(from && to)) {
      const [fromNow, toNow] = util.getBeginEndDateTS(dateRef.current, view ?? viewRef.current ?? 'month');
      from = fromNow;
      to = toNow;
    }
    const {data} = await apiBL.calendarList({fromDate: from, toDate: to, branchId, kind: kindRef.current});
    if (Array.isArray(data)) {
      const newEvents = data.map(row => {
        const {
          id,
          balance,
          filing_no,
          bl_id: blId,
          bl_no: blNo,
          cbm = 0,
          workplace: name,
          city,
          work_date: date,
          work_time: time,
          work_type: workType,
          work_count: workCount,
          h_m: hm,
          in_out,
          kind_str,
          kind,
          dropzone,
        } = row;
        // let title = `${woTypeMap[workType]} ${cbm} ${name} ${time ? time : ''} ${city ? city : ''}`;
        let title = `${in_out} ${cbm??''} ${name} ${time ? time : ''} ${city ? city : ''}`;
        if (workCount > 1) {
          title += `(${workCount})`;
        }
        if (balance > 0) {
          title = '* ' + title;
        }
        return {
          title,
          start: moment(date).toDate(),
          end: moment(date).toDate(),
          allDay: true,
          resource: {id, blId, blNo, cbm, name, city, date, time, workType, workCount, hm, balance, filing_no, in_out, kind_str, kind, dropzone},
        };
      });
      setEvents(newEvents);
    }
  }
  React.useEffect(() => {
    if (user.id > 0) {
      kindRef.current = (user.team_main === 'N' || user.team_main === 'A' || user.team_main === 'S' || user.team_main === 'F') ? '' : user.team_main;
      branchRef.current = user.branch_id;
      loadSchedule(undefined, undefined, 'month').catch();
      setViewRef.current && setViewRef.current('month');
    }
  }, [user.id, params.ts]);
  return (
    <CalendarView
      // buttonLabel={'FULL VIEW'}
      buttonLabel={''}
      kind = {kindRef.current}
      branch = {branchRef.current}
      onButtonClick={() => {
        const searchDate = moment(fromRef.current).format('YYYYMMDD') ?? moment().format('YYYYMMDD');
        util.openTab(`/admin/schedule/fullschedule?branchId=${branchRef.current}&&kind=${kindRef.current}&&date=${searchDate}`);
      }}
      events={events}
      onEventClick={(data) => {
        // const {resource: {id, blNo, workCount, blId, hm}} = data;
        const {resource: {id, blNo, workCount, blId, hm, in_out, kind_str, kind, dropzone}} = data;
        if (id && blNo) {
          // if (workCount <= 1) {
          //   util.openTab(`/admin/schedule/workorder/set?id=${id}&blNo=${blNo}`);
          // } else {
          //   util.openTab(`/admin/schedule/workorder?blNo=${blNo}`);
          // }
          if (kind === 'C' && in_out === 'A' && dropzone) {
            util.openTab(`/admin/schedule/workorder/set?id=${id}&blNo=${blNo}`);
          } else {
            util.openTab(`/admin/${kind_str}/house/customer?id=${id}`);
          }
          // util.openTab(`/admin/schedule/workorder/set?id=${id}&blNo=${blNo}`);
        }
      }}
      onDateClick={(date, view) => {
        const [from, to] = util.getBeginEndDateTS(date, view);
        fromRef.current = from;
        toRef.current = to;
        viewRef.current = view;
        dateRef.current = date;
        loadSchedule(from, to, view).catch();
      }}
      onViewChange={(view, date, ...others) => {
        const [from, to] = util.getBeginEndDateTS(date, view);
        fromRef.current = from;
        toRef.current = to;
        viewRef.current = view;
        loadSchedule(from, to, view).catch();
      }}
      onShowMore={() => {}}
      showBranchOptions={true}
      onBranchChange={(branchId) => {
        branchRef.current = branchId;
        loadSchedule().catch();
      }}
      showKindOptions={true}
      onKindChange={(kind) => {
        kindRef.current = kind;
        loadSchedule().catch();
      }}
      setViewRef={setViewRef}
      useBoxDelivery={true}
      onMonthChange={() => {}}
    />
  );
};

// const woTypeMap = {
//   PU: 'O',
//   DL: 'I',
//   RP: 'O',
//   RN: 'O',
//   EP: 'O',
//   FP: 'O',
//   LR: 'O',
// };

export default BLSchedule;
