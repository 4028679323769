/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {packageTypeOptions, tpSizeOptions} from "../../field-defs/bl";

const FN = {
  CONTAINER_ID: 'container_id',
  CONTAINER_NO: 'container_no',
  TP_SIZE: 'tp_size',
  FILING_NO: 'filing_no',
  O_FTR: 'o_ftr',
  SEAL_NO: 'seal_no',
  CONTAINER_SEQ: 'container_seq',
  PACK_COUNT: 'pack_count',
  PACK_UNIT: 'pack_unit',
  WEIGHT_KG: 'weight_kg',
  CBM: 'cbm',
  REMARK: 'remark',
};

class ForwardingMasterBLContainerFormManager extends FormManager {
  constructor() {
    super({
      prefix: `forwarding-master-container-modal-form`,
      fields: [
        {name: FN.CONTAINER_ID, serverName: FN.CONTAINER_ID, hidden: true},
        {name: FN.CONTAINER_NO, serverName: FN.CONTAINER_NO, label: 'Container', required: true},
        {name: FN.TP_SIZE, serverName: FN.TP_SIZE, label: 'TP/SZ', options: tpSizeOptions, noDefOption: true, required: true},
        {name: FN.FILING_NO, serverName: FN.FILING_NO},
        {name: FN.O_FTR, serverName: FN.O_FTR, label: 'O/Frt'},
        {name: FN.SEAL_NO, serverName: FN.SEAL_NO, label: 'Seal No.'},
        {name: FN.CONTAINER_SEQ, serverName: FN.CONTAINER_SEQ, label: 'Container Seq.'},
        {name: FN.PACK_COUNT, serverName: FN.PACK_COUNT, label: 'Package'},
        {name: FN.PACK_UNIT, serverName: FN.PACK_UNIT, label: '', options: packageTypeOptions, noDefOption: true},
        {name: FN.WEIGHT_KG, serverName: FN.WEIGHT_KG, label: 'Weight(KG)'},
        {name: FN.CBM, serverName: FN.CBM, label: 'CBM'},
        {name: FN.REMARK, serverName: FN.REMARK, label: 'Remark'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getValue: _v} = this;

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'w-full'}>
          <div className={'flex'}>
            <div className={'flex-1'}>{_r(FN.CONTAINER_NO)}</div>
            <div className={'flex-1'}>{_r(FN.TP_SIZE)}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-1'}>{_r(FN.O_FTR)}</div>
            <div className={'flex-1'}>{_r(FN.SEAL_NO)}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-1'}>{_r(FN.CONTAINER_SEQ)}</div>
            <div className={'flex flex-1'}>
              <div className={'flex-1'}>{_r(FN.PACK_COUNT)}</div>
              <div className={'flex-1 ml-2'}>{_r(FN.PACK_UNIT)}</div>
            </div>
          </div>
          <div className={'flex'}>
            <div className={'flex-1'}>{_r(FN.WEIGHT_KG)}</div>
            <div className={'flex-1'}>{_r(FN.CBM)}</div>
          </div>
          <div>{_r(FN.REMARK)}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default ForwardingMasterBLContainerFormManager;
