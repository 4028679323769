/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {FD, FN, kindOptions} from '../field-defs/newCoship';

class SettingSearchFormManager extends FormManager {
  onSearch: () => void;
  constructor(kind: 'M' | 'F' | 'C') {
    super({
      prefix: `setting-search-form`,
      fields: [{...FD.KIND, defaultValue: kind}],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.kind = kind;
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const service = _v(FN.KIND);
    _f(FN.KIND).label = '';
    _f(FN.KIND).options = [{value: '', label: 'ALL'}, ...kindOptions];

    React.useEffect(() => {
      _c(FN.KIND, this.kind);
    }, []);

    React.useEffect(() => {
      if (service !== undefined) {
        this.onSearch();
      }
    }, [service]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default SettingSearchFormManager;
